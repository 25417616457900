<template>
    <div id="content" class="container ner_responesize content-margin" :class="{checkHidHeader: checkHidHeader==1}">
        <div class="content-section sticky-container" sticky-container>
            <div class="row">
                <div class="col-lg-9 xs-12 iframe-live" id="live-body_sss" v-if="checkMobile==0">
                    <div class="desktop_mq wp-live-stream" v-for="(item, index) in detailLive" v-bind:key="index">
                        <div class="wp-video-api">
                            <div class="" v-if="free==1">
                                <div class="mobile_stream_dt_dexuat">
                                    <vue3-video-player
                                        autoplay
                                        :core="HLSCore"
                                        :src="domain_str + id + '/master/' + id + '_480p.m3u8'"
                                        title=""
                                        resolution="408p"
                                        :view-core="viewCore.bind(null, 'video1')"
                                        id="video1"
                                        v-if="hideIframe==0 && item[4] == 200"
                                    />
                                    <vue3-video-player
                                        autoplay
                                        :core="HLSCore"
                                        :src="domain_strs + id + '/master/' + id + '_auto.m3u8'"
                                        title=""
                                        resolution="408p"
                                        :view-core="viewCore.bind(null, 'video1')"
                                        id="video1"
                                        v-else-if="hideIframe==0 && item[4] == 404"
                                    />
                                    <vue3-video-player
                                        :core="HLSCore"
                                        :src="''"
                                        title=""
                                        resolution="408p"
                                        :view-core="viewCore.bind(null, 'video1')"
                                        id="video1"
                                        v-else
                                    />
                                    <div :class="'marque_div marque_div' + id">
                                        <!-- <marquee width="100%" loop="100" class="" direction="left">
                                            <div class="vaophongmq">
                                                <img src="../assets/images/vp.gif" alt="" />
                                                <span><b class="fullname_color">Live demo</b> đã vào phòng 1</span>
                                            </div>
                                        </marquee> -->
                                    </div>
                                    <div :class="'animation_pointMember animation_pointMember' + id"></div>
                                </div>
                                <div class="pst_in_video">
                                    <img :src="item[3]" alt="">
                                    <p class="number_view_detail"><font-awesome-icon icon="fa-solid fa-users" /> <span>{{ viewVideo18 }}</span></p>
                                </div>
                                <div class="list_icon_chat_live">
                                    <div class="item_icon_chat_live">
                                        <Carousel :breakpoints="breakpoints" :wrap-around="false">
                                            <Slide v-for="(item, index) in listIcon" v-bind:key="index">
                                                <img :data_img="item.id" :src="link_web + item.url" alt="" class="icon_chat_send_live" @click="sendIconChat">
                                                <span class="point_level_live" v-if="item.amount == 0" :data_img="item.id" @click="sendIconChat">{{ item.point}} điểm</span>
                                                <span class="point_level_live" v-else :data_img="item.id" @click="sendIconChat">{{ item.amount}} xu</span>
                                            </Slide>
                                            <template #addons>
                                                <Navigation />
                                            </template>
                                        </Carousel>
                                    </div>
                                </div>
                                <div class="wp_ifo_detaile">
                                    <div class="ifo_detaile">
                                        <div class="img-detail">
                                            <a href="javascript:;">
                                                <img :src="item[1]" alt="">
                                            </a>
                                        </div>
                                        <div class="text_detail">
                                            <h3>{{ item[2] }}</h3>
                                            <div>
                                                <span class="share_live">
                                                    <img src="../assets/images/cs.png" alt=""> Chia sẻ
                                                    <a v-bind:href="'https://www.facebook.com/sharer/sharer.php?u=' + link_webs + '/video-propose/' + id" target="_blank" role="button" tabindex="0" class="at-icon-wrapper at-share-btn at-svc-facebook">
                                                        <img src="../assets/images/cs1.png" alt="">
                                                    </a>
                                                    <a v-bind:href="'https://telegram.me/share/url?url=' + link_webs + '/video-propose/' + id" target="_blank" role="button" tabindex="0" class="at-icon-wrapper at-share-btn at-svc-telegram">
                                                        <img src="../assets/images/cs2.png" alt="">
                                                    </a>
                                                    <a @click="copyToClipBoard(link_webs + '/video-propose/' + id)" id="copyClick" role="button" tabindex="0" class="at-icon-wrapper at-share-btn at-svc-link">
                                                        <img src="../assets/images/cs3.png" alt="">
                                                    </a>
                                                    <button type="button" @click="showModal = true" class="registeridol" v-if="token == null">Follow</button>
                                                    <button type="button" @click="followIdol" class="registeridol" v-else-if="checkFolow==0 && token != null">Follow</button>
                                                    <button type="button" @click="cancelFolow" class="registeridol" v-else>Hủy follow</button>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="livemb_number" style="display: none;">
                                        <img :src="item[3]" alt="" class="fly_live">
                                        <img src="../assets/images/mb1.png" alt="" class="live_img">
                                        <span><img src="../assets/images/mb2.png" alt=""><b>{{ viewVideo18 }}</b></span>
                                    </div>
                                </div>
                            </div>
                            <div class="wp_mobile_stream_dt_dexuat" v-else>
                                <Carousel :wrap-around="false" :breakpoints="breakpointssls" @slide-start="handleSlideStart">
                                    <Slide v-for="(item, index) in detailLive" :key="index+1">
                                        <div class="desktop_mq wp-live-stream wp_wd_mb">
                                            <div class="wp-video-api">
                                                <div class="mobile_stream_dt_dexuat">
                                                    <vue3-video-player
                                                        autoplay
                                                        :core="HLSCore"
                                                        :src="domain_str + id + '/master/' + id + '_480p.m3u8'"
                                                        title=""
                                                        resolution="408p"
                                                        :view-core="viewCore.bind(null, 'video1')"
                                                        id="video1"
                                                        v-if="hideIframe==0 && item[4] == 200"
                                                    />
                                                    <vue3-video-player
                                                        autoplay
                                                        :core="HLSCore"
                                                        :src="domain_strs + id + '/master/' + id + '_auto.m3u8'"
                                                        title=""
                                                        resolution="408p"
                                                        :view-core="viewCore.bind(null, 'video1')"
                                                        id="video1"
                                                        v-else-if="hideIframe==0 && item[4] == 404"
                                                    />
                                                    <vue3-video-player
                                                        :core="HLSCore"
                                                        :src="''"
                                                        title=""
                                                        resolution="408p"
                                                        :view-core="viewCore.bind(null, 'video1')"
                                                        id="video1"
                                                        v-else
                                                    />
                                                </div>
                                                <div class="pst_in_video">
                                                    <img :src="item[3]" alt="">
                                                    <p class="number_view_detail"><font-awesome-icon icon="fa-solid fa-users" /> <span>{{ viewVideo18 }}</span></p>
                                                </div>
                                            </div>
                                            <div class="img_show_hide_chat">
                                                <p :class="'show_chat1 show_chat'+item[0]" :data_id="item[0]"><img src="../assets/images/show_chat.png" alt=""></p>
                                                <p :class="'hide_chat1 hide_chat'+item[0]" :data_id="item[0]"><img src="../assets/images/hide_chat.png" alt=""></p>
                                            </div>
                                            <div class="wrapper_message_pin showmobile_pin">
                                                <h3 class="message_pin_s" id="message_pin" v-html="messagePin"></h3>
                                            </div>
                                            <div class="list_icon_chat_live">
                                                <div class="item_icon_chat_live">
                                                    <Carousel :breakpoints="breakpoints" :wrap-around="false">
                                                        <Slide v-for="(item, index) in listIcon" v-bind:key="index">
                                                            <img :data_img="item.id" :src="link_web + item.url" alt="" class="icon_chat_send_live" @click="sendIconChat">
                                                            <span class="point_level_live" v-if="item.amount == 0" :data_img="item.id" @click="sendIconChat">{{ item.point}} điểm</span>
                                                            <span class="point_level_live" v-else :data_img="item.id" @click="sendIconChat">{{ item.amount}} xu</span>
                                                        </Slide>
                                                        <template #addons>
                                                            <Navigation />
                                                        </template>
                                                    </Carousel>
                                                </div>
                                            </div>
                                            <div class="wp_ifo_detaile">
                                                <div class="ifo_detaile">
                                                    <div class="img-detail">
                                                        <a href="javascript:;">
                                                            <img :src="item[1]" alt="">
                                                        </a>
                                                    </div>
                                                    <div class="text_detail">
                                                        <input type="hidden" :class="'idvideo'+parseInt(index+1)" :value="item[0]">
                                                        <h3>{{ item[2] }}</h3>
                                                        <div>
                                                            <span class="share_live">
                                                                <img src="../assets/images/cs.png" alt=""> Chia sẻ
                                                                <a v-bind:href="'https://www.facebook.com/sharer/sharer.php?u=' + link_webs + '/video-propose/' + id" target="_blank" role="button" tabindex="0" class="at-icon-wrapper at-share-btn at-svc-facebook">
                                                                    <img src="../assets/images/cs1.png" alt="">
                                                                </a>
                                                                <a v-bind:href="'https://telegram.me/share/url?url=' + link_webs + '/video-propose/' + id" target="_blank" role="button" tabindex="0" class="at-icon-wrapper at-share-btn at-svc-telegram">
                                                                    <img src="../assets/images/cs2.png" alt="">
                                                                </a>
                                                                <a @click="copyToClipBoard(link_webs + '/video-propose/' + id)" id="copyClick" role="button" tabindex="0" class="at-icon-wrapper at-share-btn at-svc-link">
                                                                    <img src="../assets/images/cs3.png" alt="">
                                                                </a>
                                                                <button type="button" @click="showModal = true" class="registeridol" v-if="token == null">Follow</button>
                                                                <button type="button" @click="followIdol" class="registeridol" v-else-if="checkFolow==0 && token != null">Follow</button>
                                                                <button type="button" @click="cancelFolow" class="registeridol" v-else>Hủy follow</button>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="livemb_number" style="display: none;">
                                                    <img src="../assets/images/mb1.png" alt="">
                                                    <span><img src="../assets/images/mb2.png" alt=""><b>{{ viewVideo18 }}</b></span>
                                                </div>
                                            </div>
                                        </div>
                                    </Slide>
                                    <Slide  v-for="(item, index) in listidolmb.slice(0, 15)" v-bind:key="index+1">
                                        <div class="desktop_mq wp-live-stream wp_wd_mb">
                                            <div class="wp-video-api">
                                                <div class="mobile_stream_dt_dexuat" v-if="hideIframe==0 && item[4] == 200">
                                                    <vue3-video-player
                                                        :core="HLSCore"
                                                        :src="domain_str + item[0] + '/master/' + item[0] + '_480p.m3u8'"
                                                        title=""
                                                        resolution="408p"
                                                        :view-core="viewCore.bind(null, 'video' + parseInt(index+2))"
                                                        :id="'video'+parseInt(index+2)"
                                                    />
                                                </div>
                                                <div class="mobile_stream_dt_dexuat" v-else-if="hideIframe==0 && item[4] == 404">
                                                    <vue3-video-player
                                                        :core="HLSCore"
                                                        :src="domain_strs + id + '/master/' + id + '_auto.m3u8'"
                                                        title=""
                                                        resolution="408p"
                                                        :view-core="viewCore.bind(null, 'video' + parseInt(index+2))"
                                                        :id="'video'+parseInt(index+2)"
                                                    />
                                                </div>
                                                <div class="mobile_stream_dt_dexuat" v-else>
                                                    <vue3-video-player
                                                        :core="HLSCore"
                                                        :src="''"
                                                        title=""
                                                        resolution="408p"
                                                        :view-core="viewCore.bind(null, 'video'+parseInt(index+2))"
                                                        :id="'video'+parseInt(index+2)"
                                                    />
                                                </div>
                                                <div class="pst_in_video">
                                                    <img :src="item[3]" alt="">
                                                    <p class="number_view_detail"><font-awesome-icon icon="fa-solid fa-users" /> <span>{{ viewVideo18 }}</span></p>
                                                </div>
                                            </div>
                                            <div class="img_show_hide_chat">
                                                <p :class="'show_chat1 show_chat'+item[0]" :data_id="item[0]"><img src="../assets/images/show_chat.png" alt=""></p>
                                                <p :class="'hide_chat1 hide_chat'+item[0]" :data_id="item[0]"><img src="../assets/images/hide_chat.png" alt=""></p>
                                            </div>
                                            <div class="wrapper_message_pin showmobile_pin">
                                                <h3 class="message_pin_s" id="message_pin" v-html="messagePin"></h3>
                                            </div>
                                            <div class="list_icon_chat_live">
                                                <div class="item_icon_chat_live">
                                                    <Carousel :breakpoints="breakpoints" :wrap-around="false">
                                                        <Slide v-for="(item, index) in listIcon" v-bind:key="index">
                                                            <img :data_img="item.id" :src="link_web + item.url" alt="" class="icon_chat_send_live" @click="sendIconChat">
                                                            <span class="point_level_live" v-if="item.amount == 0" :data_img="item.id" @click="sendIconChat">{{ item.point}} điểm</span>
                                                            <span class="point_level_live" v-else :data_img="item.id" @click="sendIconChat">{{ item.amount}} xu</span>
                                                        </Slide>
                                                        <template #addons>
                                                            <Navigation />
                                                        </template>
                                                    </Carousel>
                                                </div>
                                            </div>
                                            <div class="wp_ifo_detaile">
                                                <div class="ifo_detaile">
                                                    <div class="img-detail">
                                                        <a href="javascript:;">
                                                            <img :src="item[1]" alt="">
                                                        </a>
                                                    </div>
                                                    <div class="text_detail">
                                                        <input type="hidden" :class="'idvideo'+parseInt(index+2)" :value="item[0]">
                                                        <h3>{{ item[2] }}</h3>
                                                        <div>
                                                            <span class="share_live">
                                                                <img src="../assets/images/cs.png" alt=""> Chia sẻ
                                                                <a v-bind:href="'https://www.facebook.com/sharer/sharer.php?u=' + link_webs + '/video-propose/' + item[0]" target="_blank" role="button" tabindex="0" class="at-icon-wrapper at-share-btn at-svc-facebook">
                                                                    <img src="../assets/images/cs1.png" alt="">
                                                                </a>
                                                                <a v-bind:href="'https://telegram.me/share/url?url=' + link_webs + '/video-propose/' + item[0]" target="_blank" role="button" tabindex="0" class="at-icon-wrapper at-share-btn at-svc-telegram">
                                                                    <img src="../assets/images/cs2.png" alt="">
                                                                </a>
                                                                <a @click="copyToClipBoard(link_webs + '/video-propose/' + item[0])" id="copyClick" role="button" tabindex="0" class="at-icon-wrapper at-share-btn at-svc-link">
                                                                    <img src="../assets/images/cs3.png" alt="">
                                                                </a>
                                                                <button type="button" @click="showModal = true" class="registeridol" v-if="token == null">Follow</button>
                                                                <button type="button" @click="followIdols($event)" :data_name="item[2]" class="registeridol" v-else-if="checkflsl==0 && token != null">Follow</button>
                                                                <button type="button" @click="cancelFolows" :data_name="item[2]" class="registeridol" v-else>Hủy follow</button>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="livemb_number" style="display: none;">
                                                    <img src="../assets/images/mb1.png" alt="">
                                                    <span><img src="../assets/images/mb2.png" alt=""><b>{{ viewVideo18 }}</b></span>
                                                </div>
                                            </div>
                                        </div>
                                    </Slide>
                                    <template #addons>
                                        <Navigation />
                                    </template>
                                </Carousel>
                                <div :class="'marque_div marque_div' + id"></div>
                                <div :class="'animation_pointMember animation_pointMember' + id"></div>
                            </div>
                            <!-- <p class="number_view_detail"><font-awesome-icon icon="fa-solid fa-users" /> <span>{{ viewVideo18 }}</span></p> -->
                            <!-- <router-link to="/video-propose" class="close_live_mobile"><img src="../assets/images/clo.png" alt=""></router-link> -->
                        </div>
                        <div class="img_show_hide_chat">
                            <p class="show_chat"><img src="../assets/images/show_chat.png" alt=""></p>
                            <p class="hide_chat"><img src="../assets/images/hide_chat.png" alt=""></p>
                        </div>
                        <div class="wrapper_message_pin showmobile_pin">
                            <h3 class="message_pin_s" id="message_pin" v-html="messagePin"></h3>
                        </div>
                        <!-- <div class="wp_ifo_detaile">
                            <div class="ifo_detaile">
                                <div class="img-detail">
                                    <a href="javascript:;">
                                        <img :src="item[1]" alt="">
                                    </a>
                                </div>
                                <div class="text_detail">
                                    <h3>{{ item[2] }}</h3>
                                    <div>
                                        <span class="share_live">
                                            <img src="../assets/images/cs.png" alt=""> Chia sẻ
                                            <a v-bind:href="'https://www.facebook.com/sharer/sharer.php?u=' + link_webs + '/video-propose/' + id" target="_blank" role="button" tabindex="0" class="at-icon-wrapper at-share-btn at-svc-facebook">
                                                <img src="../assets/images/cs1.png" alt="">
                                            </a>
                                            <a v-bind:href="'https://telegram.me/share/url?url=' + link_webs + '/video-propose/' + id" target="_blank" role="button" tabindex="0" class="at-icon-wrapper at-share-btn at-svc-telegram">
                                                <img src="../assets/images/cs2.png" alt="">
                                            </a>
                                            <a @click="copyToClipBoard(link_webs + '/video-propose/' + id)" id="copyClick" role="button" tabindex="0" class="at-icon-wrapper at-share-btn at-svc-link">
                                                <img src="../assets/images/cs3.png" alt="">
                                            </a>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="livemb_number" style="display: none;">
                                <img src="../assets/images/mb1.png" alt="">
                                <span><img src="../assets/images/mb2.png" alt=""><b>{{ viewVideo18 }}</b></span>
                            </div>
                        </div> -->
                    </div>
                    <!-- icon mobile -->
                    <div class="icon_social_mobile">
                        <div class="social_icon">
                            <ul>
                                <li v-if="token == null"><a href="javascript:;" @click="FormLoginShow"><img src="../assets/images/help.png" alt=""></a></li>
                                <li v-else><router-link to='/support'><img src="../assets/images/help.png" alt="" title="support"></router-link></li>
                                <li><a :href="facebook" target="_blank"><img src="../assets/images/zl.png" alt="" title="zalo"></a></li>
                                <li><a :href="telegram" target="_blank"><img src="../assets/images/telegram.gif" alt=""></a></li>
                                <li><a :href="telegrams" target="_blank"><img src="../assets/images/telegrams.gif" alt=""></a></li>
                                <!-- <li v-if="token == null"><a href="javascript:;" @click="FormLoginShow"><img src="../assets/images/sp.gif" alt=""></a></li>
                                <li v-else><a href="javascript:;" @click="showModalsp = true"><img src="../assets/images/sp.gif" alt=""></a></li> -->
                            </ul>
                        </div>
                    </div>
                    <!-- end icon mobile -->
                </div>
                <div class="list_slider_mobile" v-if="checkMobile==1">
                    <Carousel :wrap-around="false" :breakpoints="breakpointssls" @slide-start="handleSlideStart" v-if="free==0">
                        <Slide v-for="(item, index) in detailLive" :key="index+1">
                            <div class="desktop_mq wp-live-stream wp_wd_mb">
                                <div class="wp-video-api">
                                    <div class="mobile_stream_dt_dexuat">
                                        <vue3-video-player
                                            autoplay
                                            :core="HLSCore"
                                            :src="domain_str + id + '/master/' + id + '_480p.m3u8'"
                                            title=""
                                            resolution="408p"
                                            :view-core="viewCore.bind(null, 'video1')"
                                            id="video1"
                                            v-if="hideIframe==0 && item[4] == 200"
                                        />
                                        <vue3-video-player
                                            autoplay
                                            :core="HLSCore"
                                            :src="domain_strs + id + '/master/' + id + '_auto.m3u8'"
                                            title=""
                                            resolution="408p"
                                            :view-core="viewCore.bind(null, 'video1')"
                                            id="video1"
                                            v-else-if="hideIframe==0 && item[4] == 404"
                                        />
                                        <vue3-video-player
                                            :core="HLSCore"
                                            :src="''"
                                            title=""
                                            resolution="408p"
                                            :view-core="viewCore.bind(null, 'video1')"
                                            id="video1"
                                            v-else
                                        />
                                    </div>
                                    <p class="number_view_detail"><font-awesome-icon icon="fa-solid fa-users" /> <span>{{ viewVideo18 }}</span></p>
                                </div>
                                <div class="img_show_hide_chat">
                                    <p :class="'show_chat1 show_chat'+item[0]" :data_id="item[0]"><img src="../assets/images/show_chat.png" alt=""></p>
                                    <p :class="'hide_chat1 hide_chat'+item[0]" :data_id="item[0]"><img src="../assets/images/hide_chat.png" alt=""></p>
                                </div>
                                <div class="wrapper_message_pin showmobile_pin">
                                    <h3 class="message_pin_s" id="message_pin" v-html="messagePin"></h3>
                                </div>
                                <div class="wp_ifo_detaile">
                                    <div class="ifo_detaile">
                                        <div class="img-detail">
                                            <a href="javascript:;">
                                                <img :src="item[1]" alt="">
                                            </a>
                                        </div>
                                        <div class="text_detail">
                                            <h3>{{ item[2] }}</h3>
                                            <div>
                                                <span class="share_live">
                                                    <img src="../assets/images/cs.png" alt=""> Chia sẻ
                                                    <a v-bind:href="'https://www.facebook.com/sharer/sharer.php?u=' + link_webs + '/video-propose/' + id" target="_blank" role="button" tabindex="0" class="at-icon-wrapper at-share-btn at-svc-facebook">
                                                        <img src="../assets/images/cs1.png" alt="">
                                                    </a>
                                                    <a v-bind:href="'https://telegram.me/share/url?url=' + link_webs + '/video-propose/' + id" target="_blank" role="button" tabindex="0" class="at-icon-wrapper at-share-btn at-svc-telegram">
                                                        <img src="../assets/images/cs2.png" alt="">
                                                    </a>
                                                    <a @click="copyToClipBoard(link_webs + '/video-propose/' + id)" id="copyClick" role="button" tabindex="0" class="at-icon-wrapper at-share-btn at-svc-link">
                                                        <img src="../assets/images/cs3.png" alt="">
                                                    </a>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="livemb_number" style="display: none;">
                                        <img :src="item[3]" alt="" class="fly_live">
                                        <img src="../assets/images/mb1.png" alt="" class="live_img">
                                        <span><img src="../assets/images/mb2.png" alt=""><b>{{ viewVideo18 }}</b></span>
                                    </div>
                                </div>
                            </div>
                        </Slide>
                        <Slide  v-for="(item, index) in listidolmb.slice(0, 10)" v-bind:key="index+1">
                            <div class="desktop_mq wp-live-stream wp_wd_mb">
                                <div class="wp-video-api">
                                    <div class="mobile_stream_dt_dexuat" v-if="hideIframe==0">
                                        <vue3-video-player
                                            :core="HLSCore"
                                            :src="domain_str + item[0] + '/master/' + item[0] + '_480p.m3u8'"
                                            title=""
                                            resolution="408p"
                                            :view-core="viewCore.bind(null, 'video' + parseInt(index+2))"
                                            :id="'video'+parseInt(index+2)"
                                        />
                                    </div>
                                    <div class="mobile_stream_dt_dexuat" v-else>
                                        <vue3-video-player
                                            :core="HLSCore"
                                            :src="''"
                                            title=""
                                            resolution="408p"
                                            :view-core="viewCore.bind(null, 'video'+parseInt(index+2))"
                                            :id="'video'+parseInt(index+2)"
                                        />
                                    </div>
                                    <p class="number_view_detail"><font-awesome-icon icon="fa-solid fa-users" /> <span>{{ viewVideo18 }}</span></p>
                                </div>
                                <div class="img_show_hide_chat">
                                    <p :class="'show_chat1 show_chat'+item[0]" :data_id="item[0]"><img src="../assets/images/show_chat.png" alt=""></p>
                                    <p :class="'hide_chat1 hide_chat'+item[0]" :data_id="item[0]"><img src="../assets/images/hide_chat.png" alt=""></p>
                                </div>
                                <div class="wrapper_message_pin showmobile_pin">
                                    <h3 class="message_pin_s" id="message_pin" v-html="messagePin"></h3>
                                </div>
                                <div class="wp_ifo_detaile">
                                    <div class="ifo_detaile">
                                        <div class="img-detail">
                                            <a href="javascript:;">
                                                <img :src="item[1]" alt="">
                                            </a>
                                        </div>
                                        <div class="text_detail">
                                            <h3>{{ item[2] }}</h3>
                                            <div>
                                                <span class="share_live">
                                                    <img src="../assets/images/cs.png" alt=""> Chia sẻ
                                                    <a v-bind:href="'https://www.facebook.com/sharer/sharer.php?u=' + link_webs + '/video-propose/' + item[0]" target="_blank" role="button" tabindex="0" class="at-icon-wrapper at-share-btn at-svc-facebook">
                                                        <img src="../assets/images/cs1.png" alt="">
                                                    </a>
                                                    <a v-bind:href="'https://telegram.me/share/url?url=' + link_webs + '/video-propose/' + item[0]" target="_blank" role="button" tabindex="0" class="at-icon-wrapper at-share-btn at-svc-telegram">
                                                        <img src="../assets/images/cs2.png" alt="">
                                                    </a>
                                                    <a @click="copyToClipBoard(link_webs + '/video-propose/' + item[0])" id="copyClick" role="button" tabindex="0" class="at-icon-wrapper at-share-btn at-svc-link">
                                                        <img src="../assets/images/cs3.png" alt="">
                                                    </a>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="livemb_number" style="display: none;">
                                        <img :src="item[3]" alt="" class="fly_live">
                                        <img src="../assets/images/mb1.png" alt="" class="live_img">
                                        <span><img src="../assets/images/mb2.png" alt=""><b>{{ viewVideo18 }}</b></span>
                                    </div>
                                </div>
                            </div>
                        </Slide>
                        <template #addons>
                            <Navigation />
                        </template>
                    </Carousel>
                    <Carousel :wrap-around="false" :breakpoints="breakpointssls" @slide-start="handleSlideStart" v-else>
                        <Slide v-for="(item, index) in detailLive" :key="index+1">
                            <div class="desktop_mq wp-live-stream wp_wd_mb">
                                <div class="wp-video-api">
                                    <div class="mobile_stream_dt_dexuat">
                                        <vue3-video-player
                                            autoplay
                                            :core="HLSCore"
                                            :src="domain_str + id + '/master/' + id + '_480p.m3u8'"
                                            title=""
                                            resolution="408p"
                                            :view-core="viewCore.bind(null, 'video1')"
                                            id="video1"
                                            v-if="hideIframe==0 && item[4] == 200"
                                        />
                                        <vue3-video-player
                                            autoplay
                                            :core="HLSCore"
                                            :src="domain_strs + id + '/master/' + id + '_auto.m3u8'"
                                            title=""
                                            resolution="408p"
                                            :view-core="viewCore.bind(null, 'video1')"
                                            id="video1"
                                            v-else-if="hideIframe==0 && item[4] == 404"
                                        />
                                        <vue3-video-player
                                            :core="HLSCore"
                                            :src="''"
                                            title=""
                                            resolution="408p"
                                            :view-core="viewCore.bind(null, 'video1')"
                                            id="video1"
                                            v-else
                                        />
                                    </div>
                                    <p class="number_view_detail"><font-awesome-icon icon="fa-solid fa-users" /> <span>{{ viewVideo18 }}</span></p>
                                </div>
                                <div class="img_show_hide_chat">
                                    <p :class="'show_chat1 show_chat'+item[0]" :data_id="item[0]"><img src="../assets/images/show_chat.png" alt=""></p>
                                    <p :class="'hide_chat1 hide_chat'+item[0]" :data_id="item[0]"><img src="../assets/images/hide_chat.png" alt=""></p>
                                </div>
                                <div class="wrapper_message_pin showmobile_pin">
                                    <h3 class="message_pin_s" id="message_pin" v-html="messagePin"></h3>
                                </div>
                                <div class="wp_ifo_detaile">
                                    <div class="ifo_detaile">
                                        <div class="img-detail">
                                            <a href="javascript:;">
                                                <img :src="item[1]" alt="">
                                            </a>
                                        </div>
                                        <div class="text_detail">
                                            <h3>{{ item[2] }}</h3>
                                            <div>
                                                <span class="share_live">
                                                    <img src="../assets/images/cs.png" alt=""> Chia sẻ
                                                    <a v-bind:href="'https://www.facebook.com/sharer/sharer.php?u=' + link_webs + '/video-propose/' + id" target="_blank" role="button" tabindex="0" class="at-icon-wrapper at-share-btn at-svc-facebook">
                                                        <img src="../assets/images/cs1.png" alt="">
                                                    </a>
                                                    <a v-bind:href="'https://telegram.me/share/url?url=' + link_webs + '/video-propose/' + id" target="_blank" role="button" tabindex="0" class="at-icon-wrapper at-share-btn at-svc-telegram">
                                                        <img src="../assets/images/cs2.png" alt="">
                                                    </a>
                                                    <a @click="copyToClipBoard(link_webs + '/video-propose/' + id)" id="copyClick" role="button" tabindex="0" class="at-icon-wrapper at-share-btn at-svc-link">
                                                        <img src="../assets/images/cs3.png" alt="">
                                                    </a>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="livemb_number" style="display: none;">
                                        <img :src="item[3]" alt="" class="fly_live">
                                        <img src="../assets/images/mb1.png" alt="" class="live_img">
                                        <span><img src="../assets/images/mb2.png" alt=""><b>{{ viewVideo18 }}</b></span>
                                    </div>
                                </div>
                            </div>
                        </Slide>
                    </Carousel>
                    <!-- icon mobile -->
                    <div class="icon_social_mobile">
                        <div class="social_icon">
                            <ul>
                                <li v-if="token == null"><a href="javascript:;" @click="FormLoginShow"><img src="../assets/images/help.png" alt=""></a></li>
                                <li v-else><router-link to='/support'><img src="../assets/images/help.png" alt="" title="support"></router-link></li>
                                <li><a :href="facebook" target="_blank"><img src="../assets/images/zl.png" alt="" title="zalo"></a></li>
                                <li><a :href="telegram" target="_blank"><img src="../assets/images/telegram.gif" alt=""></a></li>
                                <li><a :href="telegrams" target="_blank"><img src="../assets/images/telegrams.gif" alt=""></a></li>
                                <!-- <li v-if="token == null"><a href="javascript:;" @click="FormLoginShow"><img src="../assets/images/sp.gif" alt=""></a></li>
                                <li v-else><a href="javascript:;" @click="showModalsp = true"><img src="../assets/images/sp.gif" alt=""></a></li> -->
                            </ul>
                        </div>
                    </div>
                    <!-- end icon mobile -->
                </div>
                <img src="../assets/images/hide_chat.png" alt="" class="none_pin_mes" @click="clickClInfo">
                <div class="col-lg-3 xs-12 iframe-chat">
                    <div class="sticky" v-sticky="stickyEnabled" sticky-offset="{top: 70, bottom: 0}">
                        <div class="wp-chat-stream">
                            <div class="position_right">
                                <div class="chat-text chat-text_dexuat" v-bind:id="this.$route.params.id"></div>
                                <!-- form chat -->
                                <div class="frm_chat_live">
                                    <form method="post" action="" name="frm_chat" id="_frm_chat" @submit="sendChat">
                                        <div class="btn-chatbd">
                                            <input type="hidden" name="url-chat" id="url-chat" v-bind:value="this.$route.params.id">
                                            <input type="hidden" name="data-img" class="dataImg_chat">
                                            <div class="wp_content_chat">
                                                <input type="text" name="content_chat" id="content_chat" class="content_chat" placeholder="Nội dung" >
                                                <div class="name_chat">
                                                    <img src="../assets/images/icon.png" alt="" class="show_list_icon" @click="showIconChatLive">
                                                    <div class="list_icon_chat">
                                                        <div class="item_icon_chat">
                                                            <p v-for="(item, index) in listIcon" v-bind:key="index">
                                                                <img :data_img="item.id" :src="'https://f8game.click/' + item.url" alt="" class="icon_chat_send" @click="sendIconChat">
                                                                <span class="point_level" v-if="item.amount == 0" :data_img="item.id" @click="sendIconChat">{{ item.point}} điểm</span>
                                                                <span class="point_level" v-else :data_img="item.id" @click="sendIconChat">{{ item.amount}} xu</span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <button type="submit" class="btn_submit_chat" id="chat-submit">Gửi</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <!-- end form chat -->
                                <div class="wrapper_message_pin">
                                    <h3 class="message_pin_s" id="message_pin" v-html="messagePin"></h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <!-- modal login -->
        <div id="modal-example" class="modal-login">
            <div class="overlay" v-if="showModal" @click="showModal = false"></div>
            <div class="modal" v-if="showModal">
                <div class="wp-modal-login">
                    <div class="header-modal">
                        <button class="close-modal_login" @click="closeMdLg"><img src="../assets/images/close_md.png" alt=""></button>
                    </div>
                    <div class="content-modal_tab">
                        <div>
                            <div class="logo_modal_login">
                                <div class="logo_mdal">
                                    <div class="img_md">
                                        <img src="../assets/images/logomd.png" alt="">
                                    </div>
                                    <div class="img_md1">
                                        <img src="../assets/images/logomd1.png" alt="">
                                    </div>
                                </div>
                                <div class="img_pathner_md">
                                    <img src="../assets/images/logomd2.png" alt="">
                                </div>
                            </div>
                            <Login_f8 @showMd="showModalrg" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- end modal login -->
        <!-- modal register -->
        <div id="modal-example" class="modal-login">
            <div class="overlay" v-if="showModal_dk" @click="showModal_dk = false"></div>
            <div class="modal" v-if="showModal_dk">
                <div class="wp-modal-register">
                    <div class="header-modal">
                        <button class="close-modal_login" @click="closeMdRg"><img src="../assets/images/close_md.png" alt=""></button>
                    </div>
                    <div class="content-modal_tab">
                        <div>
                            <div class="logo_modal_login">
                                <div class="logo_mdal">
                                    <div class="img_md">
                                        <img src="../assets/images/logomd.png" alt="">
                                    </div>
                                    <div class="img_md1">
                                        <img src="../assets/images/logomd1.png" alt="">
                                    </div>
                                </div>
                                <div class="img_pathner_md">
                                    <img src="../assets/images/logomd2.png" alt="">
                                </div>
                            </div>
                            <div class="form_dangky">
                                <Register_f8 @showMd="showModallg"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- end modal register -->
        <!-- list live other -->
        <div class="wp_cat_live_list listmobile_none list_live_other" v-if="listLiveidol.length > 0">
            <div class="title_item_idol">
                <h3>
                    <img src="../assets/images/gy.png" alt="">
                    <span>Được gợi ý</span>
                    <!-- <router-link to="/video-propose">Xem thêm</router-link> -->
                </h3>
            </div>
            <!-- <Carousel :wrap-around="false" :breakpoints="breakpointssl">
                <Slide v-for="(item, indexs) in listLiveidol.slice(0,20)" :key="indexs">
                    <div class="item_live item_live_97">
                        <router-link v-bind:to="{ name: 'DetailVideoPropose_nm', params: { id: item[0], check: item.check_mbut} }">
                            <div class="img_live">
                                <img :src="item[2]" alt="">
                                <div class="hover_icon">
                                    <font-awesome-icon icon="fa-solid fa-caret-right" />
                                </div>
                            </div>
                            <div class="text_item_live">
                                <div class="img_live_small">
                                    <img :src="item[2]" alt="">
                                </div>
                                <div class="text_live_small">
                                    <h3>{{ item[1] }}</h3>
                                </div>
                                <img src="../assets/images/live.png" alt="" class="img_live_green">
                            </div>
                        </router-link>
                    </div>
                </Slide>
                <template #addons>
                    <pagination />
                </template>
            </Carousel> -->
            <div class="list_idol_xnxx" v-if="free==0">
                <div class="row">
                    <div class="col-md-2 col-sm-2 col-xs-3 wp-item-live" v-for="(item, index) in datas" :key="index">
                        <div class="item_live">
                            <router-link v-bind:to="{ name: 'DetailAsian_nm', params: { id: item[0], free: 0} }">
                                <div class="img_live">
                                    <img :src="item[1]" alt="">
                                    <div class="hover_icon">
                                        <font-awesome-icon icon="fa-solid fa-caret-right" />
                                    </div>
                                </div>
                                <div class="text_item_live">
                                    <div class="img_live_small">
                                        <img :src="item[1]" alt="">
                                    </div>
                                    <div class="text_live_small">
                                        <h3>{{ item[2].substring(0, 10) }}</h3>
                                        <!-- <div class="type_live">
                                            <p>
                                                <img src="../assets/images/eye.svg" alt="">
                                                <span class="sp-02">{{ item.viewersCount }}</span>
                                            </p>
                                        </div> -->
                                    </div>
                                    <img :src="item[3]" alt="" class="img_live_green">
                                </div>
                            </router-link>
                        </div>
                    </div>
                </div>
                <paginate @update:modelValue="clickCallback" :totalCount="totalCount" :limit="perPage" v-model="page"></paginate>
                <!-- <p class="detail_mobile_live">
                    <router-link to="/video-propose">Xem thêm</router-link>
                </p> -->
            </div>
            <div class="list_idol_xnxx" v-else>
                <div class="row">
                    <div class="col-md-2 col-sm-2 col-xs-3 wp-item-live" v-for="(item, index) in listlivefree.slice(0,2)" :key="index">
                        <div class="item_live">
                            <router-link v-bind:to="{ name: 'DetailAsian_nm', params: { id: item[0], free: 1} }">
                                <div class="img_live">
                                    <img :src="item[1]" alt="">
                                    <div class="hover_icon">
                                        <font-awesome-icon icon="fa-solid fa-caret-right" />
                                    </div>
                                </div>
                                <div class="text_item_live">
                                    <div class="img_live_small">
                                        <img :src="item[1]" alt="">
                                    </div>
                                    <div class="text_live_small">
                                        <h3>{{ item[2].substring(0, 10) }}</h3>
                                    </div>
                                    <img :src="item[3]" alt="" class="img_live_green">
                                </div>
                            </router-link>
                        </div>
                    </div>
                    <div class="col-md-2 col-sm-2 col-xs-3 wp-item-live" :class="{nn_im_dt: index < 3}" v-for="(item, index) in listLiveidol1.slice(0,6)" :key="index">
                        <div class="item_live">
                            <router-link v-bind:to="{ name: 'DetailVideoPropose_nm', params: { id: item[0], free: 1} }">
                                <div class="img_live">
                                    <img :src="item[1]" alt="">
                                    <div class="hover_icon">
                                        <font-awesome-icon icon="fa-solid fa-caret-right" />
                                    </div>
                                </div>
                                <div class="text_item_live">
                                    <div class="img_live_small">
                                        <img :src="item[1]" alt="">
                                    </div>
                                    <div class="text_live_small">
                                        <h3>{{ item[2].substring(0, 10) }}</h3>
                                    </div>
                                    <img :src="item[3]" alt="" class="img_live_green">
                                </div>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- end list live other -->
        <!-- modal cash in -->
            <div id="modal-example" class="modal-login">
                <div class="overlay" v-if="showmodal_cashin" @click="showmodal_cashin = false"></div>
                <div class="modal" v-if="showmodal_cashin">
                    <div class="wp-modal-thongbao">
                        <div class="header-modal">
                            <button class="close-modal_login" @click="checkCashinLive"><img src="../assets/images/close_md1.png" alt=""></button>
                        </div>
                        <div class="content-modal_tab">
                            <div>
                                <div class="wrapper_notify_live">
                                    <div class="text_tb">
                                        <p class="tb_1"><img src="../assets/images/tb.png" alt=""></p>
                                        <ul>
                                        <li><img src="../assets/images/tb1.png" alt=""> Quý khách vui lòng nạp điểm để xem trực tiếp 18+ Full HD</li> 
                                        <li><img src="../assets/images/tb1.png" alt=""> Số điểm của bạn có thể trãi nghiệm game từ nhà cái F8BET</li> 
                                        </ul>
                                        <p class="tb_2"><img src="../assets/images/tb2.png" alt=""></p>
                                        <p class="tb_3"><a :href="linkf8bet" target="_blank"><img src="../assets/images/tb3.png" alt=""></a></p>
                                        <p class="luuy">Lưu ý</p>
                                    </div>
                                    <div class="note_cash_live">
                                        <h4>Vui lòng nhập đúng số tiền cần nạp ví dụ 50 = 50,000 vnd,</h4>
                                        <h5>mọi vấn đề thắc mắc xin liên hệ telegram: <a :href="telegram" target="_blank">{{ telegram }}</a></h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        <!-- end modal cash in -->
        <a :href="linkgame" class="gameicon_mobile" target="_blank"><img src="../assets/images/game.gif" alt=""></a>
    </div>
</template>

<script>
import HLSCore from '@cloudgeek/playcore-hls'
import moment from 'moment'
import Swal from 'sweetalert2'

import $ from 'jquery'

import Login_f8 from '@/components/Login'
import Register_f8 from '@/components/Register'

// import SocketIO from 'socket.io-client'

import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Navigation } from 'vue3-carousel'

import {reactive} from "vue"
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
$( document ).ready(function() {
    $('.checkHidHeader').parent().addClass('hideheader')
    $(document).on('click', '.hide_chat1', function() {
        $('.wp-chat-stream').addClass('hide_chat_click');
        $('.hide_chat1').hide()
        $('.show_chat1').show()
    });
    $(document).on('click', '.show_chat1', function() {
        $('.wp-chat-stream').removeClass('hide_chat_click');
        $('.show_chat1').hide()
        $('.hide_chat1').show()
    });
});
export default {
    name: 'DetailAsian_nm',
    setup() {
        const data = reactive({
            description: '',
        })
        return {
            data,
            editor: ClassicEditor,
            breakpointssl: {
                0: {
					itemsToShow: 1.5,
					snapAlign: "start"
				},
				575: {
					itemsToShow: 2,
					snapAlign: "start"
				},
				1024: {
					itemsToShow: 3,
					snapAlign: "start"
				},
				1280: {
					itemsToShow: 4,
					snapAlign: "start"
				},
				1440: {
					itemsToShow: 5,
					snapAlign: "start"
				},
			},
            breakpointssls: {
                0: {
					itemsToShow: 1,
					snapAlign: "start"
				},
			},
            breakpoints: {
                0: {
					itemsToShow: 4,
					snapAlign: "start"
				},
                667: {
					itemsToShow: 5,
					snapAlign: "start"
				},
				768: {
					itemsToShow: 7,
					snapAlign: "start"
				},
				1024: {
					itemsToShow: 10,
					snapAlign: "start"
				},
                1364: {
					itemsToShow: 14,
					snapAlign: "start"
				},
                1600: {
					itemsToShow: 17,
					snapAlign: "start"
				}
			},
        }
    },
    data () {
        return {
            id: this.$route.params.id,
            free: this.$route.params.free,
            token: window.localStorage.getItem('token'),
            checkHidHeader: window.localStorage.getItem('hiddenhd'),
            players: {},
            volume: 90,
            HLSCore,
            page: 1,
            perPage: 30,
            totalCount: 0,
            datas: [],
            messagePin: '',
            linkgame: '',
            facebook: '',
            telegram: '',
            telegrams: '',
            linkf8bet: '',
            detailLive: [],
            listLiveidol: [],
            listIcon: [],
            hideIframe: 0,
            showModal_dk: false,
            showModal: false,
            viewVideo18: Math.floor(1000 + Math.random() * 9000),
            idtesst: '',
            checkMobile: 0,
            other: '',
            showmodal_cashin: false,
            stickyEnabled: true,
            listidolmb: [],
            nameIdol: '',
            checkFolow: 0,
            listlivefree: [],
            listLiveidol1: [],
            checkFlSl: 0
        }
    },
    components: {
        Carousel,
        Slide,
        Navigation,
        Login_f8,
        Register_f8,
    },
    mounted() {
        let isMobile = /Mobi/i.test(navigator.userAgent)
        if(isMobile == true){
            this.checkMobile = 1
        }

        // let ss = sessionStorage.getItem("session");
        // if(this.token === null && ss == null){
        //     var title_ss = 'user0';
        //     var num_ran = Math.floor(Math.random() * 1000000000);
        //     var session = title_ss + num_ran
        //     sessionStorage.setItem("session", session);
        // }
        let thismain = this
        $.ajax({
            // <div class="dtxt" title="23 Nov 22, 02:10 AM" data-time="${this.created_at}">${timeAgo(this.created_at)}</div>
            url: thismain.api_chatdexuat,
            type: "GET",
            data: { id_live : this.id },
            dataType: 'json',
            success : function( response ) {
                if(response.data.length > 0){
                    var url_append = response.data[0].live_id;
                    $.each(response.data, function(){
                        if(this.sticker !== null){
                            this.stickerDV = `<div class="namechat_live">${this.fullname} <b class="point_css point_css_chat point_css${this.level_rank}">${this.level_rank}</b></div><div class="imgsticker"><img src="https://f8game.click/${this.sticker}" alt=""></div>`;
                        }else{
                            if(this.type==3){
                                this.stickerDV = `<div class="namechat_live namechat_live_inroom">${this.fullname} <b class="point_css point_css_chat point_css${this.level_rank}">${this.level_rank}</b><span class="textchat_content">${this.content}</span></div>`;
                            }else if(this.type==4){
                                this.stickerDV = `<div class="namechat_live namechat_live_inroom">${this.fullname} <b class="point_css point_css_chat point_css${this.level_rank}">${this.level_rank}</b><span class="textchat_content"><a href="${this.content}" target="_blank">${this.content}</a></span></div>`;
                            }else{
                                this.stickerDV = `<div class="namechat_live">${this.fullname} <b class="point_css point_css_chat point_css${this.level_rank}">${this.level_rank}</b><span class="textchat_content">${this.content}</span></div>`;
                            }
                        }
                        let str = `<div class="wp_item_chat_live msg msg-${this.id}">
                                    ${this.stickerDV}
                                </div>`;
                        setTimeout(() => {
                            $(`#${url_append}`).prepend(str);
                            var hg = $('.chat-text').prop('scrollHeight');
                            $('.chat-text').animate({ scrollTop: hg+100+'px' }, 5);
                        }, 500);
                        
                    });
                }
            },
        });
    },
    created () {
        // 60000 = 60s
        this.getDetaillive()
        this.getInfoLink()
        this.listIconChat()
        this.getDataDxlive()
        if(this.token==null){
            if(this.free==0){
                this.getTimeViewLive()
                setInterval(this.showLogin, 60000);
                if( this.$cookies.get('logins') == 'show' ){
                    this.showLogin()
                }
            }
        }else{
            if(this.free==0){
                this.getTimeView()
                setInterval(this.getTimeView, 180000);
            }
            this.checkfllow()
        }
        setInterval(this.setRandomView, 15000);
    },
    methods: {
        getInfoLink () {
            this.axios.get(this.api_previewLink + '?domain=' + this.url_param).then((response) => {
                this.messagePin = response.data.data.list_data.pin_message
                this.linkgame = response.data.data.list_data.link_game;
                this.facebook = response.data.data.list_data.facebook
                this.telegram = response.data.data.list_data.telegram
                this.telegrams = response.data.data.list_data.telegram2
                this.linkf8bet = response.data.data.list_data.banner
            })
        },
        getDetaillive () {
            this.axios.get(this.api_listAsian).then((response) => {
                this.listlivefree = response.data.data.filter(d => d[0] !== this.id && d[4] == 200 && d[6] == 1)
                this.detailLive = response.data.data.filter(d => d[0] === this.id)
                this.nameIdol = this.detailLive[0][2]
                this.listidolmb = this.shuffle(response.data.data.filter(d => d[0] !== this.id && d[4] == 200 && d[6] == 1))
                this.listLiveidol = this.shuffle(response.data.data.filter(d => d[0] !== this.id && d[6] == 1))
                this.totalCount = this.listLiveidol.length
                this.paginatedData()
            })
        },
        paginatedData () {
            this.datas = this.listLiveidol.slice((this.page - 1) * this.perPage, this.page * this.perPage)
        },
        clickCallback (pageNum) {
            this.page = pageNum;
            this.paginatedData();
        },
        viewCore(id, player) {
            this.players[id] = player;
            // this.play(id)
        },
        play(id) {
            this.players && this.players[id] && this.players[id].play();
        },
        pause(id) {
            this.players && this.players[id] && this.players[id].pause();
        },
        pip(id) {
            this.players &&
            this.players[id] &&
            this.players[id].requestPictureInPicture();
        },
        format_date(value){
            if (value) {
                return moment(String(value)).format('hh:mm DD/MM')
            }
        },
        copyToClipBoard (textToCopy) {
            navigator.clipboard.writeText(textToCopy)
            Swal.fire({
                position: 'top-end',
                showConfirmButton: false,
                title: 'Copy thành công',
                icon: 'success',
                timer: 2000
            });
        },
        listIconChat (){
            if(this.token == null){
                this.axios.get(this.api_liststickerlg).then((response) => {
                    this.listIcon = response.data.data.data_sticker
                })
            }else{
                this.axios.get(this.api_liststicker, {
                    headers: {
                        Authorization: 'Bearer ' + this.token
                    } 
                }).then((response) => {
                    this.listIcon = response.data.data.data_sticker
                })
            }
        },
        sendIconChat (event) {
            var dataImg = event.target.getAttribute('data_img')
            $('.dataImg_chat').val(dataImg)
            $('#chat-submit').trigger('click')
            $('.list_icon_chat').hide()
        },
        sendChat (e) {
            e.preventDefault();
            var content = $('#content_chat').val();
            var url_lk = $('input[name="url-chat"]').val();
            var dataimage = $('.dataImg_chat').val()
            if(this.token == null){
                Swal.fire({
                    position: 'top-end',
                    showConfirmButton: false,
                    title: 'Bạn cần đăng nhập để chat',
                    icon: 'error',
                    timer: 2000
                });
                return false;
            }else{
                this.axios.post(this.api_sendchatdexuat, 
                    {
                        id_live: url_lk,
                        content: content,
                        sticker: dataimage
                    },
                    {
                        headers: {
                            Authorization: 'Bearer ' + this.token
                        }
                    }
                ).then((response) => {
                    $('#content_chat').val('');
                    $('.dataImg_chat').val('');
                    this.var_res = response
                }).catch(e => {
                    Swal.fire({
                        position: 'top-end',
                        showConfirmButton: false,
                        title: e.response.data.message,
                        icon: 'error',
                        timer: 2000
                    });
                    $('.dataImg_chat').val('');
                    this.var_res = e
                })
                return false;
            }
        },
        showIconChatLive () {
            $('.list_icon_chat').slideToggle();
        },
        viewmodal_rg () {
            this.showModal_dk = true
            this.showModal = false
        },
        viewmodal_lg () {
            this.showModal = true
            this.showModal_dk = false
        },
        showLogin () {
            this.showModal = true
            this.hideIframe = 1
            this.$cookies.set('logins', 'show', "60s");
        },
        closeMdLg (){
            this.showModal = false
            window.location.href = '/'
        },
        closeMdRg (){
            this.showModal_dk = false
            window.location.href = '/'
        },
        FormLoginShow () {
            this.showModal = true
            this.tabSelecteds = 'login'
            this.checkClick = 1
        },
        setRandomView () {
            this.viewVideo18 = Math.floor(1000 + Math.random() * 9000)
        },
        shuffle(arra1) {
            var ctr = arra1.length, temp, index;
            while (ctr > 0) {
                index = Math.floor(Math.random() * ctr);
                ctr--;
                temp = arra1[ctr];
                arra1[ctr] = arra1[index];
                arra1[index] = temp;
            }
            return arra1;
        },
        handleSlideStart(data) {
            $('.list_slider_mobile').addClass('hidepause')
            this.viewVideo18 = Math.floor(1000 + Math.random() * 9000)
            this.idtesst = data.slidingToIndex + 1
            let vdt = data.slidingToIndex
            let vdg = data.slidingToIndex + 1
            let vds = data.slidingToIndex + 2
            this.play('video'+vdg)
            this.pause('video'+vdt)
            this.pause('video'+vds)

            if(this.token == null){
                this.checkflsl = 0
            }else{
                let idvd = $('.idvideo'+vdg).val()
                this.axios.get(this.api_listfollow,
                    {
                        headers: {
                            Authorization: 'Bearer ' + this.token
                        }
                    }
                ).then((response) => {
                    // console.log(vdg, response.data.data.filter(d => d.live_id == idvd).length);
                    if(response.data.data.filter(d => d.live_id == idvd).length > 0){
                        this.checkflsl = 1
                    }else{
                        this.checkflsl = 0
                    }
                })
            }
        },
        getTimeView () {
            this.axios.get(this.api_timeview + '?id=' + this.id, 
                {
                    headers: {
                        Authorization: 'Bearer ' + this.token
                    }
                }
            ).then((response) => {
                this.other = response
            }).catch(e => {
                Swal.fire({
                    position: 'top-end',
                    showConfirmButton: false,
                    title: e.response.data.message,
                    icon: 'error',
                    timer: 5000
                });
                setTimeout(() => {
                    this.hideIframe = 1
                    this.showmodal_cashin = true
                }, 2000);
            })
        },
        getTimeViewLive () {
            let session = sessionStorage.getItem("session");
            this.axios.get(this.api_timeviewnolg + '?id=' + this.id + '&session=' + session).then((response) => {
                this.other = response
            }).catch(e => {
                // console.log(e);
                this.other = e
            })
        },
        checkCashinLive () {
            this.showmodal_cashin = false
            window.location.href = '/'
        },
        clickClInfo () {
            $('.mobile_stream_dt_dexuat').addClass('topcontrolor')
        },
        followIdol (e) {
            e.preventDefault()
            $('.loading_show').show().fadeIn(10)
            this.axios.post(this.api_followidol, {
                    name_idol: this.nameIdol,
                    type: 1
                },
                {
                    headers: {
                        Authorization: 'Bearer ' + this.token
                    }
                }
            ).then(res => {
                $('#loading_show').css('display', 'none')
                this.checkFolow = 1
                this.$swal({
                    position: 'top-end',
                    icon: 'success',
                    title: res.data.message,
                    showConfirmButton: false,
                    timer: 4000
                })
            }).catch(e => {
                $('#loading_show').css('display', 'none')
                if(e.response.status === 404){
                    this.$swal({
                        position: 'top-end',
                        icon: 'error',
                        title: e.response.data.message,
                        showConfirmButton: false,
                        timer: 3000
                    })
                }
            })
        },
        cancelFolow (e) {
            e.preventDefault()
            $('.loading_show').show().fadeIn(10)
            this.axios.post(this.api_followidol, {
                    name_idol: this.nameIdol,
                    type: 0
                },
                {
                    headers: {
                        Authorization: 'Bearer ' + this.token
                    }
                }
            ).then(res => {
                $('#loading_show').css('display', 'none')
                this.checkFolow = 0
                this.$swal({
                    position: 'top-end',
                    icon: 'success',
                    title: res.data.message,
                    showConfirmButton: false,
                    timer: 4000
                })
            }).catch(e => {
                $('#loading_show').css('display', 'none')
                if(e.response.status === 404){
                    this.$swal({
                        position: 'top-end',
                        icon: 'error',
                        title: e.response.data.message,
                        showConfirmButton: false,
                        timer: 3000
                    })
                }
            })
        },
        checkfllow () {
            this.axios.get(this.api_listfollow,
                {
                    headers: {
                        Authorization: 'Bearer ' + this.token
                    }
                }
            ).then((response) => {
                if(response.data.data.filter(d => d.live_id == this.id).length > 0){
                    this.checkFolow = 1
                }else{
                    this.checkFolow = 0
                }
            })
        },
        getDataDxlive () {
            this.axios.get(this.api_live18_dexuat).then((response) => {
                this.listLiveidol1 = response.data.data.filter(d => d[4] == 200)
            })
        },
        followIdols (event) {
            event.preventDefault()
            var nameId = event.target.getAttribute('data_name')
            this.axios.post(this.api_followidol, {
                    name_idol: nameId,
                    type: 1
                },
                {
                    headers: {
                        Authorization: 'Bearer ' + this.token
                    }
                }
            ).then(res => {
                this.checkflsl = 1
                this.$swal({
                    position: 'top-end',
                    icon: 'success',
                    title: res.data.message,
                    showConfirmButton: false,
                    timer: 4000
                })
            }).catch(e => {
                if(e.response.status === 404){
                    this.$swal({
                        position: 'top-end',
                        icon: 'error',
                        title: e.response.data.message,
                        showConfirmButton: false,
                        timer: 3000
                    })
                }
            })
        },
        cancelFolows (event) {
            event.preventDefault()
            var nameId = event.target.getAttribute('data_name')
            this.axios.post(this.api_followidol, {
                    name_idol: nameId,
                    type: 0
                },
                {
                    headers: {
                        Authorization: 'Bearer ' + this.token
                    }
                }
            ).then(res => {
                this.checkflsl = 0
                this.$swal({
                    position: 'top-end',
                    icon: 'success',
                    title: res.data.message,
                    showConfirmButton: false,
                    timer: 4000
                })
            }).catch(e => {
                if(e.response.status === 404){
                    this.$swal({
                        position: 'top-end',
                        icon: 'error',
                        title: e.response.data.message,
                        showConfirmButton: false,
                        timer: 3000
                    })
                }
            })
        },
        showModalrg () {
            this.showModal = false
            this.showModal_dk = true
        },
        showModallg () {
            this.showModal = true
            this.showModal_dk = false
        }
    },
    beforeUnmount () {
        window.localStorage.setItem('hiddenhd', 0)
        $('#app').removeClass('hideheader')
        $('.list_slider_mobile').removeClass('hidepause')
    }
}
</script>
<style>
</style>
