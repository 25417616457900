<template>
    <div class="root_info_user">
        <div class="info_wrapper">
            <div class="wrapper_intro" style="margin-bottom: 30px;" v-if="typeus == 1">
                <div class="previewidol_live">
                    <div class="cat_chanel">
                        <ul>
                            <li><img src="../assets/images/logo.png" alt=""></li>
                            <li :class="{active: tabSelected === 'payroll'}" v-on:click="changeTab('payroll')">Bảng lương</li>
                            <li :class="{active: tabSelected === 'timesheets'}" v-on:click="changeTab('timesheets')">Bảng chấm công</li>
                            <li :class="{active: tabSelected === 'penalties'}" v-on:click="changeTab('penalties')">Danh sách phạt</li>
                        </ul>
                    </div>
                    <div class="content_tab_chanel">
                        <div class="payroll_tb" v-if="tabSelected === 'payroll'">
                            <table class="table_chanel">
                                <thead>
                                    <tr>
                                        <th width="7%">STT</th>
                                        <th width="30%">Lương</th>
                                        <th width="10%">Phạt</th>
                                        <th width="30%">Thực nhận</th>
                                        <th width="23%">Ngày tạo</th>
                                    </tr>
                                </thead>
                                <tbody v-if="datas.length > 0">
                                    <tr v-for="(item, index) in datas" :key="index">
                                        <td>{{ index + 1 }}</td>
                                        <td>
                                            {{ new Intl.NumberFormat().format(item.salary) }} vnđ
                                        </td>
                                        <td>
                                            {{ item.qc }}%
                                        </td>
                                        <td>
                                            {{ new Intl.NumberFormat().format(item.total_salary) }} vnđ
                                        </td>
                                        <td>{{ formatDate(item.ngay_tao) }}</td>
                                    </tr>
                                </tbody>
                                <tbody v-else>
                                    <tr>
                                        <td colspan="5">Chưa có bảng lương</td>
                                    </tr>
                                </tbody>
                            </table>
                            <paginate @update:modelValue="clickCallback" :totalCount="totalCount" :limit="perPage" v-model="page"></paginate>
                        </div>
                        <div class="payroll_tb" v-if="tabSelected === 'timesheets'">
                            <table class="table_chanel">
                                <thead>
                                    <tr>
                                        <th width="7%">STT</th>
                                        <th width="15%">Thời gian live</th>
                                        <th width="28%">Lương</th>
                                        <th width="28%">Thực nhận</th>
                                        <th width="24%">Ngày làm việc</th>
                                    </tr>
                                </thead>
                                <tbody v-if="datass.length > 0">
                                    <tr v-for="(item, index) in datass" :key="index">
                                        <td>{{ index + 1 }}</td>
                                        <td>
                                            {{ item.time }} phút
                                        </td>
                                        <td>
                                            {{ new Intl.NumberFormat().format(item.salary) }} vnđ
                                        </td>
                                        <td>
                                            {{ new Intl.NumberFormat().format(item.total_salary) }} vnđ
                                        </td>
                                        <td>{{ formatDate(item.ngay_tao) }}</td>
                                    </tr>
                                </tbody>
                                <tbody v-else>
                                    <tr>
                                        <td colspan="5">Chưa có bảng chấm công</td>
                                    </tr>
                                </tbody>
                            </table>
                            <paginate @update:modelValue="clickCallbacks" :totalCount="totalCounts" :limit="perPages" v-model="pages"></paginate>
                        </div>
                        <div class="payroll_tb" v-if="tabSelected === 'penalties'">
                            <table class="table_chanel">
                                <thead>
                                    <tr>
                                        <th width="7%">STT</th>
                                        <th width="40%">Nội dung</th>
                                        <th width="20%">Mức phạt</th>
                                        <th width="33%">Ngày phạt</th>
                                    </tr>
                                </thead>
                                <tbody v-if="datasss.length > 0">
                                    <tr v-for="(item, index) in datasss" :key="index">
                                        <td>{{ index + 1 }}</td>
                                        <td>
                                            {{ item.info }}
                                        </td>
                                        <td>
                                            {{ item.value }} %
                                        </td>
                                        <td>{{ formatDate(item.ngay_tao) }}</td>
                                    </tr>
                                </tbody>
                                <tbody v-else>
                                    <tr>
                                        <td colspan="4">Chưa có danh sách phạt</td>
                                    </tr>
                                </tbody>
                            </table>
                            <paginate @update:modelValue="clickCallbackss" :totalCount="totalCountss" :limit="perPagess" v-model="pagess"></paginate>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import $ from 'jquery'
export default {
    name: 'Payroll_nm',
    data () {
        return {
            tabSelected: 'payroll',
            page: 1,
            perPage: 15,
            totalCount: 0,
            datas: [],
            token: window.localStorage.getItem('token'),
            typeus: window.localStorage.getItem('typeus'),
            listPayroll: [],
            listTimesheets: [],
            pages: 1,
            perPages: 15,
            totalCounts: 0,
            datass: [],
            listPenalties: [],
            pagess: 1,
            perPagess: 15,
            totalCountss: 0,
            datasss: [],
        }
    },
    components: {
    },
    created () {
        this.GetPayroll()
        this.GetTimesheets()
        this.GetPenalties()
    },
    methods: {
        changeTab (tab) {
            this.tabSelected = tab
        },
        formatDate (date) {
            var splitdate = date.split(' ')
            var datePart = splitdate[0].match(/\d+/g),
            year = datePart[2].substring(0),
            month = datePart[1], 
            day = datePart[0];
            // var gpg = splitdate[1].split(':');
            // gpg[0] + ':' + gpg[1] + ' ' + 
            return year + '-' + month + '-' + day;
        },
        GetPayroll () {
            let today = new Date();
            let yearP = today.getFullYear()
            let monthP = ''
            if((today.getMonth()+1) < 10){
                monthP = '0' + (today.getMonth()+1)
            }else{
                monthP = (today.getMonth()+1)
            }
            let yearMonth = yearP + '-' + monthP
            this.axios.get(this.api_payroll + '?date=' + yearMonth,
                {
                    headers: {
                        Authorization: 'Bearer ' + this.token
                    }
                }
            ).then(res => {
                this.listPayroll = res.data.data
                this.totalCount = this.listPayroll.length
                this.paginatedData()
            })
        },
        paginatedData () {
            this.datas = this.listPayroll.slice((this.page - 1) * this.perPage, this.page * this.perPage)
        },
        clickCallback (pageNum) {
            this.page = pageNum;
            this.paginatedData();
        },
        GetTimesheets () {
            let today = new Date();
            let yearP = today.getFullYear()
            let monthP = ''
            let dateP = today.getDate()
            if((today.getMonth()+1) < 10){
                monthP = '0' + (today.getMonth()+1)
            }else{
                monthP = (today.getMonth()+1)
            }
            let time_start = yearP + '-' + monthP + '-' + '01'
            let time_end = yearP + '-' + monthP + '-' + dateP
            this.axios.get(this.api_timesheets + '?time_start=' + time_start + '&time_end=' + time_end,
                {
                    headers: {
                        Authorization: 'Bearer ' + this.token
                    }
                }
            ).then(res => {
                this.listTimesheets = res.data.data
                this.totalCounts = this.listTimesheets.length
                this.paginatedDatas()
            })
        },
        paginatedDatas () {
            this.datass = this.listTimesheets.slice((this.pages - 1) * this.perPages, this.pages * this.perPages)
        },
        clickCallbacks (pageNum) {
            this.pages = pageNum;
            this.paginatedDatas();
        },
        GetPenalties () {
            let today = new Date();
            let yearP = today.getFullYear()
            let monthP = ''
            let dateP = today.getDate()
            if((today.getMonth()+1) < 10){
                monthP = '0' + (today.getMonth()+1)
            }else{
                monthP = (today.getMonth()+1)
            }
            let time_start = yearP + '-' + monthP + '-' + '01'
            let time_end = yearP + '-' + monthP + '-' + dateP
            this.axios.get(this.api_penalties + '?time_start=' + time_start + '&time_end=' + time_end,
                {
                    headers: {
                        Authorization: 'Bearer ' + this.token
                    }
                }
            ).then(res => {
                this.listPenalties = res.data.data
                this.totalCounts = this.listPenalties.length
                this.paginatedDatass()
            })
        },
        paginatedDatass () {
            this.datasss = this.listPenalties.slice((this.pagess - 1) * this.perPagess, this.pagess * this.perPagess)
        },
        clickCallbackss (pageNum) {
            this.pagess = pageNum;
            this.paginatedDatass();
        },
    }
}
</script>
<style>
</style>
