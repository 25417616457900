<template>
    <div class="root_social">
        <div class="container">
            <div class="wp_social">

                <div class="wp_item_social">
                    <div class="item_social">
                        <div class="info_user_social">
                            <div class="img_user_social">
                                <img src="../assets/images/admin.png" alt="">
                            </div>
                            <div class="name_user_social">
                                <p class="name_us_sca">Nguyễn Văn A</p>
                                <p class="time_social">10 phút</p>
                            </div>
                        </div>
                        <div class="content_social">
                            <div class="text_social">
                                <div class="status_social">
                                    <p>em chuyên mua rau nhà trồng bà con ai bán alô em 0123 456 789</p>
                                </div>
                                <div class="img_video_social">
                                    <Fancybox>
                                        <a data-fancybox="" href="https://scontent.fdad3-4.fna.fbcdn.net/v/t39.30808-6/433865460_442895048273096_2789078774783867779_n.jpg?stp=dst-jpg_p526x296&_nc_cat=104&ccb=1-7&_nc_sid=5f2048&_nc_ohc=_V2jvXBXW8sAX9Ir7rM&_nc_ht=scontent.fdad3-4.fna&oh=00_AfDINtYawvisRMfNHSrPLsSTtdgjnSAai8eP7QucaeXZcw&oe=66119620">
                                            <img src="https://scontent.fdad3-4.fna.fbcdn.net/v/t39.30808-6/433865460_442895048273096_2789078774783867779_n.jpg?stp=dst-jpg_p526x296&_nc_cat=104&ccb=1-7&_nc_sid=5f2048&_nc_ohc=_V2jvXBXW8sAX9Ir7rM&_nc_ht=scontent.fdad3-4.fna&oh=00_AfDINtYawvisRMfNHSrPLsSTtdgjnSAai8eP7QucaeXZcw&oe=66119620" alt="">
                                        </a>
                                    </Fancybox>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="wp_item_social">
                    <div class="item_social">
                        <div class="info_user_social">
                            <div class="img_user_social">
                                <img src="../assets/images/admin.png" alt="">
                            </div>
                            <div class="name_user_social">
                                <p class="name_us_sca">Nguyễn Văn A</p>
                                <p class="time_social">10 phút</p>
                            </div>
                        </div>
                        <div class="content_social">
                            <div class="text_social">
                                <div class="status_social">
                                    <p>em chuyên mua rau nhà trồng bà con ai bán alô em 0123 456 789</p>
                                </div>
                                <div class="img_video_social">
                                    <Fancybox>
                                        <a data-fancybox="" href="https://scontent.fdad3-4.fna.fbcdn.net/v/t39.30808-6/433865460_442895048273096_2789078774783867779_n.jpg?stp=dst-jpg_p526x296&_nc_cat=104&ccb=1-7&_nc_sid=5f2048&_nc_ohc=_V2jvXBXW8sAX9Ir7rM&_nc_ht=scontent.fdad3-4.fna&oh=00_AfDINtYawvisRMfNHSrPLsSTtdgjnSAai8eP7QucaeXZcw&oe=66119620">
                                            <img src="https://scontent.fdad3-4.fna.fbcdn.net/v/t39.30808-6/433865460_442895048273096_2789078774783867779_n.jpg?stp=dst-jpg_p526x296&_nc_cat=104&ccb=1-7&_nc_sid=5f2048&_nc_ohc=_V2jvXBXW8sAX9Ir7rM&_nc_ht=scontent.fdad3-4.fna&oh=00_AfDINtYawvisRMfNHSrPLsSTtdgjnSAai8eP7QucaeXZcw&oe=66119620" alt="">
                                        </a>
                                    </Fancybox>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import Fancybox from './Fancybox.vue';
export default {
    name: 'Social_nm',
    data () {
        return {
            
        }
    },
    components: {
        Fancybox
    },
    created () {
        // this.getListPost()
    },
    methods: {
        // getListPost () {
        //     this.axios.get(this.api_listPost).then((response) => {
        //         console.log(response);
        //     })
        // },
    }
}
</script>
<style>
</style>
