import { createRouter, createWebHistory } from 'vue-router'

import Home_live from '@/components/Home.vue'
import Live_nm from '@/components/Live.vue'
import LiveDetail_nm from '@/components/LiveDetail.vue'
import LiveDetailShow_nm from '@/components/LiveDetailShow.vue'
import Info_nm from '@/components/Info.vue'
import InfoIdol_nm from '@/components/InfoIdol.vue'
import VideoDetail_nm from '@/components/VideoDetail.vue'
import VideoDetailIdol_nm from '@/components/VideoDetailIdol.vue'
import IdolLive_nm from '@/components/IdolLive.vue'
import Support_nm from '@/components/Support.vue'
import SupportDetail_nm from '@/components/SupportDetail.vue'
import Faq_nm from '@/components/Faq.vue'
import Gift_nm from '@/components/Gift.vue'
import MessageGroup_nm from '@/components/MessageGroup.vue'
import Video_nm from '@/components/Video.vue'
import VideoPlay_nm from '@/components/VideoPlay.vue'
import VideoPropose_nm from '@/components/VideoPropose.vue'
import DetailVideoPropose_nm from '@/components/DetailVideoPropose.vue'
import ListIdol_nm from '@/components/ListIdol.vue'
import ListIdolfollow_nm from '@/components/ListIdolfollow.vue'
import Asian_nm from '@/components/Asian.vue'
import DetailAsian_nm from '@/components/DetailAsian.vue'
import Chat_nm from '@/components/Chat.vue'
import ListRg_nm from '@/components/ListRg.vue'
import Payroll_nm from '@/components/Payroll.vue'
import Notification_nm from '@/components/Notification.vue'
import LiveBg_nm from '@/components/LiveBg.vue'
import DetailLiveBG_nm from '@/components/DetailLiveBG.vue'
import Social_nm from '@/components/Social.vue'
import Games_nm from '@/components/Games.vue'
import Contact_nm from '@/components/Contact.vue'
import Livegame_nm from '@/components/Livegame.vue'
import DetailGame_nm from '@/components/DetailGame.vue'

const routes = [
    {
        path: '/',
        name: 'Home_live',
        component: Home_live
    },
    {
        path: '/live',
        name: 'Live_nm',
        component: Live_nm
    },
    {
        path: '/live-detail/:id/:idlive',
        name: 'LiveDetail_nm',
        component: LiveDetail_nm
    },
    {
        path: '/live-detail-show/:id/:idlive',
        name: 'LiveDetailShow_nm',
        component: LiveDetailShow_nm
    },
    {
        path: '/thong-tin/:id',
        name: 'Info_nm',
        component: Info_nm
    },
    {
        path: '/video-detail/:ididol/:id',
        name: 'VideoDetail_nm',
        component: VideoDetail_nm
    },
    {
        path: '/idol-show',
        name: 'IdolLive_nm',
        component: IdolLive_nm
    },
    {
        path: '/thong-tin-idol/:id',
        name: 'InfoIdol_nm',
        component: InfoIdol_nm
    },
    {
        path: '/video-idol/:ididol/:id',
        name: 'VideoDetailIdol_nm',
        component: VideoDetailIdol_nm
    },
    {
        path: '/support',
        name: 'Support_nm',
        component: Support_nm
    },
    {
        path: '/support-detail/:id',
        name: 'SupportDetail_nm',
        component: SupportDetail_nm
    },
    {
        path: '/faq',
        name: 'Faq_nm',
        component: Faq_nm
    },
    {
        path: '/gift/:id',
        name: 'Gift_nm',
        component: Gift_nm
    },
    {
        path: '/chanel/:ididol/:idcheck/:id',
        name: 'MessageGroup_nm',
        component: MessageGroup_nm
    },
    {
        path: '/video',
        name: 'Video_nm',
        component: Video_nm
    },
    {
        path: '/watch-video/:id',
        name: 'VideoPlay_nm',
        component: VideoPlay_nm
    },
    {
        path: '/video-propose',
        name: 'VideoPropose_nm',
        component: VideoPropose_nm
    },
    {
        path: '/video-propose/:id/:free',
        name: 'DetailVideoPropose_nm',
        component: DetailVideoPropose_nm
    },
    {
        path: '/asian',
        name: 'Asian_nm',
        component: Asian_nm
    },
    {
        path: '/asian/:id/:free',
        name: 'DetailAsian_nm',
        component: DetailAsian_nm
    },
    {
        path: '/list-idol',
        name: 'ListIdol_nm',
        component: ListIdol_nm
    },
    {
        path: '/list-idol-follow',
        name: 'ListIdolfollow_nm',
        component: ListIdolfollow_nm
    },
    {
        path: '/chat/:idUser/:idIdol',
        name: 'Chat_nm',
        component: Chat_nm
    },
    {
        path: '/list-register',
        name: 'ListRg_nm',
        component: ListRg_nm
    },
    {
        path: '/payroll',
        name: 'Payroll_nm',
        component: Payroll_nm
    },
    {
        path: '/notification',
        name: 'Notification_nm',
        component: Notification_nm
    },
    {
        path: '/idol-live',
        name: 'LiveBg_nm',
        component: LiveBg_nm
    },
    {
        path: '/video-idol-live/:id',
        name: 'DetailLiveBG_nm',
        component: DetailLiveBG_nm
    },
    {
        path: '/social',
        name: 'Social_nm',
        component: Social_nm
    },
    {
        path: '/games',
        name: 'Games_nm',
        component: Games_nm
    },
    {
        path: '/contact',
        name: 'Contact_nm',
        component: Contact_nm
    },
    {
        path: '/live-game',
        name: 'Livegame_nm',
        component: Livegame_nm
    },
    {
        path: '/live-game/:id',
        name: 'DetailGame_nm',
        component: DetailGame_nm
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes: routes,
    linkActiveClass: "active"
})

export default router