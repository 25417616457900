<template>
    <div class="wrapper_home mg_top">
        <div class="live_room">
            <div class="container ner_responesize ner_responesize_home">
                <div class="row">
                    <div class="col-md-9 col-sm-9 col-xs-9 video_live_left">
                        <div class="root_liveRoom" v-if="listLiveHome.length > 0">
                            <div :class="{active_margin: listLiveHome.length > 0}">
                                <div class="wrapper_liveroom">
                                    <div class="video_live_room" v-for="(item, index) in listLiveHome.slice(0, 1)" v-bind:key="index">
                                        <div class="wplive_home">
                                            <div class="video_f8live_home" v-if="country == 'Vietnam'">
                                                <div v-if="item.device == 1" class="desktop_stream wrapper_video">
                                                    <iframe v-if="item.live > 0" width="100%" class="iframe_videof8" :src="item.luong + '?playsinline=1'" title="" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>
                                                </div>
                                                <div v-else class="mobile_stream">
                                                    <iframe v-if="item.live > 0" width="" height="533" class="iframe_videof8" :src="item.luong + '?playsinline=1'" title="" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>
                                                </div>
                                            </div>
                                            <div class="video_f8live_home" v-else>
                                                <div v-if="item.device == 1" class="desktop_stream">
                                                    <vue3-video-player
                                                        autoplay
                                                        :core="HLSCore"
                                                        :src="item.link_m3u8"
                                                        title=""
                                                        resolution="408p"
                                                        :view-core="viewCore.bind(null, `videolivecam${index}`)"
                                                        :id="`videolivecam${index}`"
                                                    />
                                                </div>
                                                <div v-else class="mobile_stream">
                                                    <vue3-video-player
                                                        autoplay
                                                        :core="HLSCore"
                                                        :src="item.link_m3u8"
                                                        title=""
                                                        resolution="408p"
                                                        :view-core="viewCore.bind(null, `videolivecams${index}`)"
                                                        :id="`videolivecams${index}`"
                                                    />
                                                </div>
                                            </div>
                                            <router-link class="room_live_a" :data_device="item.device" v-bind:to="{ name: 'LiveDetail_nm', params: { id: item.id, idlive: item.id_live } }" @click="getLocalstoragePage">Vào phòng live</router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="root_liveRoom" v-else>
                            <div class="tvc_f8game_home">
                                <video width="100%" height="653" autoplay muted loop controls class="video-highlight-home" style="width: 100%;" webkit-playsinline playsinline>
                                    <source src="https://f8game.click/tvc/tvc_f8.mp4" type="video/mp4">
                                </video>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 col-sm-3 col-xs-3 rank_idol_right">
                        <div class="wrapper_rank">
                            <h3>
                                <img src="../assets/images/bxh.png" alt="">
                                <span>Bảng xếp hạng</span>
                            </h3>
                            <div class="list_rank_home" :class="{active: check_deveci==1}" v-if="country == 'Vietnam'">
                                <ul>
                                    <li v-for="(item, index) in listBxhs" :key="index">
                                        <router-link v-bind:to="{ name: 'InfoIdol_nm', params: { id: item.id } }">
                                            <p class="stt_rank">{{ index + 1}}ST</p>
                                            <p class="img_rank_idol">
                                                <img :src="link_web + item.hinh_anh" alt="" v-if="item.hinh_anh !== null" class="img_tron">
                                                <img src="../assets/images/user.png" alt="" v-else class="img_tron">
                                            </p>
                                            <p class="name_idol_rank">{{ item.name }}</p>
                                            <img src="../assets/images/live.png" alt="" v-if="item.live == 1" class="img_live_rank">
                                            <img src="../assets/images/off.png" alt="" v-else class="img_live_rank">
                                        </router-link>
                                    </li>
                                </ul>
                                <p class="view_rank_detail">
                                    <router-link to="/list-idol">Xem thêm</router-link>
                                </p>
                            </div>
                            <div class="list_rank_home active right_cam" v-else>
                                <ul>
                                    <li v-for="(item, index) in listBxhs" :key="index">
                                        <router-link v-bind:to="{ name: 'InfoIdol_nm', params: { id: item.id } }">
                                            <p class="stt_rank">{{ index + 1}}ST</p>
                                            <p class="img_rank_idol">
                                                <img :src="link_web + item.hinh_anh" alt="" v-if="item.hinh_anh !== null" class="img_tron">
                                                <img src="../assets/images/user.png" alt="" v-else class="img_tron">
                                            </p>
                                            <p class="name_idol_rank">{{ item.name }}</p>
                                            <img src="../assets/images/live.png" alt="" v-if="item.live == 1" class="img_live_rank">
                                            <img src="../assets/images/off.png" alt="" v-else class="img_live_rank">
                                        </router-link>
                                    </li>
                                </ul>
                                <p class="view_rank_detail">
                                    <router-link to="/list-idol">Xem thêm</router-link>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container ner_responesize">
            <!-- Chơi game -->
            <div class="wrapper_idol_live_home wrapper_idol_live_home0" v-if="listLiveHome.length > 0">
                <div class="title_item_idol">
                    <h3>
                        <img src="../assets/images/cg.png" alt="">
                        <span>Chơi game</span>
                        <a @click="showModal = true" v-if="token==null">Xem thêm</a>
                        <router-link to="/live" v-else>Xem thêm</router-link>
                    </h3>
                </div>
                <div class="root_item_idol">
                    <Carousel :wrap-around="false" :breakpoints="breakpoints">
                        <Slide v-for="(item, indexs) in listLiveHome.slice(0,10)" :key="indexs">
                            <div class="item_live item_live_97">
                                <div class="img_live rela_img">
                                    <router-link v-bind:to="{ name: 'LiveDetail_nm', params: { id: item.id, idlive: item.id_live } }" @click="getLocalstoragePage">
                                        <img :src="link_web + item.background" alt="" v-if="item.background != null">
                                        <img src="../assets/images/lv.png" alt="" v-else>
                                        <div class="hover_icon">
                                            <font-awesome-icon icon="fa-solid fa-caret-right" />
                                        </div>
                                    </router-link>
                                    <!-- <router-link v-if="token != null && typeus != 1" v-bind:to="{ name: 'Chat_nm', params: { idUser: numberus, idIdol: item.id } }" class="imgchat_idol">
                                        <img src="../assets/images/chatid.png" alt="">
                                    </router-link> -->
                                </div>
                                <div class="text_item_live">
                                    <router-link v-bind:to="{ name: 'LiveDetail_nm', params: { id: item.id, idlive: item.id_live } }" @click="getLocalstoragePage">
                                        <div class="img_live_small">
                                            <img :src="link_web + item.hinh_anh" alt="" v-if="item.hinh_anh != null">
                                            <img src="../assets/images/gx.png" alt="" v-else>
                                        </div>
                                        <div class="text_live_small">
                                            <h3>{{ item.fullname }}</h3>
                                            <div class="type_live">
                                                <p>
                                                    <img src="../assets/images/eye.svg" alt="">
                                                    <span class="sp-02">{{ item.total_view }}</span>
                                                </p>
                                            </div>
                                        </div>
                                        <img src="../assets/images/live.png" alt="" class="img_live_green">
                                    </router-link>
                                </div>
                            </div>
                        </Slide>
                        <template #addons>
                            <Pagination />
                        </template>
                    </Carousel>
                </div>
                <div class="list_idol_mobile_live">
                    <div class="row">
                        <div class="col-md-2 col-sm-2 col-xs-3 wp-item-live" v-for="(item, index) in listLiveHome.slice(0,6)" :key="index">
                            <div class="item_live">
                                <router-link v-bind:to="{ name: 'LiveDetail_nm', params: { id: item.id, idlive: item.id_live } }" @click="getLocalstoragePage">
                                    <div class="img_live">
                                        <img :src="link_web + item.background" alt="" v-if="item.background != null">
                                        <img src="../assets/images/lv.png" alt="" v-else>
                                        <div class="hover_icon">
                                            <font-awesome-icon icon="fa-solid fa-caret-right" />
                                        </div>
                                    </div>
                                    <div class="text_item_live">
                                        <div class="img_live_small">
                                            <img :src="link_web + item.hinh_anh" alt="" v-if="item.hinh_anh != null">
                                            <img src="../assets/images/gx.png" alt="" v-else>
                                        </div>
                                        <div class="text_live_small">
                                            <h3>{{ item.fullname }}</h3>
                                            <div class="type_live">
                                                <p>
                                                    <img src="../assets/images/eye.svg" alt="">
                                                    <span class="sp-02">{{ item.total_view }}</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <p class="detail_mobile_a">
                    <router-link to="/live">Xem thêm</router-link>
                </p>
            </div>
            <!-- Idol bg -->
            <div class="wrapper_idol_live_home wrapper_idol_live_home2" v-if="listIdolbg.length > 0" style="display: none !important;">
                <div class="title_item_idol">
                    <h3>
                        <img src="../assets/images/ddx.png" alt="">
                        <span>Idol</span>
                    </h3>
                </div>
                <div class="list_idol_mobile_live_xnxx">
                    <div class="row">
                        <div class="col-md-2 col-sm-2 col-xs-3 wp-item-live" v-for="(item, index) in listIdolbg.slice(0,10)" :key="index">
                            <div class="item_live">
                                <router-link v-bind:to="{ name: 'DetailLiveBG_nm', params: { id: item.bigo_id } }" @click="getLocalstoragePage">
                                    <div class="img_live">
                                        <img :src="item.cover_l" alt="">
                                        <div class="hover_icon">
                                            <font-awesome-icon icon="fa-solid fa-caret-right" />
                                        </div>
                                    </div>
                                    <div class="text_item_live">
                                        <div class="img_live_small">
                                            <img :src="item.data1" alt="">
                                        </div>
                                        <div class="text_live_small">
                                            <h3>{{ item.nick_name.substring(0, 8) }}</h3>
                                        </div>
                                    </div>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <p class="detail_mobile_live">
                    <a @click="showModal = true" v-if="token==null">Xem thêm</a>
                    <router-link to="/idol-live" v-else>Xem thêm</router-link>
                </p>
            </div>
            <!-- Free -->
            <div class="wrapper_idol_live_home wrapper_idol_live_home2" style="display: none !important;">
                <div class="title_item_idol">
                    <h3>
                        <img src="../assets/images/ddx.png" alt="">
                        <span>Free</span>
                    </h3>
                </div>
                <div class="list_idol_mobile_live_xnxx wp_item_mobile_show">
                    <div class="row">
                        <div class="col-md-2 col-sm-2 col-xs-3 wp-item-live" :class="{nn_dtop: index==2}" v-for="(item, index) in listLiveAsian1.slice(0,3)" :key="index">
                            <div class="item_live">
                                <router-link v-bind:to="{ name: 'DetailAsian_nm', params: { id: item[0], free: 1} }" @click="getLocalstoragePage">
                                    <div class="img_live">
                                        <img :src="item[1]" alt="">
                                        <div class="hover_icon">
                                            <font-awesome-icon icon="fa-solid fa-caret-right" />
                                        </div>
                                    </div>
                                    <div class="text_item_live">
                                        <div class="img_live_small">
                                            <img :src="item[1]" alt="">
                                        </div>
                                        <div class="text_live_small">
                                            <h3>{{ item[2].substring(0, 10) }}</h3>
                                        </div>
                                        <img :src="item[3]" alt="" class="img_live_green">
                                    </div>
                                </router-link>
                            </div>
                        </div>
                        <div class="col-md-2 col-sm-2 col-xs-3 wp-item-live" :class="{nn_im_dt: index < 3}" v-for="(item, index) in listLiveidol1.slice(0,6)" :key="index">
                            <div class="item_live">
                                <router-link v-bind:to="{ name: 'DetailVideoPropose_nm', params: { id: item[0], free: 1} }" @click="getLocalstoragePage">
                                    <div class="img_live">
                                        <img :src="item[1]" alt="">
                                        <div class="hover_icon">
                                            <font-awesome-icon icon="fa-solid fa-caret-right" />
                                        </div>
                                    </div>
                                    <div class="text_item_live">
                                        <div class="img_live_small">
                                            <img :src="item[1]" alt="">
                                        </div>
                                        <div class="text_live_small">
                                            <h3>{{ item[2].substring(0, 10) }}</h3>
                                        </div>
                                        <img :src="item[3]" alt="" class="img_live_green">
                                    </div>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Live Game -->
            <div class="wrapper_idol_live_home wrapper_idol_live_home2" v-if="listLiveGame.length > 0" style="display: none !important;">
                <div class="title_item_idol">
                    <h3>
                        <img src="../assets/images/ddx.png" alt="">
                        <span>Live game</span>
                    </h3>
                </div>
                <div class="list_idol_mobile_live_xnxx">
                    <div class="row">
                        <div class="col-md-2 col-sm-2 col-xs-3 wp-item-live" v-for="(item, index) in listLiveGame.slice(0,10)" :key="index">
                            <div class="item_live">
                                <router-link v-bind:to="{ name: 'DetailGame_nm', params: { id: item.bigo_id } }" @click="getLocalstoragePage">
                                    <div class="img_live">
                                        <img :src="item.cover_l" alt="">
                                        <div class="hover_icon">
                                            <font-awesome-icon icon="fa-solid fa-caret-right" />
                                        </div>
                                    </div>
                                    <div class="text_item_live">
                                        <div class="img_live_small">
                                            <img :src="item.data1" alt="">
                                        </div>
                                        <div class="text_live_small">
                                            <h3>{{ item.nick_name.substring(0, 8) }}</h3>
                                            <div class="type_live">
                                                <p>
                                                    <img src="../assets/images/eye.svg" alt="">
                                                    <span class="sp-02">{{ item.user_count }}</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <p class="detail_mobile_live">
                    <a @click="showModal = true" v-if="token==null">Xem thêm</a>
                    <router-link to="/live-game" v-else>Xem thêm</router-link>
                </p>
            </div>
            <!-- 18+ -->
            <div class="wrapper_idol_live_home wrapper_idol_live_home2" v-if="listLiveidol.length > 0">
                <div class="title_item_idol">
                    <h3>
                        <img src="../assets/images/ddx.png" alt="">
                        <span>Live show 18+</span>
                    </h3>
                </div>
                <div class="list_idol_mobile_live_xnxx">
                    <div class="row">
                        <div class="col-md-2 col-sm-2 col-xs-3 wp-item-live" v-for="(item, index) in listLiveidol.slice(0,10)" :key="index">
                            <div class="item_live">
                                <router-link v-bind:to="{ name: 'DetailVideoPropose_nm', params: { id: item[0], free: 0} }" @click="getLocalstoragePage">
                                    <div class="img_live">
                                        <img :src="item[1]" alt="">
                                        <div class="hover_icon">
                                            <font-awesome-icon icon="fa-solid fa-caret-right" />
                                        </div>
                                    </div>
                                    <div class="text_item_live">
                                        <div class="img_live_small">
                                            <img :src="item[1]" alt="">
                                        </div>
                                        <div class="text_live_small">
                                            <h3>{{ item[2].substring(0, 10) }}</h3>
                                        </div>
                                        <img :src="item[3]" alt="" class="img_live_green">
                                    </div>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <p class="detail_mobile_live">
                    <a @click="showModal = true" v-if="token==null">Xem thêm</a>
                    <router-link to="/video-propose" v-else>Xem thêm</router-link>
                </p>
            </div>
            <!-- Asian -->
            <div class="wrapper_idol_live_home wrapper_idol_live_home2" v-if="listLiveAsian.length > 0" style="display: none !important;">
                <div class="title_item_idol">
                    <h3>
                        <img src="../assets/images/ddx.png" alt="">
                        <span>Asian</span>
                    </h3>
                </div>
                <div class="list_idol_mobile_live_xnxx">
                    <div class="row">
                        <div class="col-md-2 col-sm-2 col-xs-3 wp-item-live" v-for="(item, index) in listLiveAsian.slice(0,10)" :key="index">
                            <div class="item_live">
                                <router-link v-bind:to="{ name: 'DetailAsian_nm', params: { id: item[0], free: 0} }" @click="getLocalstoragePage">
                                    <div class="img_live">
                                        <img :src="item[1]" alt="">
                                        <div class="hover_icon">
                                            <font-awesome-icon icon="fa-solid fa-caret-right" />
                                        </div>
                                    </div>
                                    <div class="text_item_live">
                                        <div class="img_live_small">
                                            <img :src="item[1]" alt="">
                                        </div>
                                        <div class="text_live_small">
                                            <h3>{{ item[2].substring(0, 10) }}</h3>
                                        </div>
                                        <img :src="item[3]" alt="" class="img_live_green">
                                    </div>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <p class="detail_mobile_live">
                    <a @click="showModal = true" v-if="token==null">Xem thêm</a>
                    <router-link to="/asian" v-else>Xem thêm</router-link>
                </p>
            </div>
            <!-- Idol follow -->
            <div class="wrapper_idol_live_home wrapper_idol_live_home2" v-if="listFollowidol.length > 0 && token != null">
                <div class="title_item_idol">
                    <h3>
                        <img src="../assets/images/ddx.png" alt="">
                        <span>Idol follow</span>
                    </h3>
                </div>
                <div class="list_idol_mobile_live_xnxx">
                    <div class="row">
                        <div class="col-md-2 col-sm-2 col-xs-3 wp-item-live" v-for="(item, index) in listFollowidol.slice(0,10)" :key="index">
                            <div class="item_live">
                                <router-link v-bind:to="{ name: 'DetailVideoPropose_nm', params: { id: item.live_id, free: 0} }" @click="getLocalstoragePage" v-if="item.is_live==1">
                                    <div class="img_live">
                                        <img :src="item.avatar" alt="">
                                        <div class="hover_icon">
                                            <font-awesome-icon icon="fa-solid fa-caret-right" />
                                        </div>
                                    </div>
                                    <div class="text_item_live">
                                        <div class="img_live_small">
                                            <img :src="item.avatar" alt="">
                                        </div>
                                        <div class="text_live_small">
                                            <h3>{{ item.username.substring(0, 10) }}</h3>
                                        </div>
                                    </div>
                                </router-link>
                                <a @click="offlineIdol" v-else style="cursor: pointer;">
                                    <div class="img_live">
                                        <img :src="item.avatar" alt="">
                                        <div class="hover_icon">
                                            <font-awesome-icon icon="fa-solid fa-caret-right" />
                                        </div>
                                    </div>
                                    <div class="text_item_live">
                                        <div class="img_live_small">
                                            <img :src="item.avatar" alt="">
                                        </div>
                                        <div class="text_live_small">
                                            <h3>{{ item.username.substring(0, 10) }}</h3>
                                        </div>
                                    </div>
                                </a>
                                <div class="unfollow">
                                    <img src="../assets/images/unfl.png" alt="" @click="cancelFolow($event)" :data_name="item.username">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <p class="detail_mobile_live">
                    <router-link to="/list-idol-follow">Xem thêm</router-link>
                </p>
            </div>
            <!-- Bóng đá -->
            <div class="wrapper_idol_live_home wrapper_idol_live_home3" v-if="listSoccer.length > 0">
                <div class="title_item_idol title_item_idol_mb">
                    <h3>
                        <img src="../assets/images/ttbd.png" alt="">
                        <span>Trực tiếp bóng đá</span>
                        <a href="https://vaohang10.tv" target="_blank">Xem thêm</a>
                    </h3>
                </div>
                <div class="root_item_idol root_item_idol_mb">
                    <Carousel :wrap-around="false" :breakpoints="breakpoints">
                        <Slide v-for="(item, indexs) in listSoccer.slice(0,10)" :key="indexs">
                            <div class="item_soccer">
                                <a href="https://vaohang10.tv" target="_blank">
                                    <div class="tournament">
                                        <h2>{{ item.tournament }}</h2>
                                    </div>
                                    <div class="content_soccer">
                                        <div class="home_soccer">
                                            <img :src="item.logo_home" alt="">
                                            <h3>{{ item.name_home }}</h3>
                                        </div>
                                        <div class="btn_info_soccer">
                                            <img src="../assets/images/play.png" alt="">
                                            <p>{{ item.day + ' | '  + item.hours }}</p>
                                        </div>
                                        <div class="away_soccer">
                                            <img :src="item.logo_away" alt="">
                                            <h3>{{ item.name_away }}</h3>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </Slide>
                        <template #addons>
                            <Pagination />
                        </template>
                    </Carousel>
                </div>
            </div>
            <!-- Video -->
            <div class="wrapper_idol_live_home wrapper_idol_live_home4" v-if="listVideoIdol.length > 0">
                <div class="title_item_idol">
                    <h3>
                        <img src="../assets/images/vdeo.png" alt="">
                        <span>Video</span>
                    </h3>
                </div>
                <div class="list_idol_mobile_live">
                    <div class="row">
                        <div class="col-md-6 col-sm-6 col-xs-6 wp_video_tab_mobile wp-item-live" v-for="(item, index) in listVideoIdol.slice(0,6)" :key="index">
                            <div class="item_live">
                                <router-link v-bind:to="{ name: 'VideoPlay_nm', params: { id: item.id } }">
                                    <div class="img_live img_live_video">
                                        <img :src="'https://f8game.pro/assets/uploads/thumbnail/' + item.thumbnail_path" alt="">
                                    </div>
                                    <div class="text_item_live text_item_live1">
                                        <div class="img_live_small">
                                            <img :src="'https://f8game.pro/assets/uploads/thumbnail/' + item.thumbnail_path" alt="">
                                        </div>
                                        <div class="text_live_small videoLeft">
                                            <h3>{{ item.fullname }}</h3>
                                        </div>
                                    </div>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <p class="detail_mobile_a">
                    <router-link to="/video">Xem thêm</router-link>
                </p>
            </div>
            <!-- Phim ngắn -->
            <div class="wrapper_idol_live_home wrapper_idol_live_home5">
                <div class="title_item_idol title_item_idol_mb">
                    <h3>
                        <img src="../assets/images/gamedx.png" alt="">
                        <span>Game đề xuất</span>
                        <a :href="linkgame" target="_blank">Xem thêm</a>
                    </h3>
                </div>
                <div class="root_item_idol root_item_idol_mb">
                    <div class="row">
                        <div class="col-md-4 col-sm-4 col-xs-4 wp_item_game" v-for="(item, index) in listgamemobile" :key="index">
                            <div class="itemgame">
                                <div class="img_game">
                                    <a :href="linkgame" target="_blank">
                                        <img :src="item.img" alt="">
                                    </a>
                                </div>
                                <div class="name_game">
                                    <h4><a :href="linkgame" target="_blank">{{ item.name }}</a></h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="wrapper_film_mb">
                    <div class="title_item_idol title_item_idol_mb">
                        <h3>
                            <img src="../assets/images/pn.png" alt="" class="desktop_show">
                            <span>Phim ngắn</span>
                            <a :href="api_linkvideoFilms" target="_blank" class="xemthem_mb">Xem thêm</a>
                        </h3>
                    </div>
                    <div class="film_left">
                        <div class="one_film_left">
                            <div class="item_film_left" v-for="(item, index) in listVideo.slice(0,1)" :key="index">
                                <a :href="api_linkvideoFilm + item.slug" target="_blank">
                                    <div class="img_video_lf">
                                        <img :src="api_linkimgFilm + item.thumb" alt="">
                                    </div>
                                    <div class="name_video_lf">
                                        <p>{{ item.title }}</p>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="wrapper_film">
                <div class="title_item_idol title_item_idol_mb">
                    <h3>
                        <img src="../assets/images/pn.png" alt="" class="desktop_show">
                        <span>Phim ngắn</span>
                        <a :href="api_linkvideoFilms" target="_blank" class="xemthem_mb">Xem thêm</a>
                    </h3>
                </div>
                <div class="row">
                    <div class="col-md-8 col-sm-8 col-xs-12 wp_film">
                        <div class="film_left">
                            <div class="one_film_left">
                                <div class="item_film_left" v-for="(item, index) in listVideo.slice(0,1)" :key="index">
                                    <a :href="api_linkvideoFilm + item.slug" target="_blank">
                                        <div class="img_video_lf">
                                            <img :src="api_linkimgFilm + item.thumb" alt="">
                                        </div>
                                        <div class="name_video_lf">
                                            <p>{{ item.title }}</p>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div class="list_film_right">
                                <div class="right_film">
                                    <div class="item_film_left" v-for="(item, index) in listVideo.slice(1,4)" :key="index">
                                        <a :href="api_linkvideoFilm + item.slug" target="_blank">
                                            <div class="img_video_lf">
                                                <img :src="api_linkimgFilm + item.thumb" alt="">
                                            </div>
                                            <div class="name_video_lf name_video_lf1">
                                                <p>{{ item.title }}</p>
                                            </div>
                                        </a>
                                    </div>
                                    <p class="view_see"><a :href="api_linkvideoFilms" target="_blank">Xem thêm</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-4 col-xs-12 wp_banner">
                        <div class="img_banner">
                            <a :href="linkf8bet" target="_blank"><img src="../assets/images/banner.png" alt=""></a>
                            <a :href="linkf8bet" target="_blank"><img src="../assets/images/banner1.png" alt=""></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- modal login -->
        <div id="modal-example" class="modal-login">
            <div class="overlay" v-if="showModal" @click="showModal = false"></div>
            <div class="modal" v-if="showModal">
                <div class="wp-modal-login">
                    <div class="header-modal">
                        <button class="close-modal_login" @click="showModal = false"><img src="../assets/images/close_md.png" alt=""></button>
                    </div>
                    <div class="content-modal_tab">
                        <div>
                            <div class="logo_modal_login">
                                <div class="logo_mdal">
                                    <div class="img_md">
                                        <img src="../assets/images/logomd.png" alt="">
                                    </div>
                                    <div class="img_md1">
                                        <img src="../assets/images/logomd1.png" alt="">
                                    </div>
                                </div>
                                <div class="img_pathner_md">
                                    <img src="../assets/images/logomd2.png" alt="">
                                </div>
                            </div>
                            <Login_f8 @showMd="showModalrg" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- end modal login -->
        <!-- modal register -->
        <div id="modal-example" class="modal-login">
            <div class="overlay" v-if="showModal_dk" @click="showModal_dk = false"></div>
            <div class="modal" v-if="showModal_dk">
                <div class="wp-modal-register">
                    <div class="header-modal">
                        <button class="close-modal_login" @click="showModal_dk = false"><img src="../assets/images/close_md.png" alt=""></button>
                    </div>
                    <div class="content-modal_tab">
                        <div>
                            <div class="logo_modal_login">
                                <div class="logo_mdal">
                                    <div class="img_md">
                                        <img src="../assets/images/logomd.png" alt="">
                                    </div>
                                    <div class="img_md1">
                                        <img src="../assets/images/logomd1.png" alt="">
                                    </div>
                                </div>
                                <div class="img_pathner_md">
                                    <img src="../assets/images/logomd2.png" alt="">
                                </div>
                            </div>
                            <div class="form_dangky">
                                <Register_f8 @showMd="showModallg"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- end modal register -->
        <!-- modal thông báo -->
        <div id="modal-example" class="modal-login">
            <div class="overlay" v-if="showmodal_noti" @click="showmodal_noti = false"></div>
            <div class="modal modal_info" v-if="showmodal_noti">
                <div class="wp-modal-info">
                    <a :href="linkf8bet" target="_blank">
                        <img src="../assets/images/gift.png" alt="">
                    </a>
                    <button class="close-modal_login close_gift" @click="hidden_pp"><img src="../assets/images/closegf.png" alt=""></button>
                </div>
            </div>
        </div>
        <!-- end modal thông báo -->
        <!-- modal popup -->
        <div id="modal-example" class="modal-login">
            <div class="overlay" v-if="showmodal_popup" @click="showmodal_popup = false"></div>
            <div class="modal modal_info" v-if="showmodal_popup">
                <div class="wp-modal-info">
                    <a :href="linkf8bet" target="_blank">
                        <img src="../assets/images/gifttb.gif" alt="">
                    </a>
                    <button class="close-modal_login close_gift" @click="showmodal_popup = false"><img src="../assets/images/closegf.png" alt=""></button>
                </div>
            </div>
        </div>
        <!-- end modal popup -->
    </div>
</template>

<script>
import Login_f8 from '@/components/Login'
import Register_f8 from '@/components/Register'
import HLSCore from '@cloudgeek/playcore-hls'
// import Banner_adv from '@/components/Banner'
import { Carousel, Slide, Pagination } from 'vue3-carousel'
import $ from 'jquery'
// import SocketIO from 'socket.io-client'
export default {
    name: 'Home_live',
    setup() {
		return {
			breakpoints: {
                0: {
					itemsToShow: 1.5,
					snapAlign: "start"
				},
				431: {
					itemsToShow: 2,
					snapAlign: "start"
				},
				768: {
					itemsToShow: 3,
					snapAlign: "start"
				},
				1024: {
					itemsToShow: 3,
					snapAlign: "start"
				},
                1280: {
					itemsToShow: 4,
					snapAlign: "start"
				},
			}
		};
	},
    data () {
        return {
            showmodal_noti: false,
            showModal: false,
            showModal_dk: false,
            tabSelected: 'livegame',
            liveRoom: [],
            players: {},
            volume: 90,
            HLSCore,
            check: 0,
            listLive: [],
            listLiveHome: [],
            listLiveidol18: [],
            token: window.localStorage.getItem('token'),
            rank: window.localStorage.getItem('rank'),
            checkes: '',
            listBxh: [],
            listBxhs: [],
            listIdolAll: [],
            listIdol18All: [],
            hot_idol: [],
            typeus: window.localStorage.getItem('typeus'),
            numberus: parseInt(window.localStorage.getItem('numberus')),
            type_live: parseInt(window.localStorage.getItem('type_live')),
            other: '',
            length_hot: 6,
            listSoccer: [],
            listVideo: [],
            lenghVideo: 12,
            listGift: [],
            listIdolAll1: [],
            listIdol18All1: [],
            hot_idol1: [],
            country: 'Vietnam',
            linkf8bet: '',
            check_deveci: 1,
            linkgame: '',
            listLiveidol: [],
            listLiveidol1: [],
            listVideoIdol: [],
            liveMatch: [],
            listItemdate1: [],
            tabSelectedpl: '',
            tabs: '',
            checkMobile: 0,
            listgamemobile: [],
            showmodal_popup: false,
            listFollowidol: [],
            listLiveAsian: [],
            listLiveAsian1: [],
            listIdolbg: [],
            listLiveGame: []
        }
    },
    components: {
        Carousel,
        Slide,
        Pagination,
        Login_f8,
        Register_f8,
    },
    mounted () {
        this.$nextTick(() => {
        })
        let isMobile = /Mobi/i.test(navigator.userAgent)
        if(isMobile == true){
            this.checkMobile = 1
        }
        let ss = sessionStorage.getItem("session");
        if(this.token == null && ss == null){
            var title_ss = 'user0';
            var num_ran = Math.floor(Math.random() * 1000000000);
            var session = title_ss + num_ran
            sessionStorage.setItem("session", session);
        }
    },
    created () {
        this.getDataRoomLive()
        this.getDataliveidol18()
        this.getListBxh()
        this.getListBxhs()
        // if( this.$cookies.get(100) !== 'close' ){
        //     setTimeout(() => {
        //         this.showmodal_noti = true
        //     }, 3000);
        // }
        this.getData()
        this.getdataVideo()
        this.getDataListGift()
        this.socketLive()
        this.getlocation()
        this.getInfoLink()
        this.getDataliveidolApi()
        this.getListVideoIdol()
        this.getDataSocer()
        this.GetListGameMobile()
        if(this.token==null){
            setTimeout(() => {
                this.showmodal_popup = true
            }, 3000);
        }else{
            sessionStorage.clear();
            this.getListFollow()
        }
        this.getDataliveAsian()
        this.getDataliveidolBg()
        this.getDataliveGame()
    },
    computed: {
        loadMore_hotidol() {
            return this.hot_idol.slice(0, this.length_hot);
        },
    },
    methods: {
        getInfoLink () {
            this.axios.get(this.api_previewLink + '?domain=' + this.url_param).then((response) => {
                this.linkf8bet = response.data.data.list_data.banner
                this.linkgame = response.data.data.list_data.link_game;
            })
        },
        showlogin () {
            this.$swal({
                position: 'top-end',
                icon: 'error',
                title: 'Vui lòng đăng nhập',
                showConfirmButton: false,
                timer: 3000
            })
        },
        getDataRoomLive () {
            this.axios.get(this.api_listlive).then((response) => {
                this.listLiveHome = response.data.data.list_user
                if(this.listLiveHome.length > 0){
                    let check_deveci = this.listLiveHome[0].device
                    if(check_deveci == 1){
                        this.check_deveci = 1
                    }else{
                        this.check_deveci = 2
                    }
                }
                // const dataImage = [];
                // listLiveHome.forEach(item => {
                //     let check_mbut = 0
                //     this.axios.get(item.link_m3u8).then(res => {
                //         if(res.status===200){
                //             check_mbut = 1
                //             dataImage.push({...item, check_mbut});
                //         }
                //     }).catch(e => {
                //         if (e.response.status === 404) {
                //             check_mbut = 0
                //         }else{
                //             check_mbut = 1
                //             dataImage.push({...item, check_mbut});
                //         }
                //     })
                // });
                // setTimeout(() => {
                //     this.listLiveHome = dataImage;
                // }, 800);
            })
        },
        getDataliveidol18 () {
            this.axios.get(this.api_listall).then((response) => {
                this.listLiveidol18 = response.data.data.list_user.filter(d => d.type_live === 2 && d.live === 1)
                this.hot_idol = this.shuffle(response.data.data.list_user)
                this.hot_idol1 = response.data.data.list_user
            })
        },
        loadMore() {
            if (this.length_hot > this.hot_idol.length) return;
            this.length_hot = this.length_hot + 6;
        },
        searchIdolHot (e) {
            e.preventDefault()
            let keyname = $('.key_searchIdol').val().toLowerCase()
            let dataArray = []
            $.each(this.hot_idol1, function() {
                if ( this.fullname.toLowerCase().indexOf(keyname) != -1) {
                    return dataArray.push(this);
                }
            });
            this.hot_idol = dataArray
        },
        getDataliveidolApi () {
            this.axios.get(this.api_live18_dexuat).then((response) => {
                // console.log('111', response.data.data);
                this.listLiveidol1 = response.data.data.filter(d => d[4] == 200 && d[6] == 1)
                let isMobile = /Mobi/i.test(navigator.userAgent)
                if(isMobile == true){
                    this.listLiveidol = this.shuffle(response.data.data.filter(d => d[4] == 200 && d[6] == 1))
                }else{
                    this.listLiveidol = this.shuffle(response.data.data.filter(d => d[6] == 1))
                }

                // let listLiveidol = this.shuffle(response.data.data)
                // const datalistIdol = [];
                // listLiveidol.forEach(item => {
                //     let check_mbut = 0
                //     this.axios.get(this.domain_str + item[0] + '/master/' + item[0] + '_480p.m3u8').then(res => {
                //         this.other = res
                //         check_mbut = 1
                //         datalistIdol.push({...item, check_mbut});
                //     }).catch(e => {
                //         this.other = e
                //         check_mbut = 0
                //         datalistIdol.push({...item, check_mbut});
                //     })
                // });

                // setTimeout(() => {
                //     this.listLiveidol = datalistIdol;
                //     // this.totalCount = this.listLiveidol.length
                //     // this.paginatedData()
                // }, 800);
            })
        },
        getData () {
            this.axios.get(this.api_listmatchdates).then((response) => {
                this.listSoccer = response.data.data
            })
        },
        getdataVideo () {
            this.axios.get(this.api_listvideof8bet).then((response) => {
                this.listVideo = response.data.data
            })
        },
        viewCore(id, player) {
            this.players[id] = player;
            this.play(id)
        },
        viewCores(id, player) {
            this.players[id] = player;
        },
        play(id) {
            this.players && this.players[id] && this.players[id].play();
        },
        pip(id) {
            this.players &&
            this.players[id] &&
            this.players[id].requestPictureInPicture();
        },
        retrieveAllCustomer (url) {
            this.axios.get(url).then(res => {
                if(res.status===200){
                    this.check = 1
                }
            }).catch(e => {
                if (e.response.status === 404) {
                    this.check = 0
                }else{
                    this.check = 1
                }
            })
            return this.check;
        },
        getListBxh () {
            this.axios.get(this.api_listBxh).then((response) => {
                this.listBxh = response.data.data.list_user.slice(0, 9)
            })
        },
        getListBxhs () {
            this.axios.get(this.api_rankfollow).then((response) => {
                this.listBxhs = response.data.data.list_user.slice(0, 9)
            })
        },
        seandMbut (id) {
            if(id != null){
                this.axios.post(this.api_offstream, {
                    id: id,
                }).then(res => {
                    this.other = res
                }).catch(e => {
                    this.other = e
                })
            }
        },
        hidden_pp () {
            this.showmodal_noti = false
            this.$cookies.set(100, 'close', "600s");
        },
        getDataListGift () {
            this.axios.get(this.api_listGift).then((response) => {
                this.listGift = response.data.data.list_data
                if(this.listGift.length > 0 && this.$cookies.get(100) !== 'close'){
                    setTimeout(() => {
                        this.showmodal_noti = true
                    }, 3000);
                }
            })
        },
        socketLive() {
            // var thisAgo = this
            // var socket = SocketIO('https://f8game.click/', {
            //     transports: ['websocket', 'polling', 'flashsocket'],
            // });
            // socket.on('livestream:realtime',function(result){
            //     if(result.data.user.live==1){
            //         if(result.data.user.type_live==1){
            //             thisAgo.listLiveHome = thisAgo.listLiveHome.concat(result.data.user)
            //         }
            //         thisAgo.listIdolAll.filter(item => item.id === result.data.user.id).map(function (obj) {
            //             obj.live = 1;
            //             obj.id_live = result.data.user.id_live
            //             return obj;
            //         });
            //         thisAgo.listIdol18All.filter(item => item.id === result.data.user.id).map(function (obj) {
            //             obj.live = 1;
            //             obj.id_live = result.data.user.id_live
            //             return obj;
            //         });
            //         thisAgo.listIdolAll.splice(thisAgo.listIdolAll.findIndex(({id}) => id == result.data.user.id), 1);
            //         thisAgo.listIdol18All.splice(thisAgo.listIdol18All.findIndex(({id}) => id == result.data.user.id), 1);
            //         const dataObj = {
            //             "id": result.data.user.id,
            //             "fullname": result.data.user.fullname,
            //             "sex": result.data.user.sex,
            //             "type_live": result.data.user.type_live,
            //             "live": result.data.user.live,
            //             "hinh_anh": result.data.user.hinh_anh,
            //             "background": result.data.user.background,
            //             "tieu_de": result.data.user.tieu_de,
            //             "point": result.data.user.point,
            //             "number_follow": result.data.user.number_follow,
            //             "time_start": result.data.user.time_start,
            //             "time_end": result.data.user.time_end,
            //             "link_m3u8": result.data.user.link_m3u8,
            //             "luong": result.data.user.luong,
            //             "stream_key": result.data.user.stream_key,
            //             "server_key": result.data.user.server_key,
            //             "id_live": result.data.user.id_live,
            //             "device": result.data.user.device
            //         }
            //         if(result.data.user.type_live == 1){
            //             thisAgo.listIdolAll.unshift(dataObj)
            //         }else{
            //             thisAgo.listIdol18All.unshift(dataObj)
            //         }
            //     }else{
            //         thisAgo.listLiveHome.splice(thisAgo.listLiveHome.findIndex(({id}) => id == result.data.user.id), 1);
            //         thisAgo.listIdolAll.filter(item => item.id === result.data.user.id).map(function (obj) {
            //             obj.live = 0;
            //             return obj;
            //         });
            //         thisAgo.listIdol18All.filter(item => item.id === result.data.user.id).map(function (obj) {
            //             obj.live = 0;
            //             return obj;
            //         });
            //     }
            // });
        },
        getlocation () {
            this.axios.get('https://geolocation-db.com/json/').then((response) => {
                this.country = response.data.country_name
            })
        },
        viewmodal_rg () {
            this.showModal_dk = true
            this.showModal = false
        },
        viewmodal_lg () {
            this.showModal = true
            this.showModal_dk = false
        },
        changeTab (tab) {
            this.tabSelected = tab
        },
        getListVideoIdol () {
            this.axios.get(this.api_listvideoidol).then((response) => {
                this.listVideoIdol = response.data.items
            })
        },
        getDataSocer () {
            this.axios.get(this.api_listmatchdate).then((response) => {
                this.listItemdate1 = response.data.data
                this.tabSelectedpl = Object.keys(this.listItemdate1)[0];
                this.tabs = Object.keys(this.listItemdate1)[0];
                let liveMatch = Object.values(this.listItemdate1)[0];
                if(this.listItemdate1.length > 0){
                    this.liveMatch = liveMatch.slice(0, 1);
                }else{
                    this.liveMatch = []
                }
                
            })
        },
        changeTabpl (tab) {
            this.tabSelectedpl = tab
            this.tabs = tab
        },
        shuffle(arra1) {
            var ctr = arra1.length, temp, index;
            while (ctr > 0) {
                index = Math.floor(Math.random() * ctr);
                ctr--;
                temp = arra1[ctr];
                arra1[ctr] = arra1[index];
                arra1[index] = temp;
            }
            return arra1;
        },
        getLocalstoragePage () {
            window.localStorage.setItem('hiddenhd', 1)
            $('#app').addClass('hideheader')
        },
        GetListGameMobile () {
            this.listgamemobile = [
                { name: 'Kho báu aztec', img: require('@/assets/images/bngame.png')},
                { name: 'Đường mạt chược', img: require('@/assets/images/bngame1.png')},
                { name: 'Đường mạt chược 2', img: require('@/assets/images/bngame2.png')},
                { name: 'Neko may mắn', img: require('@/assets/images/bngame3.png')},
                { name: 'Chiến thắng caishen', img: require('@/assets/images/bngame4.png')},
                { name: 'Kỳ lân mách nước', img: require('@/assets/images/bngame5.png')},
            ]
        },
        clickPlaypp () {
            this.showModal_dk = true
            this.showmodal_popup = false
        },
        getListFollow () {
            this.axios.get(this.api_listfollow,
                {
                    headers: {
                        Authorization: 'Bearer ' + this.token
                    }
                }
            ).then((response) => {
                this.listFollowidol = response.data.data
            })
        },
        offlineIdol () {
            this.$swal({
                position: 'top-end',
                icon: 'error',
                title: 'Idol hiện tại đã kết thúc phiên live',
                showConfirmButton: false,
                timer: 3000
            })
        },
        cancelFolow (event) {
            event.preventDefault()
            $('.loading_show').show().fadeIn(10)
            var nameidol = event.target.getAttribute('data_name')
            this.axios.post(this.api_followidol, {
                    name_idol: nameidol,
                    type: 0
                },
                {
                    headers: {
                        Authorization: 'Bearer ' + this.token
                    }
                }
            ).then(res => {
                $('#loading_show').css('display', 'none')
                this.getListFollow()
                this.$swal({
                    position: 'top-end',
                    icon: 'success',
                    title: res.data.message,
                    showConfirmButton: false,
                    timer: 4000
                })
            }).catch(e => {
                $('#loading_show').css('display', 'none')
                if(e.response.status === 404){
                    this.$swal({
                        position: 'top-end',
                        icon: 'error',
                        title: e.response.data.message,
                        showConfirmButton: false,
                        timer: 3000
                    })
                }
            })
        },
        getDataliveAsian () {
            this.axios.get(this.api_listAsian).then((response) => {
                this.listLiveAsian = this.shuffle(response.data.data.filter(d => d[4] == 200 && d[6] == 1));
                this.listLiveAsian1 = response.data.data.filter(d => d[4] == 200 && d[6] == 1);
            })
        },
        getDataliveidolBg () {
            this.axios.get(this.api_listBG).then((response) => {
                this.listIdolbg = this.shuffle(response.data.data)
            })
        },
        showModalrg () {
            this.showModal = false
            this.showModal_dk = true
        },
        showModallg () {
            this.showModal = true
            this.showModal_dk = false
        },
        getDataliveGame () {
            this.axios.get(this.api_ListGameBG).then((response) => {
                this.listLiveGame = this.shuffle(response.data.data.data)
            })
        },
    }
}
</script>