<template>
    <div class="wrapper_list_idol">
        <div class="container ner_responesize">
            <div class="wp_list_idol">
                <div class="wraper_game_nohu">
                    <div class="row row_5_mb">
                        <div class="col-md-2 col-sm-2 col-xs-3 wp-item-live" v-for="(item, index) in loadMoreList" :key="index">
                            <div class="item_live">
                                <router-link v-bind:to="{ name: 'DetailVideoPropose_nm', params: { id: item.live_id, free: 0} }" @click="getLocalstoragePage" v-if="item.is_live==1">
                                    <div class="img_live">
                                        <img :src="item.avatar" alt="">
                                        <div class="hover_icon">
                                            <font-awesome-icon icon="fa-solid fa-caret-right" />
                                        </div>
                                    </div>
                                    <div class="text_item_live">
                                        <div class="img_live_small">
                                            <img :src="item.avatar" alt="">
                                        </div>
                                        <div class="text_live_small">
                                            <h3>{{ item.username.substring(0, 10) }}</h3>
                                        </div>
                                    </div>
                                </router-link>
                                <a @click="offlineIdol" v-else style="cursor: pointer;">
                                    <div class="img_live">
                                        <img :src="item.avatar" alt="">
                                        <div class="hover_icon">
                                            <font-awesome-icon icon="fa-solid fa-caret-right" />
                                        </div>
                                    </div>
                                    <div class="text_item_live">
                                        <div class="img_live_small">
                                            <img :src="item.avatar" alt="">
                                        </div>
                                        <div class="text_live_small">
                                            <h3>{{ item.username.substring(0, 10) }}</h3>
                                        </div>
                                    </div>
                                </a>
                                <div class="unfollow">
                                    <img src="../assets/images/unfl.png" alt="" @click="cancelFolow($event)" :data_name="item.username">
                                </div>
                            </div>
                        </div>
                    </div>
                    <p class="detail_mobile_live" v-if="length_hot < listFollowidol.length"><a @click="loadMore">Xem thêm</a></p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import $ from 'jquery'
export default {
    name: 'ListIdolfollow_nm',
    data () {
        return {
            listFollowidol: [],
            length_hot: 15,
            token: window.localStorage.getItem('token'),
        }
    },
    created () {
        this.getListFollow()
    },
    computed: {
        loadMoreList() {
            return this.listFollowidol.slice(0, this.length_hot);
        },
    },
    methods: {
        getListFollow () {
            this.axios.get(this.api_listfollow,
                {
                    headers: {
                        Authorization: 'Bearer ' + this.token
                    }
                }
            ).then((response) => {
                this.listFollowidol = response.data.data
            })
        },
        loadMore() {
            if (this.length_hot > this.listFollowidol.length) return;
            this.length_hot = this.length_hot + 5;
        },
        getLocalstoragePage () {
            window.localStorage.setItem('hiddenhd', 1)
            $('#app').addClass('hideheader')
        },
        offlineIdol () {
            this.$swal({
                position: 'top-end',
                icon: 'error',
                title: 'Idol hiện tại đã kết thúc phiên live',
                showConfirmButton: false,
                timer: 3000
            })
        },
        cancelFolow (event) {
            event.preventDefault()
            $('.loading_show').show().fadeIn(10)
            var nameidol = event.target.getAttribute('data_name')
            this.axios.post(this.api_followidol, {
                    name_idol: nameidol,
                    type: 0
                },
                {
                    headers: {
                        Authorization: 'Bearer ' + this.token
                    }
                }
            ).then(res => {
                $('#loading_show').css('display', 'none')
                this.getListFollow()
                this.$swal({
                    position: 'top-end',
                    icon: 'success',
                    title: res.data.message,
                    showConfirmButton: false,
                    timer: 4000
                })
            }).catch(e => {
                $('#loading_show').css('display', 'none')
                if(e.response.status === 404){
                    this.$swal({
                        position: 'top-end',
                        icon: 'error',
                        title: e.response.data.message,
                        showConfirmButton: false,
                        timer: 3000
                    })
                }
            })
        },
    }
}
</script>