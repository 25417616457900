<template>
    <div class="root_info_user">
        <div class="info_wrapper">
            <div class="wrapper_info">
                <img src="../assets/images/bn.webp" alt="">
            </div>
            <div class="text_info">
                <div class="img_info">
                    <img :src="link_web + hinh_anh" alt="" v-if="hinh_anh !== null">
                    <img src="../assets/images/user.png" alt="" v-else>
                </div>
                <div class="info_user_right">
                    <p class="p-id">Live ID: {{ id }}</p>
                    <p class="p-name" v-if="typeUser == 1">
                        <span class="p_name_1">{{ fullname }}</span>
                        <span v-if="live > 0" class="p_name_2"><font-awesome-icon icon="fa-solid fa-headset" style="color: green;" /> Đang stream</span>
                        <span v-else class="p_name_2"><font-awesome-icon icon="fa-solid fa-power-off" /> offline</span>
                    </p>
                    <p class="p-name" v-else>
                        <span class="p_name_1">{{ fullname }}</span>
                    </p>
                    <div class="follow_fan" v-if="typeUser == 1">
                        <div class="follow">
                            <p class="p-tdf">Theo dõi</p>
                            <p class="p-sl">{{ follow }}</p>
                        </div>
                        <div class="fan">
                            <p class="p-tdf">Điểm</p>
                            <p class="p-sl">{{ new Intl.NumberFormat('en-US', { minimumFractionDigits: 0 }).format(point) }}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="wrapper_gift">
                <div class="gift_list">
                    <h2>Lịch phát quà</h2>
                    <table v-if="listGift.length > 0">
                        <thead>
                            <tr v-if="typeUser==1">
                                <th width="15%">Tiêu đề</th>
                                <th width="15%">Hình ảnh</th>
                                <th width="30%">Nội dung</th>
                                <th width="20%">Thời gian</th>
                                <th width="20%">Thao tác</th>
                            </tr>
                            <tr v-else>
                                <th width="15%">Idol</th>
                                <th width="20%">Tiêu đề</th>
                                <th width="15%">Hình ảnh</th>
                                <th width="30%">Nội dung</th>
                                <th width="20%">Thời gian</th>
                            </tr>
                        </thead>
                        <tbody v-if="typeUser==1">
                            <tr v-for="(item, index) in datas" :key="index">
                                <td width="15%">{{ item.title }}</td>
                                <td width="15%">
                                    <Fancybox_nm>
                                        <a data-fancybox="gallery" :href="link_web + item.hinh_anh">
                                            <img :src="link_web + item.hinh_anh" alt="">
                                        </a>
                                    </Fancybox_nm>
                                </td>
                                <td class="content_gift" width="30%">
                                    <div class="title_chat">
                                        <div v-if="!readMore[index]" v-html="item.content.substring(0, 200)"></div>
                                        <div v-if="readMore[index]" v-html="item.content"></div>
                                        <p style="margin-top: 7px;" @click="showMore(index)" v-if="!readMore[index] && item.content.length > 200" class="showmore">[Xem thêm]</p>
                                        <p style="margin-top: 7px;" @click="showLess(index)" v-if="readMore[index]" class="lessmore">[Thu gọn]</p>
                                    </div>
                                </td>
                                <td width="20%">{{ item.time_start + ' - ' + item.time_end + ' ' + item.day_gift }}</td>
                                <!-- <td class="btn_gift" width="20%" v-if="gift = 0">
                                    <button type="button" @click="showView()">View</button>
                                    <button type="button" class="btn_disable">Phát quà</button>
                                </td> -->
                                <td class="btn_gift" width="20%">
                                    <button type="button" @click="showView()">View</button>
                                    <button type="button" @click="postGiveGift()" v-if="item.temp == 1">Phát quà</button>
                                    <button type="button" v-else class="btn_disable">Phát quà</button>
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr v-for="(item, index) in datas" :key="index">
                                <td width="15%">{{ item.fullname }}</td>
                                <td width="20%">{{ item.title }}</td>
                                <td width="15%">
                                    <Fancybox_nm>
                                        <a data-fancybox="gallery" :href="link_web + item.hinh_anh">
                                            <img :src="link_web + item.hinh_anh" alt="">
                                        </a>
                                    </Fancybox_nm>
                                </td>
                                <td class="content_gift" v-html="item.content" width="30%"></td>
                                <td width="20%">{{ item.time_start + ' ' + item.tu + ' - ' + item.time_end + ' ' + item.den }}</td>
                            </tr>
                        </tbody>
                    </table>
                    <p v-else class="no_data">Chưa có lịch phát quà</p>
                    <paginate @update:modelValue="clickCallback" :totalCount="totalCount" :limit="perPage" v-model="page"></paginate>
                </div>
            </div>
            <!-- modal view -->
            <div id="modal-example" class="modal-login">
                <div class="overlay" v-if="showModal" @click="showModal = false"></div>
                <div class="modal" v-if="showModal">
                    <div class="wp-modal-login">
                        <div class="header-modal">
                            <ul class="list_menu_modal">
                                <li class="img_modal"><img src="../assets/images/logo.png"></li>
                                <li class="active">Views</li>
                            </ul>
                            <button class="close-modal_login" @click="showModal = false"><img src="../assets/images/close_md.png" alt=""></button>
                        </div>
                        <div class="content-modal_tab">
                            <div>
                                <h2 class="number_views">{{ view }}</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- end modal view -->
        </div>
    </div>
</template>

<script>
import Fancybox_nm from "@/components/Fancybox";
import $ from 'jquery'
export default {
    name: 'InfoIdol_nm',
    data () {
        return {
            fullname: '',
            live: '',
            hinh_anh: '',
            type: '',
            point_rank: '',
            point: '',
            follow: 0,
            id: this.$route.params.id,
            token: window.localStorage.getItem('token'),
            typeUser: window.localStorage.getItem('typeus'),
            listGift: [],
            view: '',
            showModal: false,
            others: [],
            gift: '',
            dateNow: '',

            page: 4,
            perPage: 15,
            totalCount: 0,
            datas: [],
            readMore: [false],
        }
    },
    components: {
        Fancybox_nm
    },
    created () {
        this.getInfo()
        this.getListGift()
        this.getDateNow()
    },
    methods: {
        getInfo () {
            this.axios.get(this.api_infouser, {
                headers: {
                    Authorization: 'Bearer ' + window.localStorage.getItem('token')
                }
            }).then((response) => {
                this.id = response.data.data.user.id
                this.fullname = response.data.data.user.fullname
                this.live = response.data.data.user.live
                this.hinh_anh = response.data.data.user.hinh_anh
                this.intro = response.data.data.user.intro
                this.type = response.data.data.user.type
                this.point_rank = response.data.data.user.point_rank
                this.point = response.data.data.user.point
                this.follow = response.data.data.user.follow_total
            }).catch(e => {
                if (e.response.status === 401) {
                    setTimeout(function() {
                        this.axios.post(this.api_logout, {
                        }).then(res => {
                            if (res.data.success === true) {
                                localStorage.clear();
                            }
                        })
                    }.bind(this), 5000);
                    setTimeout(function() {
                        window.location.href = '/'
                    }, 13000);
                }
            })
        },
        getListGift () {
            if(this.typeUser == 1){
                this.axios.get(this.api_listGiftIdol,{
                    headers: {
                        Authorization: 'Bearer ' + window.localStorage.getItem('token')
                    }
                }).then((response) => {
                    this.listGift = response.data.data.list_data
                    this.totalCount = this.listGift.length
                    this.paginatedData()
                })
            }else{
                this.axios.get(this.api_listGift).then((response) => {
                    this.listGift = response.data.data.list_data
                    this.totalCount = this.listGift.length
                    this.paginatedData()
                })
            }
        },
        paginatedData () {
            this.datas = this.listGift.slice((this.page - 1) * this.perPage, this.page * this.perPage)
        },
        clickCallback (pageNum) {
            this.page = pageNum;
            this.paginatedData();
        },
        showView () {
            this.axios.get(this.api_viewLive,
                {
                    headers: {
                        Authorization: 'Bearer ' + window.localStorage.getItem('token')
                    }
                }
            ).then((response) => {
                this.showModal = true
                this.view = response.data.data
            })
        },
        postGiveGift () {
            $('.loading_show').show().fadeIn(10)
            this.axios.get(this.api_giveGift,
                {
                    headers: {
                        Authorization: 'Bearer ' + window.localStorage.getItem('token')
                    }
                }
            ).then((response) => {
                $('#loading_show').css('display', 'none')
                this.$swal({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 3000
                })
                this.getListGift()
            }).catch(e => {
                $('#loading_show').css('display', 'none')
                this.$swal({
                    position: 'top-end',
                    icon: 'error',
                    title: e.response.data.message,
                    showConfirmButton: false,
                    timer: 10000
                })
            })
        },
        formatDate (date) {
            var datePart = date.match(/\d+/g),
            year = datePart[2].substring(0),
            month = datePart[1], 
            day = datePart[0];
            return year + '-' + month + '-' + day;
        },
        getDateNow () {
            var today = new Date()
            var time = today.getHours() + ":" + today.getMinutes();
            this.dateNow = Date.parse(today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate() + ' ' + time) / 1000
        },
        strTotimeDate (date) {
            var d = Date.parse(date) / 1000;
            return d;
        },
        showMore(index) {
            this.readMore[index] = true;
        },
        showLess(index) {
            this.readMore[index] = false;
        },
    }
}
</script>
<style>
</style>
