<template>
    <div id="content" class="content-margin">
        <div class="root_video_live">
            <div class="title_item_idol">
                <h3 class="titMobile">
                    <img src="../assets/images/ddx.png" alt="">
                    <span>Live stream</span>
                </h3>
                <div class="menuSelect_mb">
                    <h2 class="selectedLive">Live game</h2>
                    <div class="menuSelectLive">
                        <ul>
                            <li><router-link to='/live'>Live game</router-link></li>
                            <!-- <li><router-link to="/idol-live">Live Tâm Sự</router-link></li> -->
                            <li><router-link to='/video-propose'>Live Show 18+</router-link></li>
                            <!-- <li><router-link to='/asian'>Live Show Châu Á</router-link></li> -->
                        </ul>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-2 col-sm-2 col-xs-3 wp-item-live" v-for="(item, index) in datas" :key="index">
                    <div class="item_live">
                        <router-link v-bind:to="{ name: 'LiveDetail_nm', params: { id: item.id, idlive: item.id_live } }" @click="getLocalstoragePage">
                            <div class="img_live">
                                <img :src="link_web + item.background" alt="" v-if="item.background != null">
                                <img src="../assets/images/lv.png" alt="" v-else>
                                <div class="hover_icon">
                                    <font-awesome-icon icon="fa-solid fa-caret-right" />
                                </div>
                            </div>
                            <div class="text_item_live">
                                <div class="img_live_small">
                                    <img :src="link_web + item.hinh_anh" alt="" v-if="item.hinh_anh != null">
                                    <img src="../assets/images/gx.png" alt="" v-else>
                                </div>
                                <div class="text_live_small">
                                    <!-- <h3>{{ item.tieu_de }}</h3>
                                    <p>{{ item.name }}</p> -->
                                    <h3>{{ item.fullname }}</h3>
                                    <div class="type_live">
                                        <!-- <span class="sp-01">Nhảy</span> -->
                                        <p>
                                            <img src="../assets/images/eye.svg" alt="">
                                            <span class="sp-02">{{ item.total_view }}</span>
                                        </p>
                                    </div>
                                </div>
                                <img src="../assets/images/live.png" alt="" class="img_live_green">
                            </div>
                        </router-link>
                    </div>
                </div>
            </div>
            <paginate @update:modelValue="clickCallback" :totalCount="totalCount" :limit="perPage" v-model="page"></paginate>
        </div>
    </div>
</template>

<script>
import $ from 'jquery'
import SocketIO from 'socket.io-client'
$( document ).ready(function() {
    $(document).on("click",".selectedLive",function() {
        $('.menuSelectLive').slideToggle();
    });
});
export default {
    name: 'Live_nm',
    data () {
        return {
            listLiveidol: [],
            page: 1,
            perPage: 15,
            totalCount: 0,
            datas: []
        }
    },
    components: {
    },
    mounted() {
        
    },
    created () {
        this.getDataliveidol()
        this.socketLive()
    },
    methods: {
        getDataliveidol () {
            this.axios.get(this.api_listlive).then((response) => {
                this.listLiveidol = response.data.data.list_user
                this.totalCount = this.listLiveidol.length
                this.paginatedData()
                // const dataImage = [];
                // this.listLiveidol.forEach(item => {
                //     let check_mbut = 0
                //     this.axios.get(item.link_m3u8).then(res => {
                //         if(res.status===200){
                //             check_mbut = 1
                //             dataImage.push({...item, check_mbut});
                //         }
                //     }).catch(e => {
                //         if (e.response.status === 404) {
                //             check_mbut = 0
                //         }else{
                //             check_mbut = 1
                //             dataImage.push({...item, check_mbut});
                //         }
                //     })
                // });
                // setTimeout(() => {
                //     this.listLiveidol = dataImage;
                //     this.totalCount = this.listLiveidol.length
                //     this.paginatedData()
                // }, 500);
            })
        },
        paginatedData () {
            this.datas = this.listLiveidol.slice((this.page - 1) * this.perPage, this.page * this.perPage)
        },
        clickCallback (pageNum) {
            this.page = pageNum;
            this.paginatedData();
        },
        socketLive() {
            var thisAgo = this
            var socket = SocketIO('https://f8game.click/', {
                transports: ['websocket', 'polling', 'flashsocket'],
            });
            socket.on('livestream:realtime',function(result){
                if(result.data.user.live==1){
                    if(result.data.user.type_live==1){
                        thisAgo.listLiveidol = thisAgo.listLiveidol.concat(result.data.user)
                        thisAgo.totalCount = thisAgo.listLiveidol.length
                        thisAgo.paginatedData()
                    }
                }else{
                    thisAgo.listLiveidol.splice(thisAgo.listLiveidol.findIndex(({id}) => id == result.data.user.id), 1);
                    thisAgo.totalCount = thisAgo.listLiveidol.length
                    thisAgo.paginatedData()
                }
            });
        },
        getLocalstoragePage () {
            window.localStorage.setItem('hiddenhd', 1)
            $('#app').addClass('hideheader')
        }
    }
}
</script>
<style>
</style>
