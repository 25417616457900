<template>
    <div class="root_info_user">
        <div class="container ner_support">
            <div class="list_chat_support list_chat_support_faq">
                <router-link to="/faq" class="comeback_support"><img src="../assets/images/cb.png" alt=""></router-link>
                <p class="btn_addnew_sp"><span @click="showModalsp = true">+ Thêm mới</span></p>
                <div class="item_support" v-for="(item, index) in datas" :key="index">
                    <div class="img_support">
                        <router-link v-bind:to="{ name: 'SupportDetail_nm', params: { id: item.id } }">
                            <img :src="link_web + hinh_anh" alt="">
                        </router-link>
                    </div>
                    <div class="name_content_support" :class="{opacity: item.status == 2}">
                        <router-link v-bind:to="{ name: 'SupportDetail_nm', params: { id: item.id } }">
                            <p class="font1">{{ fullname }} <img src="../assets/images/view.png" alt="" v-if="item.status == 1" class="newgif"> <span>{{ item.ngay_tao }}</span></p>
                            <div class="content_sp">
                                {{ item.title }}
                            </div>
                        </router-link>
                    </div>
                </div>
            </div>
            <paginate @update:modelValue="clickCallback" :totalCount="totalCount" :limit="perPage" v-model="page"></paginate>
            <!-- modal chat -->
            <div id="modal-example" class="modal-login">
                <div class="overlay" v-if="showModalsp" @click="showModalsp = false"></div>
                <div class="modal" v-if="showModalsp">
                    <div class="wp-modal-login wp-modal-support">
                        <div class="header-modal">
                            <ul class="list_menu_modal">
                                <li class="img_modal"><img src="../assets/images/logo.png"></li>
                                <li class="active">Trợ giúp</li>
                            </ul>
                            <button class="close-modal_login" @click="showModalsp = false"><img src="../assets/images/close_md.png" alt=""></button>
                        </div>
                        <div class="content-modal_tab">
                            <div>
                                <form action="" method="post" id="frm_chat" @submit="sendSupports">
                                    <div>
                                        <Select2 v-model="topic" :options="listtopic" placeholder="Chọn tiêu đề">
                                        </Select2>
                                    </div>
                                    <!-- <select v-model="topic">
                                        <option value="" selected>Chọn tiêu đề</option>
                                        <option :value="item.id" v-for="(item, index) in listtopic" :key="index">{{ item.title }}</option>
                                    </select> -->
                                    <input type="text" v-model="titlesp" placeholder="Tiêu đề">
                                    <input type="text" v-model="content" placeholder="Nội dung">
                                    <button type="submit">Gửi</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- end modal chat -->
        </div>
    </div>
</template>

<script>
import $ from 'jquery'
import Select2 from 'vue3-select2-component';
export default {
    name: 'Support_nm',
    data () {
        return {
            listsp: [],
            fullname: '',
            hinh_anh: '',
            page: 1,
            perPage: 20,
            totalCount: 0,
            datas: [],
            showModalsp: false,
            listtopic: [],
            topic: '',
            titlesp: '',
            content: '',
            token: window.localStorage.getItem('token'),
        }
    },
    components: {
        Select2
    },
    created () {
        this.getListsp()
        setInterval(this.getListsp, 30000);
        this.getListInfo()
        this.getListtopic()
    },
    methods: {
        getListInfo () {
            this.axios.get(this.api_infouser, {
                headers: {
                    Authorization: 'Bearer ' + window.localStorage.getItem('token')
                }
            }).then((response) => {
                this.fullname = response.data.data.user.fullname
                this.hinh_anh = response.data.data.user.hinh_anh
            }).catch(e => {
                if (e.response.status === 401) {
                    setTimeout(function() {
                        this.axios.post(this.api_logout, {
                        }).then(res => {
                            if (res.data.success === true) {
                                localStorage.clear();
                            }
                        })
                    }.bind(this), 5000);
                    setTimeout(function() {
                        window.location.href = '/'
                    }, 13000);
                }
            })
        },
        getListsp () {
            this.axios.get(this.api_listchatsp, {
                headers: {
                    Authorization: 'Bearer ' + window.localStorage.getItem('token')
                }
            }).then((response) => {
                this.listsp = response.data.data
                this.totalCount = this.listsp.length
                this.paginatedData()
            })
        },
        paginatedData () {
            this.datas = this.listsp.slice((this.page - 1) * this.perPage, this.page * this.perPage)
        },
        clickCallback (pageNum) {
            this.page = pageNum;
            this.paginatedData();
        },
        getListtopic () {
            this.axios.get(this.api_listtopic).then((response) => {
                let listtopic = response.data.data
                let datatp = []
                listtopic.forEach(item => {
                    datatp.push({...item, text: item.title});
                });
                this.listtopic = datatp
            })
        },
        sendSupports (e) {
            e.preventDefault();
            $('.loading_show').show().fadeIn(10)
            this.axios.post(this.api_sendSupport, 
                {
                    topic_id: this.topic,
                    title: this.titlesp,
                    content: this.content,
                    support_id: 0
                },
                {
                    headers: {
                        Authorization: 'Bearer ' + this.token
                    }
                }
            ).then((response) => {
                $('#loading_show').css('display', 'none')
                this.$swal({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 3000
                })
                this.showModal = false,
                window.location.reload()
                this.topic_id = this.title = this.content = ''
            }).catch(e => {
                $('#loading_show').css('display', 'none')
                this.$swal({
                    position: 'top-end',
                    icon: 'error',
                    title: e.response.data.message,
                    showConfirmButton: false,
                    timer: 3000
                })
            })
            return false;
        },
    }
}
</script>
<style>
</style>
