<template>
    <div id="content" class="content-margin root_lh_mobile">
        <div class="root_contact">
            <div class="contact_mobile">
                <div class="logo_contact">
                    <img src="../assets/images/logo.png" alt="">
                </div>
                <div class="img_contact">
                    <img src="../assets/images/lh4.png" alt="">
                </div>
                <div class="item_contact">
                    <ul>
                        <li><a :href="facebook"><img src="../assets/images/lh.svg" alt=""></a></li>
                        <li><a :href="telegram"><img src="../assets/images/lh1.svg" alt=""></a></li>
                        <li><a :href="zalo"><img src="../assets/images/lh2.svg" alt=""></a></li>
                        <li><router-link to='/support'><img src="../assets/images/lh3.svg" alt=""></router-link></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Contact_nm',
    data () {
        return {
            facebook: '',
            telegram: '',
            zalo: '',
        }
    },
    components: {
    },
    mounted() {
        
    },
    created () {
        this.getInfoLink()
    },
    methods: {
        getInfoLink () {
            this.axios.get(this.api_previewLink + '?domain=' + this.url_param).then((response) => {
                this.facebook = response.data.data.list_data.facebook
                this.telegram = response.data.data.list_data.telegram
                this.zalo = response.data.data.list_data.zalo
            })
        },
    }
}
</script>
<style>
</style>
