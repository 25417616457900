<template>
    <div class="root_info_user">
        <div class="info_wrapper">
            <div class="wrapper_info">
                <img src="../assets/images/bn.webp" alt="">
            </div>
            <div class="text_info">
                <div class="img_info">
                    <img :src="link_web + hinh_anh" alt="" v-if="hinh_anh !== null">
                    <img src="../assets/images/user.png" alt="" v-else>
                    <br />
                    <button type="button" v-if="token==null" @click="showModal = true">+ Theo dõi</button>
                    <div class="followidol_info" v-else-if="idus != id">
                        <div class="" v-if="listFollow.includes(idus) == false">
                            <button type="button" v-if="checkFollow == 0" @click="followIdol">+ Theo dõi</button>
                            <button type="button" v-else @click="unfollowIdol">Bỏ theo dõi</button>
                        </div>
                        <div class="" v-else>
                            <button type="button" v-if="checkFollows == 0" @click="unfollowIdol">Bỏ theo dõi</button>
                            <button type="button" v-else @click="followIdol">+ Theo dõi</button>
                        </div>
                    </div>
                </div>
                <!-- {{ Object.values(listFollow.split(',').map((e) => parseInt(e))).includes(idus) }} -->
                <div class="info_user_right">
                    <p class="p-id">Live ID: {{ id }}</p>
                    <p class="p-name">
                        <span class="p_name_1">{{ fullname }}</span>
                        <span v-if="live > 0" class="p_name_2"><font-awesome-icon icon="fa-solid fa-headset" style="color: green;" /> Đang stream</span>
                        <span v-else class="p_name_2"><font-awesome-icon icon="fa-solid fa-power-off" /> offline</span>
                    </p>
                    <div class="follow_fan">
                        <div class="follow">
                            <p class="p-tdf">Theo dõi</p>
                            <p class="p-sl">{{ follow }}</p>
                        </div>
                        <div class="fan">
                            <p class="p-tdf">Điểm</p>
                            <p class="p-sl">{{ new Intl.NumberFormat('en-US', { minimumFractionDigits: 0 }).format(point) }}</p>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="wrapper_intro">
                <div class="previewidol_live">
                    <div v-html="intro" v-if="intro != null"></div>
                    <p v-if="lk_link_game != null">Link tải game: <a :href="lk_link_game" target="_blank">{{ lk_link_game }}</a></p>
                    <p v-if="lk_zalo != null">Link zalo: <a :href="lk_zalo" target="_blank">{{ lk_zalo }}</a></p>
                    <p v-if="lk_facebook != null">Link facebook: <a :href="lk_facebook" target="_blank">{{ lk_facebook }}</a></p>
                    <p v-if="lk_telegram != null">Link telegram: <a :href="lk_telegram" target="_blank">{{ lk_telegram }}</a></p>
                </div>
            </div> -->
            <div class="wrapper_intro" style="margin-bottom: 30px;">
                <div class="previewidol_live">
                    <div class="cat_chanel">
                        <ul>
                            <li><img src="../assets/images/logo.png" alt=""></li>
                            <li :class="{active: tabSelectedss === 'about'}" v-on:click="changeTabss('about')">Giới thiệu</li>
                            <li :class="{active: tabSelectedss === 'chanel'}" v-on:click="changeTabss('chanel')">Kênh idol</li>
                            <li :class="{active: tabSelectedss === 'video'}" v-on:click="changeTabss('video')">Video</li>
                        </ul>
                    </div>
                    <div class="content_tab_chanel">
                        <div v-if="tabSelectedss === 'about'">
                            <div class="intro_about">
                                <div v-if="intro != null" v-html="intro"></div>
                                <p v-if="lk_link_game != null">Link tải game: <a :href="lk_link_game" target="_blank">{{ lk_link_game }}</a></p>
                                <p v-if="lk_zalo != null">Link zalo: <a :href="lk_zalo" target="_blank">{{ lk_zalo }}</a></p>
                                <p v-if="lk_facebook != null">Link facebook: <a :href="lk_facebook" target="_blank">{{ lk_facebook }}</a></p>
                                <p v-if="lk_telegram != null">Link telegram: <a :href="lk_telegram" target="_blank">{{ lk_telegram }}</a></p>
                            </div>
                        </div>
                        <div v-if="tabSelectedss === 'chanel'">
                            <div class="wrapper_content_cn">
                                <div class="list_chanel">
                                    <h4>Danh sách kênh </h4>
                                    <table class="table_chanel">
                                        <thead>
                                            <tr>
                                                <th width="7%">STT</th>
                                                <th width="50%">Tên kênh</th>
                                                <th width="23%">Ngày tạo</th>
                                                <th width="20%">chức năng</th>
                                            </tr>
                                        </thead>
                                        <tbody v-if="listChanel.length > 0">
                                            <tr v-for="(item, index) in listChanel" :key="index">
                                                <td>{{ index + 1 }}</td>
                                                <td v-if="token != null">
                                                    <router-link v-bind:to="{ name: 'MessageGroup_nm', params: { ididol: this.idIdol, idcheck: item.is_chat, id: item.id } }">{{ item.name }}</router-link>
                                                </td>
                                                <td v-else style="text-align: justify;">
                                                    {{ item.name }}
                                                </td>
                                                <td>{{ formatDate(item.ngay_cap_nhat) }}</td>
                                                <td v-if="token == null">
                                                    <button type="button" class="btn4" @click="showModal = true">Tham gia</button>
                                                </td>
                                                <td v-else>
                                                    <button type="button" v-if="item.checkJoinGroup == 1" class="btn4 btn41"><router-link v-bind:to="{ name: 'MessageGroup_nm', params: { ididol: this.idIdol, idcheck: item.is_chat, id: item.id } }">Đã tham gia</router-link></button>
                                                    <button type="button" v-else-if="item.checkJoinGroup == 0" :data_chat="item.is_chat" :data_id="item.id" class="btn4" @click="addGroupMessage($event)">Tham gia</button>
                                                    <button type="button" v-else class="btn4 btn42">Bị chặn</button>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tbody v-else>
                                            <tr>
                                                <td colspan="4">Chưa có kênh</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div v-if="tabSelectedss === 'video'" class="wp_video_root">
                            <div class="row mar_top_row" v-if="datas.length > 0">
                                <div class="col-md-2 col-sm-2 col-xs-3 wp-item-live item_video_idoldetail" v-for="(item, index) in datas" :key="index">
                                    <div class="item_live">
                                        <router-link v-bind:to="{ name: 'VideoDetail_nm', params: { ididol: id, id: item.id } }">
                                            <div class="img_live">
                                                <!-- <img :src="'https://f8game.pro/assets/uploads/thumbnail/' + item.thumbnail_path" alt=""> -->
                                                <video ref="video" :src="'https://f8game.pro/assets/uploads/videos/' + item.video_path" :data_id="item.id" height="120" :class="'videoplay_hover videoplay_hover'+item.id" muted @mouseover="playHover(index)" @mouseleave="pauseHover(index)"></video>
                                                <!-- <div class="hover_icon">
                                                    <font-awesome-icon icon="fa-solid fa-caret-right" />
                                                </div> -->
                                            </div>
                                            <div class="text_item_live text_item_live1">
                                                <div class="img_live_small">
                                                    <img :src="link_web + hinh_anh" alt="" v-if="hinh_anh != null">
                                                    <img src="../assets/images/gx.png" alt="" v-else>
                                                </div>
                                                <div class="text_live_small text_live_smalls">
                                                    <div class="lf_info_detail infotop">
                                                        <h3 class="none_mb_h3">{{ fullname }}</h3>
                                                        <!-- <p v-if="item.title.length < 12">{{ item.title }}</p>
                                                        <p v-else>{{ item.title.slice(0,12) }}...</p> -->
                                                    </div>
                                                </div>
                                            </div>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                            <paginate @update:modelValue="clickCallback" :totalCount="totalCount" :limit="perPage" v-model="page"></paginate>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- modal login -->
        <div id="modal-example" class="modal-login">
            <div class="overlay" v-if="showModal" @click="showModal = false"></div>
            <div class="modal" v-if="showModal">
                <div class="wp-modal-login">
                    <div class="header-modal">
                        <button class="close-modal_login" @click="showModal = false"><img src="../assets/images/close_md.png" alt=""></button>
                    </div>
                    <div class="content-modal_tab">
                        <div>
                            <div class="logo_modal_login">
                                <div class="logo_mdal">
                                    <div class="img_md">
                                        <img src="../assets/images/logomd.png" alt="">
                                    </div>
                                    <div class="img_md1">
                                        <img src="../assets/images/logomd1.png" alt="">
                                    </div>
                                </div>
                                <div class="img_pathner_md">
                                    <img src="../assets/images/logomd2.png" alt="">
                                </div>
                            </div>
                            <Login_f8 @showMd="showModalrg" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- end modal login -->
        <!-- modal register -->
        <div id="modal-example" class="modal-login">
            <div class="overlay" v-if="showModal_dk" @click="showModal_dk = false"></div>
            <div class="modal" v-if="showModal_dk">
                <div class="wp-modal-register">
                    <div class="header-modal">
                        <button class="close-modal_login" @click="showModal_dk = false"><img src="../assets/images/close_md.png" alt=""></button>
                    </div>
                    <div class="content-modal_tab">
                        <div>
                            <div class="logo_modal_login">
                                <div class="logo_mdal">
                                    <div class="img_md">
                                        <img src="../assets/images/logomd.png" alt="">
                                    </div>
                                    <div class="img_md1">
                                        <img src="../assets/images/logomd1.png" alt="">
                                    </div>
                                </div>
                                <div class="img_pathner_md">
                                    <img src="../assets/images/logomd2.png" alt="">
                                </div>
                            </div>
                            <div class="form_dangky">
                                <Register_f8 @showMd="showModallg"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- end modal register -->
        <div class="root_video_live">
            <h2 class="title_category"><img src="../assets/images/images/4.png" alt=""></h2>
            <div class="wraper_game_nohu">
                <div class="row row_5_mb">
                    <div class="col-md-2 col-sm-2 col-xs-3 wp-item-live" v-for="(item, indexs) in loadMore_hotidol" :key="indexs">
                        <div class="item_live">
                            <router-link v-bind:to="{ name: 'InfoIdol_nm', params: { id: item.id } }">
                                <!-- <div class="img_live img_lives">
                                    <img :src="link_web + item.background" alt="" v-if="item.background != null">
                                    <img src="../assets/images/lv.png" alt="" v-else>
                                </div> -->
                                <div class="img_live img_lives" v-if="item.sex == 0">
                                    <img :src="link_web + item.background" alt="" v-if="item.background != null">
                                    <img src="../assets/images/lv.png" alt="" v-else>
                                </div>
                                <div class="img_live img_lives" v-else>
                                    <img :src="link_web + item.background" alt="" v-if="item.background != null">
                                    <img src="../assets/images/oto.png" alt="" v-else>
                                </div>
                                <div class="text_item_live">
                                    <div class="img_live_small">
                                        <img :src="link_web + item.hinh_anh" alt=""  v-if="item.hinh_anh != null">
                                        <img src="../assets/images/user.png" alt="" v-else>
                                    </div>
                                    <div class="text_live_small">
                                        <h3 style="text-align: left;">{{ item.fullname }}</h3>
                                        <div class="type_live">
                                            <p>
                                                <img src="../assets/images/eye.svg" alt="">
                                                <span class="sp-02">{{ new Intl.NumberFormat('en-US', { minimumFractionDigits: 0 }).format(item.point) }}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </router-link>
                        </div>
                    </div>
                </div>
                <p class="detail_mobile_live" v-if="length_hot < hot_idol.length"><a @click="loadMore">Xem thêm</a></p>
            </div>
        </div>
    </div>
</template>

<script>
import Login_f8 from '@/components/Login'
import Register_f8 from '@/components/Register'
import $ from 'jquery'
export default {
    name: 'InfoIdol_nm',
    data () {
        return {
            fullname: '',
            live: '',
            hinh_anh: '',
            listLive: [],
            video: '',
            page: 1,
            perPage: 12,
            totalCount: 0,
            datas: [],
            type: '',
            point_rank: '',
            lenght_vd: '',
            id: this.$route.params.id,
            token: window.localStorage.getItem('token'),
            idus: window.localStorage.getItem('numberus'),
            userIdol: window.localStorage.getItem('us'),
            point: '',
            checkFollow: 0,
            checkFollows: 0,
            follow: 0,
            showModal: false,
            showModal_dk: false,
            tabSelecteds: 'login',
            listFollow: '',
            hot_idol: [],
            length_hot: 10,
            intro: '',
            lk_link_game: '',
            lk_zalo: '',
            lk_facebook: '',
            lk_telegram: '',
            tabSelectedss: 'about',
            listChanel: [],
            idIdol: parseInt(this.$route.params.id),
            checkMember: 1,
            listVideo: [],
            videosState: {},
            linkf8bet: '',
        }
    },
    components: {
        Login_f8,
        Register_f8,
    },
    created () {
        this.getInfo()
        this.getLisIdolAll()
        if(this.token == null){
            this.getListChanelNologin()
            this.getListChanel()
        }else{
            this.getListChanelMember()
        }
        this.getInfoLink()
    },
    computed: {
        loadMore_hotidol() {
            return this.hot_idol.slice(0, this.length_hot);
        },
    },
    methods: {
        getInfoLink () {
            this.axios.get(this.api_previewLink + '?domain=' + this.url_param).then((response) => {
                this.linkf8bet = response.data.data.list_data.banner
            })
        },
        forgotpassword (e) {
            e.preventDefault()
            $('.loading_show').show().fadeIn(10)
            this.showModal = false
            this.axios.post(this.api_forgot, {
                email: this.email_forgot,
            }).then(res => {
                $('#loading_show').css('display', 'none')
                this.$swal({
                    position: 'top-end',
                    icon: 'success',
                    title: res.data.message,
                    showConfirmButton: false,
                    timer: 4000
                })
                // window.location.href = '/'
            }).catch(e => {
                // console.log(e);
                $('#loading_show').css('display', 'none')
                if(e.response.status === 404){
                    this.$swal({
                        position: 'top-end',
                        icon: 'error',
                        title: e.response.data.message,
                        showConfirmButton: false,
                        timer: 3000
                    })
                }
            })
        },
        getInfo () {
            this.axios.get(this.api_infidol + '?id=' + this.id).then((response) => {
                this.fullname = response.data.data.user.fullname
                this.live = response.data.data.user.live
                this.hinh_anh = response.data.data.user.hinh_anh
                this.type = response.data.data.user.type
                this.point_rank = response.data.data.user.point_rank
                this.point = response.data.data.user.point
                this.intro = response.data.data.user.intro
                this.follow = response.data.data.user.follow_total
                this.listFollow = response.data.data.user.follow
                this.lk_link_game = response.data.data.user.link_game
                this.lk_zalo = response.data.data.user.zalo
                this.lk_facebook = response.data.data.user.facebook
                this.lk_telegram = response.data.data.user.telegram
                let username = response.data.data.user.username
                this.axios.get(this.api_listvideoUs + '?useridol=' + username).then(res => {
                    this.listVideo = res.data.items
                    this.totalCount = this.listVideo.length
                    this.paginatedData()
                })
            }).catch(e => {
                if (e.response.status === 401) {
                    this.$swal({
                        position: 'top-end',
                        icon: 'error',
                        title: e.response.data.message,
                        showConfirmButton: false,
                        timer: 11000
                    })
                    setTimeout(function() {
                        this.axios.post(this.api_logout, {
                        }).then(res => {
                            if (res.data.success === true) {
                                localStorage.clear();
                            }
                        })
                    }.bind(this), 5000);
                    setTimeout(function() {
                        window.location.href = '/'
                    }, 13000);
                }
            })
        },
        paginatedData () {
            this.datas = this.listVideo.slice((this.page - 1) * this.perPage, this.page * this.perPage)
        },
        clickCallback (pageNum) {
            this.page = pageNum;
            this.paginatedData();
        },
        changeTabs (tab) {
            this.tabSelecteds = tab
        },
        followIdol (e) {
            e.preventDefault()
            $('.loading_show').show().fadeIn(10)
            this.axios.post(this.api_follow, 
                {
                    id: this.id
                },
                {
                    headers: {
                        Authorization: 'Bearer ' + window.localStorage.getItem('token')
                    }
                }
            ).then(res => {
                // console.log('res', res);
                $('#loading_show').css('display', 'none')
                this.checkFollow = 1
                this.checkFollows = 0
                this.follow = this.follow + 1
                this.$swal({
                    position: 'top-end',
                    icon: 'success',
                    title: res.data.message,
                    showConfirmButton: false,
                    timer: 4000
                })
            }).catch(e => {
                // console.log('e', e);
                $('#loading_show').css('display', 'none')
                this.checkFollow = 0
                if(e.response.status === 404){
                    this.$swal({
                        position: 'top-end',
                        icon: 'error',
                        title: e.response.data.message,
                        showConfirmButton: false,
                        timer: 3000
                    })
                }
            })
        },
        unfollowIdol (e) {
            e.preventDefault()
            $('.loading_show').show().fadeIn(10)
            this.axios.post(this.api_unfollow, 
                {
                    id: this.id
                },
                {
                    headers: {
                        Authorization: 'Bearer ' + window.localStorage.getItem('token')
                    }
                }
            ).then(res => {
                // console.log('res', res);
                $('#loading_show').css('display', 'none')
                this.checkFollow = 0
                this.checkFollows = 1
                this.follow = this.follow - 1
                this.$swal({
                    position: 'top-end',
                    icon: 'success',
                    title: res.data.message,
                    showConfirmButton: false,
                    timer: 4000
                })
            }).catch(e => {
                // console.log('e', e);
                $('#loading_show').css('display', 'none')
                this.checkFollow = 1
                if(e.response.status === 404){
                    this.$swal({
                        position: 'top-end',
                        icon: 'error',
                        title: e.response.data.message,
                        showConfirmButton: false,
                        timer: 3000
                    })
                }
            })
        },
        getLisIdolAll () {
            this.axios.get(this.api_listall).then((response) => {
                this.hot_idol = this.shuffle(response.data.data.list_user)
            })
        },
        loadMore() {
            if (this.length_hot > this.hot_idol.length) return;
            this.length_hot = this.length_hot + 5;
        },
        shuffle(arra1) {
            var ctr = arra1.length, temp, index;
            while (ctr > 0) {
                index = Math.floor(Math.random() * ctr);
                ctr--;
                temp = arra1[ctr];
                arra1[ctr] = arra1[index];
                arra1[index] = temp;
            }
            return arra1;
        },
        DeleteVideo (e) {
            e.preventDefault()
            $('.loading_show').show().fadeIn(10)
            var idVideo = Number(e.target.getAttribute('data_id'))
            this.axios.post(this.api_delete_video, 
                {
                    id: idVideo
                },
                {
                    headers: {
                        Authorization: 'Bearer ' + window.localStorage.getItem('token')
                    }
                }
            ).then(res => {
                // console.log('res', res);
                $('#loading_show').css('display', 'none')
                this.$swal({
                    position: 'top-end',
                    icon: 'success',
                    title: res.data.message,
                    showConfirmButton: false,
                    timer: 4000
                })
                window.location.reload()
            }).catch(e => {
                // console.log('e', e);
                $('#loading_show').css('display', 'none')
                if(e.response.status === 404){
                    this.$swal({
                        position: 'top-end',
                        icon: 'error',
                        title: e.response.data.message,
                        showConfirmButton: false,
                        timer: 3000
                    })
                }
            })
        },
        changeTabss (tab) {
            this.tabSelectedss = tab
        },
        getListChanel () {
            this.axios.get(this.api_listGroupid + '?user_id=' + this.idIdol).then((response) => {
                this.listChanel = response.data.data
            })
        },
        getListChanelMember () {
            this.axios.get(this.api_listGroupidMember + '?user_id=' + this.idIdol,
                {
                    headers: {
                        Authorization: 'Bearer ' + window.localStorage.getItem('token')
                    }
                }
            ).then((response) => {
                this.listChanel = response.data.data
            })
        },
        getListChanelNologin () {
            this.axios.get(this.api_listGroupid + '?user_id=' + this.idIdol).then((response) => {
                this.listChanel = response.data.data
            })
        },
        formatDate (date) {
            var splitdate = date.split(' ')
            var datePart = splitdate[0].match(/\d+/g),
            year = datePart[2].substring(0),
            month = datePart[1], 
            day = datePart[0];
            var gpg = splitdate[1].split(':');
            return  gpg[0] + ':' + gpg[1] + ' ' + year + '-' + month + '-' + day;
        },
        addGroupMessage (event) {
            var idChanel = event.target.getAttribute('data_id')
            var isChat = event.target.getAttribute('data_chat')
            $('.loading_show').show().fadeIn(10)
            this.axios.post(this.api_joinGroup, 
                {
                    id: idChanel,
                },
                {
                    headers: {
                        Authorization: 'Bearer ' + window.localStorage.getItem('token')
                    }
                }
            ).then(res => {
                $('#loading_show').css('display', 'none')
                this.$swal({
                    position: 'top-end',
                    icon: 'success',
                    title: res.data.message,
                    showConfirmButton: false,
                    timer: 4000
                })
                window.location.href = '/chanel/' + this.id + '/' + isChat + '/' + idChanel
            })
        },
        playHover (idx) {
            // console.log(e.target.getAttribute('data_id'));
            if (!this.videosState[idx]) {
                this.videosState[idx] = {
                    play: false
                }
            }
            const isPlaying = this.videosState[idx].play;
            const video = this.$refs.video[idx];
            if (!isPlaying) {
                video.play();
            } else {
                video.pause();
            }
            this.videosState[idx].play = !isPlaying
        },
        pauseHover (idx) {
            if (!this.videosState[idx]) {
                this.videosState[idx] = {
                    play: false
                }
            }
            const isPlaying = this.videosState[idx].play;
            const video = this.$refs.video[idx];
            video.pause();
            this.videosState[idx].play = !isPlaying
        },
        viewmodal_rg () {
            this.showModal_dk = true
            this.showModal = false
        },
        viewmodal_lg () {
            this.showModal = true
            this.showModal_dk = false
        },
        showModalrg () {
            this.showModal = false
            this.showModal_dk = true
        },
        showModallg () {
            this.showModal = true
            this.showModal_dk = false
        }
    }
}
</script>
<style>
</style>
