<template>
    <div class="root_login">
        <form action="" method="post" id="frm_login" @submit="login">
            <div class="input_modal">
                <input type="text" v-model="username_lg" placeholder="Tên đăng nhập">
                <img src="../assets/images/us.png" alt="" class="icon_input">
            </div>
            <div class="input_modal">
                <input type="password" v-model="password_lg" placeholder="Vui lòng nhập mật khẩu">
                <img src="../assets/images/pw.png" alt="" class="icon_input">
            </div>
            <div class="input_modal">
                <input type="text" class="codeCapchar" placeholder="Vui lòng nhập mã xác minh">
                <div class="capcharCheck">
                    <span>{{ codeCapchar }}</span>
                    <button type="button" @click="reloadCapchar" class="resetcapchar"></button>
                </div>
                <img src="../assets/images/xm.png" alt="" class="icon_input">
            </div>
            <p class="title_login">Vui lòng tự bảo quản thông tin tài khoản <br />Để bảo đảm an toàn cho tài khoản của bạn</p>
            <div class="btn_modal_login">
                <div class="btn_lg_md">
                    <button type="submit">Đăng nhập</button>
                </div>
                <div class="btn_lg_md1">
                    <button type="button" @click="handleClick">Đăng ký</button>
                </div>
            </div>
            <div class="btn_reload_forgot">
                <div class="btn_lg_md2">
                    <a :href="linkforgot_lg" target="_blank">
                        <button type="button">lấy lại mật khẩu <br /> qua số điện thoại</button>
                    </a>
                </div>
                <div class="btn_lg_md3">
                    <a :href="linkforgot_lg" target="_blank">
                        <button type="button">lấy lại mật khẩu <br /> qua địa chỉ email</button>
                    </a>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import $ from 'jquery'
export default {
    name: 'Login_f8',
    data () {
        return {
            codeCapchar: Math.floor(1000 + Math.random() * 9000),
            username_lg: '',
            password_lg: '',
            linkforgot_lg: ''
        }
    },
    components: {
        
    },
    created () {
        this.getInfoLink()
    },
    methods: {
        getInfoLink () {
            this.axios.get(this.api_previewLink + '?domain=' + this.url_param).then((response) => {
                this.linkforgot_lg = response.data.data.list_data.link_password;
            })
        },
        login (e) {
            e.preventDefault()
            $('.loading_show').show().fadeIn(10)
            let capchar = $('.codeCapchar').val()
            if(capchar == ''){
                $('#loading_show').css('display', 'none')
                this.$swal({
                    position: 'top-end',
                    icon: 'error',
                    title: 'Vui lòng nhập mã xác nhận',
                    showConfirmButton: false,
                    timer: 3000
                })
            }else if(capchar != this.codeCapchar){
                $('#loading_show').css('display', 'none')
                this.$swal({
                    position: 'top-end',
                    icon: 'error',
                    title: 'Mã xác nhận không đúng',
                    showConfirmButton: false,
                    timer: 3000
                })
            }else{
                this.showModal = false
                this.showModals = false
                this.axios.post(this.api_login, {
                    username: this.username_lg,
                    password: this.password_lg,
                }).then(res => {
                    $('#loading_show').css('display', 'none')
                    window.localStorage.setItem('token', res.data.data.token)
                    window.localStorage.setItem('rank', res.data.data.user.rank)
                    window.localStorage.setItem('numberus', res.data.data.user.id)
                    window.localStorage.setItem('typeus', res.data.data.user.type)
                    window.localStorage.setItem('type_live', res.data.data.user.type_live)
                    window.localStorage.setItem('img', res.data.data.user.hinh_anh)
                    window.localStorage.setItem('us', res.data.data.user.username)
                    window.localStorage.setItem('offline', res.data.data.user.offline)
                    window.localStorage.setItem('first_password', res.data.data.user.first_password)
                    window.localStorage.setItem('last_login', res.data.data.user.last_login)
                    let today = new Date();
                    let date = today.getFullYear() + '-' + (today.getMonth()+1) + '-' + today.getDate();
                    let time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
                    let timestamp = date + ' ' + time;
                    window.localStorage.setItem('time_token', timestamp)
                    this.$swal({
                        position: 'top-end',
                        icon: 'success',
                        title: res.data.message,
                        showConfirmButton: false,
                        timer: 4000
                    })
                    this.username_lg = this.password_lg = ''
                    window.location.reload()
                }).catch(e => {
                    $('#loading_show').css('display', 'none')
                    if(e.response.status === 404){
                        this.$swal({
                            position: 'top-end',
                            icon: 'error',
                            title: e.response.data.message,
                            showConfirmButton: false,
                            timer: 3000
                        })
                    }
                })
            }
        },
        reloadCapchar () {
            this.codeCapchar = Math.floor(1000 + Math.random() * 9000)
        },
        handleClick () {
            this.$emit('showMd')
        }
    }
}
</script>
<style>
</style>
