<template>
    <!-- <div class="position_right"> -->
        <!-- <div class="chat-text" v-bind:id="this.$route.params.idlive"> -->
        <!-- <div class="chat-text" id="1036">
        </div> -->
        <div class="frm_chat_live">
            <form method="post" action="" name="frm_chat" id="_frm_chat" @submit="sendChat">
                <div class="btn-chatbd">
                    <input type="hidden" name="url-chat" id="url-chat" v-bind:value="this.$route.params.idlive">
                    <input type="hidden" name="data-img" class="dataImg_chat">
                    <div class="wp_content_chat">
                        <input type="text" name="content_chat" id="content_chat" class="content_chat" placeholder="Nội dung" >
                        <div class="name_chat">
                            <img src="../assets/images/icon.png" alt="" class="show_list_icon" @click="showIconChatLive">
                            <div class="list_icon_chat">
                                <div class="item_icon_chat">
                                    <p v-for="(item, index) in listIcon" v-bind:key="index">
                                        <img :data_img="item.id" :src="'https://f8game.click/' + item.url" alt="" class="icon_chat_send" @click="sendIconChat">
                                        <span class="point_level" v-if="item.amount == 0" :data_img="item.id" @click="sendIconChat">{{ item.point}} điểm</span>
                                        <span class="point_level" v-else :data_img="item.id" @click="sendIconChat">{{ item.amount}} xu</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <button type="submit" class="btn_submit_chat" id="chat-submit">Gửi</button>
                    </div>
                    <!-- <div class="wp_content_chat" v-else>
                        <input readonly type="text" name="content_chat" autocomplete="off" class="content_chat" placeholder="Nội dung"  @click="showModal = true">
                        <div class="name_chat">
                            <img src="../assets/images/icon.png" alt="" class="show_list_icon">
                        </div>
                        <button type="button" class="btn_submit_chat" id="chat-submit" @click="showModal = true">Gửi</button>
                    </div> -->
                </div>
            </form>
        </div>
    <!-- </div> -->
</template>

<script>
import $ from 'jquery'
import Swal from 'sweetalert2'
import SocketIO from 'socket.io-client'
$( document ).ready(function() {
    var domainWeb = 'https://f8game.click/';
    var socket = SocketIO(domainWeb,{ transports: ['websocket', 'polling', 'flashsocket'] });
    // console.log('123', socket);
    socket.on('chat:chat_live',function(result){
        // console.log('result', result);
        let str = '';
        let str1 = '';
        let str2 = '';
        let img = require('@/assets/images/vp.gif')
        let img1 = require('@/assets/images/tn.png')
        // type = 1 tin nhắn
        // type = 2 sticker
        // type = 3 thông báo vào phòng
        // type = 4 link
        // type = 0 xóa tin nhắn
        if(result.data.type==0){
            $('.msg-' + result.data.id).hide()
        }else{
            if(result.data.sticker == ''){
                if(result.data.type==3){
                    this.stickerDV = `<div class="namechat_live namechat_live_inroom">${result.data.fullname} <b class="point_css point_css_chat point_css${result.data.level_rank}">${result.data.level_rank}</b><span class="textchat_content">${result.data.content}</span></div>`;
                    str1 = `<marquee width="100%" loop="1" class="mq${result.data.id}" direction="left">
                                <div class="vaophongmq">
                                    <img src="${img}" alt="" />
                                    <span><b class="fullname_color">${result.data.fullname}</b> ${result.data.content}</span>
                                </div>
                            </marquee>`
                    setTimeout(function(){ 
                        $(`.mq${result.data.id}`).remove()
                    }, 30000);
                }else if(result.data.type==4){
                    this.stickerDV = `<div class="namechat_live namechat_live_inroom">${result.data.fullname} <b class="point_css point_css_chat point_css${result.data.level_rank}">${result.data.level_rank}</b><span class="textchat_content"><a href="${result.data.content}" target="_blank">${result.data.content}</a></span></div>`;
                    str1 = `<marquee width="100%" loop="1" class="mq${result.data.id}" direction="left">
                                <div class="chattextrun">
                                    <img src="${img1}" alt="" />
                                    <span><b class="fullname_color">${result.data.fullname}</b> ${result.data.content}</span>
                                </div>
                            </marquee>`
                    setTimeout(function(){ 
                        $(`.mq${result.data.id}`).remove()
                    }, 30000);
                }else{
                    this.stickerDV = `<div class="namechat_live">${result.data.fullname} <b class="point_css point_css_chat point_css${result.data.level_rank}">${result.data.level_rank}</b><span class="textchat_content">${result.data.content}</span></div>`;
                    str1 = `<marquee width="100%" loop="1" class="mq${result.data.id}" direction="left">
                                <div class="chattextrun">
                                    <img src="${img1}" alt="" />
                                    <span><b class="fullname_color">${result.data.fullname}</b> ${result.data.content}</span>
                                </div>
                            </marquee>`
                    setTimeout(function(){ 
                        $(`.mq${result.data.id}`).remove()
                    }, 30000);
                }
            }else{
                if(result.data.amount == 0){
                    this.stickerDV = `<div class="namechat_live">${result.data.fullname} <b class="point_css point_css_chat point_css${result.data.level_rank}">${result.data.level_rank}</b></div><div class="imgsticker"><img src="${domainWeb}${result.data.sticker}" alt=""></div>`;
                    str1 = `<marquee width="100%" loop="1" class="mq${result.data.id}" direction="left"><img src="${domainWeb}${result.data.sticker}" alt=""></marquee>`
                    setTimeout(function(){ 
                        $(`.mq${result.data.id}`).remove()
                    }, 30000);
                }else{
                    this.stickerDV = `<div class="namechat_live">${result.data.fullname} <b class="point_css point_css_chat point_css${result.data.level_rank}">${result.data.level_rank}</b></div><div class="imgsticker"><img src="${domainWeb}${result.data.sticker}" alt=""></div>`;
                    str2 = `<div class="animation_pointMember_wp"><div class="animation_wp">
                                <div class="img_pointMember">
                                    <img src="${domainWeb}${result.data.sticker}" alt="">
                                </div>
                                <div class="name_numberpoint">
                                    <div class='hello' style='color: #0091ea'>${result.data.fullname} <span class="giftspan">đã tặng bạn</span> <span class="tengift">${result.data.amount}</span> xu</div>
                                    <div class='hello' style='color: #ff9100'>${result.data.fullname} <span class="giftspan">đã tặng bạn</span> <span class="tengift">${result.data.amount}</span> xu</div>
                                    <div class='hello' style='color: #e30ff0'>${result.data.fullname} <span class="giftspan">đã tặng bạn</span> <span class="tengift">${result.data.amount}</span> xu</div>
                                </div>
                            </div></div>`
                    setTimeout(function(){ 
                        $(`.animation_pointMember_wp`).remove()
                    }, 3000);
                }
            }
            str = `<div class="wp_item_chat_live msg msg-${result.data.id}">
                ${this.stickerDV}
            </div>`;
        }

        $(`.marque_div${result.data.live_id}`).append(str1);
        $(`#${result.data.live_id}`).append(str);
        $(`.animation_pointMember${result.data.live_id}`).append(str2)

        var hg = $('.chat-text').prop('scrollHeight');
        $('.chat-text').animate({ scrollTop: hg+100 },500);
    });
});
export default {
    name: 'FormChat_nm',
    data () {
        return {
            token: window.localStorage.getItem('token'),
            id_live: this.$route.params.idlive,
            listIcon: [],
            linkf8bet: '',
        }
    },
    components: {
    },
    created () {
        this.listIconChat()
        this.getInfoLink()
    },
    methods: {
        getInfoLink () {
            this.axios.get(this.api_previewLink + '?domain=' + this.url_param).then((response) => {
                this.linkf8bet = response.data.data.list_data.banner
            })
        },
        showIconChatLive () {
            $('.list_icon_chat').slideToggle();
        },
        listIconChat (){
            if(this.token == null){
                this.axios.get(this.api_liststickerlg).then((response) => {
                    this.listIcon = response.data.data.data_sticker
                })
            }else{
                this.axios.get(this.api_liststicker, {
                    headers: {
                        Authorization: 'Bearer ' + this.token
                    } 
                }).then((response) => {
                    this.listIcon = response.data.data.data_sticker
                })
            }
        },
        sendIconChat (event) {
            var dataImg = event.target.getAttribute('data_img')
            $('.dataImg_chat').val(dataImg)
            $('#chat-submit').trigger('click')
            $('.list_icon_chat').hide()
        },
        sendChat (e) {
            e.preventDefault();
            var content = $('#content_chat').val();
            var url_lk = $('input[name="url-chat"]').val();
            var dataimage = $('.dataImg_chat').val()
            if(this.token == null){
                let ss = sessionStorage.getItem("session");
                let namess = 'Khách' + ss.substring(10, 14)
                this.axios.post(this.api_sendMessagelg, 
                    {
                        id_live: url_lk,
                        content: content,
                        sticker: dataimage,
                        name: namess,
                        session_id: ss
                    },
                    {
                        headers: {
                            Authorization: 'Bearer ' + this.token
                        }
                    }
                ).then((response) => {
                    $('#content_chat').val('');
                    $('.dataImg_chat').val('');
                    this.var_res = response
                }).catch(e => {
                    Swal.fire({
                        position: 'top-end',
                        showConfirmButton: false,
                        title: e.response.data.message,
                        icon: 'error',
                        timer: 2000
                    });
                    this.var_res = e
                })
                return false;
            }else{
                this.axios.post(this.api_sendMessage, 
                    {
                        id_live: url_lk,
                        content: content,
                        sticker: dataimage
                    },
                    {
                        headers: {
                            Authorization: 'Bearer ' + this.token
                        }
                    }
                ).then((response) => {
                    $('#content_chat').val('');
                    $('.dataImg_chat').val('');
                    this.var_res = response
                }).catch(e => {
                    Swal.fire({
                        position: 'top-end',
                        showConfirmButton: false,
                        title: e.response.data.message,
                        icon: 'error',
                        timer: 2000
                    });
                    this.var_res = e
                })
                return false;
            }
        },
    }
}
</script>
<style>
</style>
