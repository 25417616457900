<template>
    <div class="wrapper_games">
        <div class="mobile_tab_game">
            <div class="tab_game">
                <carousel :wrap-around="false"  :breakpoints="breakpoints">
                    <slide :key="1">
                        <div class="item_tabgame_MB" v-bind:class="{active: tabSelected == 'hotnhat'}" v-on:click="changeTab('hotnhat')">
                            <img src="../assets/images/images/mobile_games/hotnhat.png" alt="" class="imgtab">
                            <p class="title_tab">Hot nhất</p>
                        </div>
                    </slide>
                    <slide :key="2">
                        <div class="item_tabgame_MB" v-bind:class="{active: tabSelected == 'casino'}" v-on:click="changeTab('casino')">
                            <img src="../assets/images/images/mobile_games/casino.png" alt="" class="imgtab">
                            <p class="title_tab">Casino</p>
                        </div>
                    </slide>
                    <slide :key="3">
                        <div class="item_tabgame_MB" v-bind:class="{active: tabSelected == 'nohu'}" v-on:click="changeTab('nohu')">
                            <img src="../assets/images/images/mobile_games/nohu.png" alt="" class="imgtab">
                            <p class="title_tab">Nổ hũ</p>
                        </div>
                    </slide>
                    <slide :key="4">
                        <div class="item_tabgame_MB" v-bind:class="{active: tabSelected == 'banca'}" v-on:click="changeTab('banca')">
                            <img src="../assets/images/images/mobile_games/banca.png" alt="" class="imgtab">
                            <p class="title_tab">Bắn cá</p>
                        </div>
                    </slide>
                    <slide :key="5">
                        <div class="item_tabgame_MB" v-bind:class="{active: tabSelected == 'thethao'}" v-on:click="changeTab('thethao')">
                            <img src="../assets/images/images/mobile_games/thethao.png" alt="" class="imgtab">
                            <p class="title_tab">Thể thao</p>
                        </div>
                    </slide>
                    <slide :key="6">
                        <div class="item_tabgame_MB" v-bind:class="{active: tabSelected == 'gamebai'}" v-on:click="changeTab('gamebai')">
                            <img src="../assets/images/images/mobile_games/gamebai.png" alt="" class="imgtab">
                            <p class="title_tab">Game bài</p>
                        </div>
                    </slide>
                    <slide :key="7">
                        <div class="item_tabgame_MB" v-bind:class="{active: tabSelected == 'xoso'}" v-on:click="changeTab('xoso')">
                            <img src="../assets/images/images/mobile_games/xoso.png" alt="" class="imgtab">
                            <p class="title_tab">Xổ số</p>
                        </div>
                    </slide>
                    <slide :key="8">
                        <div class="item_tabgame_MB" v-bind:class="{active: tabSelected == 'daga'}" v-on:click="changeTab('daga')">
                            <img src="../assets/images/images/mobile_games/daga.png" alt="" class="imgtab">
                            <p class="title_tab">Đá gà</p>
                        </div>
                    </slide>
                    <template #addons>
                        <Navigation />
                        <!-- <Pagination /> -->
                    </template>
                </carousel>
            </div>
            <div class="tab_content_game">
                <div class="tab_game_content" v-if="tabSelected === 'hotnhat'">
                    <div class="game_item_tab">
                        <div class="row">
                            <div class="col-md-2 col-sm-2 col-xs-3 wp_item_game_hotnhat" v-for="(item, index) in listHotNhat" :key="index">
                                <div class="item_game_hotnhat">
                                    <a :href="linkgame" target="_blank">
                                        <img :src="item.img" alt="">
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab_game_content" v-if="tabSelected === 'casino'">
                    <div class="game_item_tab">
                        <div class="row">
                            <div class="col-md-2 col-sm-2 col-xs-3 wp_item_game_hotnhat wp_item_game_casino" v-for="(item, index) in listCasino" :key="index">
                                <div class="item_game_hotnhat">
                                    <a :href="linkgame" target="_blank">
                                        <img :src="item.img" alt="">
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab_game_content" v-if="tabSelected === 'nohu'">
                    <div class="game_item_tab_nohu">
                        <div class="tab_tab_nohu">
                            <ul>
                                <li class="item_tabgame_nohu" v-bind:class="{active: tabNohu == 'tab1'}" v-on:click="changeTabs('tab1')">
                                    <img src="../assets/images/images/mobile_games/nohu/tab1.png" alt="" class="imgtab">
                                </li>
                                <li class="item_tabgame_nohu" v-bind:class="{active: tabNohu == 'tab2'}" v-on:click="changeTabs('tab2')">
                                    <img src="../assets/images/images/mobile_games/nohu/tab2.png" alt="" class="imgtab">
                                </li>
                                <li class="item_tabgame_nohu" v-bind:class="{active: tabNohu == 'tab3'}" v-on:click="changeTabs('tab3')">
                                    <img src="../assets/images/images/mobile_games/nohu/tab3.png" alt="" class="imgtab">
                                </li>
                                <li class="item_tabgame_nohu" v-bind:class="{active: tabNohu == 'tab4'}" v-on:click="changeTabs('tab4')">
                                    <img src="../assets/images/images/mobile_games/nohu/tab4.png" alt="" class="imgtab">
                                </li>
                                <li class="item_tabgame_nohu" v-bind:class="{active: tabNohu == 'tab5'}" v-on:click="changeTabs('tab5')">
                                    <img src="../assets/images/images/mobile_games/nohu/tab5.png" alt="" class="imgtab">
                                </li>
                                <li class="item_tabgame_nohu" v-bind:class="{active: tabNohu == 'tab6'}" v-on:click="changeTabs('tab6')">
                                    <img src="../assets/images/images/mobile_games/nohu/tab6.png" alt="" class="imgtab">
                                </li>
                                <li class="item_tabgame_nohu" v-bind:class="{active: tabNohu == 'tab7'}" v-on:click="changeTabs('tab7')">
                                    <img src="../assets/images/images/mobile_games/nohu/tab7.png" alt="" class="imgtab">
                                </li>
                                <li class="item_tabgame_nohu" v-bind:class="{active: tabNohu == 'tab8'}" v-on:click="changeTabs('tab8')">
                                    <img src="../assets/images/images/mobile_games/nohu/tab8.png" alt="" class="imgtab">
                                </li>
                                <li class="item_tabgame_nohu" v-bind:class="{active: tabNohu == 'tab9'}" v-on:click="changeTabs('tab9')">
                                    <img src="../assets/images/images/mobile_games/nohu/tab9.png" alt="" class="imgtab">
                                </li>
                                <li class="item_tabgame_nohu" v-bind:class="{active: tabNohu == 'tab10'}" v-on:click="changeTabs('tab10')">
                                    <img src="../assets/images/images/mobile_games/nohu/tab10.png" alt="" class="imgtab">
                                </li>
                            </ul>
                        </div>
                        <div class="content_tab_nohu">
                            <div class="tab_game_content" v-if="tabNohu === 'tab1'">
                                <div class="wp_item_tabnohu">
                                    <div class="row">
                                        <div class="col-md-2 col-sm-2 col-xs-3 wp_item_game_hotnhat wp_item_game_banca" v-for="(item, index) in listNohuTab1" :key="index">
                                            <div class="item_game_hotnhat">
                                                <a :href="linkgame" target="_blank">
                                                    <img :src="item.img" alt="">
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab_game_content" v-if="tabNohu === 'tab2'">
                                <div class="wp_item_tabnohu">
                                    <div class="row">
                                        <div class="col-md-2 col-sm-2 col-xs-3 wp_item_game_hotnhat wp_item_game_banca" v-for="(item, index) in listNohuTab2" :key="index">
                                            <div class="item_game_hotnhat">
                                                <a :href="linkgame" target="_blank">
                                                    <img :src="item.img" alt="">
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab_game_content" v-if="tabNohu === 'tab3'">
                                <div class="wp_item_tabnohu">
                                    <div class="row">
                                        <div class="col-md-2 col-sm-2 col-xs-3 wp_item_game_hotnhat wp_item_game_banca" v-for="(item, index) in listNohuTab3" :key="index">
                                            <div class="item_game_hotnhat">
                                                <a :href="linkgame" target="_blank">
                                                    <img :src="item.img" alt="">
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab_game_content" v-if="tabNohu === 'tab4'">
                                <div class="wp_item_tabnohu">
                                    <div class="row">
                                        <div class="col-md-2 col-sm-2 col-xs-3 wp_item_game_hotnhat wp_item_game_banca" v-for="(item, index) in listNohuTab4" :key="index">
                                            <div class="item_game_hotnhat">
                                                <a :href="linkgame" target="_blank">
                                                    <img :src="item.img" alt="">
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab_game_content" v-if="tabNohu === 'tab5'">
                                <div class="wp_item_tabnohu">
                                    <div class="row">
                                        <div class="col-md-2 col-sm-2 col-xs-3 wp_item_game_hotnhat wp_item_game_banca" v-for="(item, index) in listNohuTab5" :key="index">
                                            <div class="item_game_hotnhat">
                                                <a :href="linkgame" target="_blank">
                                                    <img :src="item.img" alt="">
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab_game_content" v-if="tabNohu === 'tab6'">
                                <div class="wp_item_tabnohu">
                                    <div class="row">
                                        <div class="col-md-2 col-sm-2 col-xs-3 wp_item_game_hotnhat wp_item_game_banca" v-for="(item, index) in listNohuTab6" :key="index">
                                            <div class="item_game_hotnhat">
                                                <a :href="linkgame" target="_blank">
                                                    <img :src="item.img" alt="">
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab_game_content" v-if="tabNohu === 'tab7'">
                                <div class="wp_item_tabnohu">
                                    <div class="row">
                                        <div class="col-md-2 col-sm-2 col-xs-3 wp_item_game_hotnhat wp_item_game_banca" v-for="(item, index) in listNohuTab7" :key="index">
                                            <div class="item_game_hotnhat">
                                                <a :href="linkgame" target="_blank">
                                                    <img :src="item.img" alt="">
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab_game_content" v-if="tabNohu === 'tab8'">
                                <div class="wp_item_tabnohu">
                                    <div class="row">
                                        <div class="col-md-2 col-sm-2 col-xs-3 wp_item_game_hotnhat wp_item_game_banca" v-for="(item, index) in listNohuTab8" :key="index">
                                            <div class="item_game_hotnhat">
                                                <a :href="linkgame" target="_blank">
                                                    <img :src="item.img" alt="">
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab_game_content" v-if="tabNohu === 'tab9'">
                                <div class="wp_item_tabnohu">
                                    <div class="row">
                                        <div class="col-md-2 col-sm-2 col-xs-3 wp_item_game_hotnhat wp_item_game_banca" v-for="(item, index) in listNohuTab9" :key="index">
                                            <div class="item_game_hotnhat">
                                                <a :href="linkgame" target="_blank">
                                                    <img :src="item.img" alt="">
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab_game_content" v-if="tabNohu === 'tab10'">
                                <div class="wp_item_tabnohu">
                                    <div class="row">
                                        <div class="col-md-2 col-sm-2 col-xs-3 wp_item_game_hotnhat wp_item_game_banca" v-for="(item, index) in listNohuTab10" :key="index">
                                            <div class="item_game_hotnhat">
                                                <a :href="linkgame" target="_blank">
                                                    <img :src="item.img" alt="">
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab_game_content" v-if="tabSelected === 'banca'">
                    <div class="game_item_tab">
                        <div class="row">
                            <div class="col-md-2 col-sm-2 col-xs-3 wp_item_game_hotnhat wp_item_game_banca" v-for="(item, index) in listBanca" :key="index">
                                <div class="item_game_hotnhat">
                                    <a :href="linkgame" target="_blank">
                                        <img :src="item.img" alt="">
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab_game_content" v-if="tabSelected === 'thethao'">
                    <div class="game_item_tab">
                        <div class="row">
                            <div class="col-md-2 col-sm-2 col-xs-3 wp_item_game_hotnhat wp_item_game_casino1" v-for="(item, index) in listTheThao" :key="index">
                                <div class="item_game_hotnhat">
                                    <a :href="linkgame" target="_blank">
                                        <img :src="item.img" alt="">
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab_game_content" v-if="tabSelected === 'gamebai'">
                    <div class="game_item_tab_nohu">
                        <div class="tab_tab_nohu">
                            <ul>
                                <li class="item_tabgame_nohu" v-bind:class="{active: tabGameBai == 'tab1'}" v-on:click="changeTabGB('tab1')">
                                    <img src="../assets/images/images/mobile_games/gamebai/tab1.png" alt="" class="imgtab">
                                </li>
                                <li class="item_tabgame_nohu" v-bind:class="{active: tabGameBai == 'tab2'}" v-on:click="changeTabGB('tab2')">
                                    <img src="../assets/images/images/mobile_games/gamebai/tab2.png" alt="" class="imgtab">
                                </li>
                                <li class="item_tabgame_nohu" v-bind:class="{active: tabGameBai == 'tab3'}" v-on:click="changeTabGB('tab3')">
                                    <img src="../assets/images/images/mobile_games/gamebai/tab3.png" alt="" class="imgtab">
                                </li>
                                <li class="item_tabgame_nohu" v-bind:class="{active: tabGameBai == 'tab4'}" v-on:click="changeTabGB('tab4')">
                                    <img src="../assets/images/images/mobile_games/gamebai/tab4.png" alt="" class="imgtab">
                                </li>
                                <li class="item_tabgame_nohu" v-bind:class="{active: tabGameBai == 'tab5'}" v-on:click="changeTabGB('tab5')">
                                    <img src="../assets/images/images/mobile_games/gamebai/tab5.png" alt="" class="imgtab">
                                </li>
                                <li class="item_tabgame_nohu" v-bind:class="{active: tabGameBai == 'tab6'}" v-on:click="changeTabGB('tab6')">
                                    <img src="../assets/images/images/mobile_games/gamebai/tab6.png" alt="" class="imgtab">
                                </li>
                                <li class="item_tabgame_nohu" v-bind:class="{active: tabGameBai == 'tab7'}" v-on:click="changeTabGB('tab7')">
                                    <img src="../assets/images/images/mobile_games/gamebai/tab7.png" alt="" class="imgtab">
                                </li>
                                <li class="item_tabgame_nohu" v-bind:class="{active: tabGameBai == 'tab8'}" v-on:click="changeTabGB('tab8')">
                                    <img src="../assets/images/images/mobile_games/gamebai/tab8.png" alt="" class="imgtab">
                                </li>
                                <li class="item_tabgame_nohu" v-bind:class="{active: tabGameBai == 'tab9'}" v-on:click="changeTabGB('tab9')">
                                    <img src="../assets/images/images/mobile_games/gamebai/tab9.png" alt="" class="imgtab">
                                </li>
                            </ul>
                        </div>
                        <div class="content_tab_nohu">
                            <div class="tab_game_content" v-if="tabGameBai === 'tab1'">
                                <div class="wp_item_tabnohu">
                                    <div class="row">
                                        <div class="col-md-2 col-sm-2 col-xs-3 wp_item_game_hotnhat wp_item_game_banca" v-for="(item, index) in listGameBaiTab1" :key="index">
                                            <div class="item_game_hotnhat">
                                                <a :href="linkgame" target="_blank">
                                                    <img :src="item.img" alt="">
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab_game_content" v-if="tabGameBai === 'tab2'">
                                <div class="wp_item_tabnohu">
                                    <div class="row">
                                        <div class="col-md-2 col-sm-2 col-xs-3 wp_item_game_hotnhat wp_item_game_banca" v-for="(item, index) in listGameBaiTab2" :key="index">
                                            <div class="item_game_hotnhat">
                                                <a :href="linkgame" target="_blank">
                                                    <img :src="item.img" alt="">
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab_game_content" v-if="tabGameBai === 'tab3'">
                                <div class="wp_item_tabnohu">
                                    <div class="row">
                                        <div class="col-md-2 col-sm-2 col-xs-3 wp_item_game_hotnhat wp_item_game_banca" v-for="(item, index) in listGameBaiTab3" :key="index">
                                            <div class="item_game_hotnhat">
                                                <a :href="linkgame" target="_blank">
                                                    <img :src="item.img" alt="">
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab_game_content" v-if="tabGameBai === 'tab4'">
                                <div class="wp_item_tabnohu">
                                    <div class="row">
                                        <div class="col-md-2 col-sm-2 col-xs-3 wp_item_game_hotnhat wp_item_game_banca" v-for="(item, index) in listGameBaiTab4" :key="index">
                                            <div class="item_game_hotnhat">
                                                <a :href="linkgame" target="_blank">
                                                    <img :src="item.img" alt="">
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab_game_content" v-if="tabGameBai === 'tab5'">
                                <div class="wp_item_tabnohu">
                                    <div class="row">
                                        <div class="col-md-2 col-sm-2 col-xs-3 wp_item_game_hotnhat wp_item_game_banca" v-for="(item, index) in listGameBaiTab5" :key="index">
                                            <div class="item_game_hotnhat">
                                                <a :href="linkgame" target="_blank">
                                                    <img :src="item.img" alt="">
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab_game_content" v-if="tabGameBai === 'tab6'">
                                <div class="wp_item_tabnohu">
                                    <div class="row">
                                        <div class="col-md-2 col-sm-2 col-xs-3 wp_item_game_hotnhat wp_item_game_banca" v-for="(item, index) in listGameBaiTab6" :key="index">
                                            <div class="item_game_hotnhat">
                                                <a :href="linkgame" target="_blank">
                                                    <img :src="item.img" alt="">
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab_game_content" v-if="tabGameBai === 'tab7'">
                                <div class="wp_item_tabnohu">
                                    <div class="row">
                                        <div class="col-md-2 col-sm-2 col-xs-3 wp_item_game_hotnhat wp_item_game_banca" v-for="(item, index) in listGameBaiTab7" :key="index">
                                            <div class="item_game_hotnhat">
                                                <a :href="linkgame" target="_blank">
                                                    <img :src="item.img" alt="">
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab_game_content" v-if="tabGameBai === 'tab8'">
                                <div class="wp_item_tabnohu">
                                    <div class="row">
                                        <div class="col-md-2 col-sm-2 col-xs-3 wp_item_game_hotnhat wp_item_game_banca" v-for="(item, index) in listGameBaiTab8" :key="index">
                                            <div class="item_game_hotnhat">
                                                <a :href="linkgame" target="_blank">
                                                    <img :src="item.img" alt="">
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab_game_content" v-if="tabGameBai === 'tab9'">
                                <div class="wp_item_tabnohu">
                                    <div class="row">
                                        <div class="col-md-2 col-sm-2 col-xs-3 wp_item_game_hotnhat wp_item_game_banca" v-for="(item, index) in listGameBaiTab9" :key="index">
                                            <div class="item_game_hotnhat">
                                                <a :href="linkgame" target="_blank">
                                                    <img :src="item.img" alt="">
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab_game_content" v-if="tabSelected === 'xoso'">
                    <div class="game_item_tab">
                        <div class="row">
                            <div class="col-md-2 col-sm-2 col-xs-3 wp_item_game_hotnhat wp_item_game_casino1" v-for="(item, index) in listXoSo" :key="index">
                                <div class="item_game_hotnhat">
                                    <a :href="linkgame" target="_blank">
                                        <img :src="item.img" alt="">
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab_game_content" v-if="tabSelected === 'daga'">
                    <div class="game_item_tab">
                        <div class="row">
                            <div class="col-md-2 col-sm-2 col-xs-3 wp_item_game_hotnhat wp_item_game_casino1" v-for="(item, index) in listDaGa" :key="index">
                                <div class="item_game_hotnhat">
                                    <a :href="linkgame" target="_blank">
                                        <img :src="item.img" alt="">
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Carousel, Slide, Navigation } from 'vue3-carousel'
// import $ from 'jquery'
export default {
    name: 'Games_nm',
    setup() {
		return {
			breakpoints: {
                0: {
					itemsToShow: 3,
					snapAlign: "start"
				},
				390: {
					itemsToShow: 4,
					snapAlign: "start"
				},
				430: {
					itemsToShow: 5,
					snapAlign: "start"
				},
			}
		};
	},
    data () {
        return {
            tabSelected: 'hotnhat',
            tabNohu: 'tab1',
            tabGameBai: 'tab1',
            linkgame: '',
            listHotNhat: [],
            listCasino: [],
            listBanca: [],
            listTheThao: [],
            listXoSo: [],
            listDaGa: [],
            listNohuTab1: [],
            listNohuTab2: [],
            listNohuTab3: [],
            listNohuTab4: [],
            listNohuTab5: [],
            listNohuTab6: [],
            listNohuTab7: [],
            listNohuTab8: [],
            listNohuTab9: [],
            listNohuTab10: [],

            listGameBaiTab1: [],
            listGameBaiTab2: [],
            listGameBaiTab3: [],
            listGameBaiTab4: [],
            listGameBaiTab5: [],
            listGameBaiTab6: [],
            listGameBaiTab7: [],
            listGameBaiTab8: [],
            listGameBaiTab9: [],
        }
    },
    components: {
        Carousel,
        Slide,
        Navigation,
    },
    mounted () {
        
    },
    created () {
        this.getInfoLink()
        this.GetListGameHotNhat()
        this.GetListGameCasino()
        this.GetListGameBanCa()
        this.GetListGameTheThao()
        this.GetListGameXoSo()
        this.GetListGameDaGa()
        this.GetListGameNohutab1()
        this.GetListGameNohutab2()
        this.GetListGameNohutab3()
        this.GetListGameNohutab4()
        this.GetListGameNohutab5()
        this.GetListGameNohutab6()
        this.GetListGameNohutab7()
        this.GetListGameNohutab8()
        this.GetListGameNohutab9()
        this.GetListGameNohutab10()

        this.GetListGameBaitab1()
        this.GetListGameBaitab2()
        this.GetListGameBaitab3()
        this.GetListGameBaitab4()
        this.GetListGameBaitab5()
        this.GetListGameBaitab6()
        this.GetListGameBaitab7()
        this.GetListGameBaitab8()
        this.GetListGameBaitab9()
    },
    computed: {
        
    },
    methods: {
        getInfoLink () {
            this.axios.get(this.api_previewLink + '?domain=' + this.url_param).then((response) => {
                this.linkgame = response.data.data.list_data.link_game;
            })
        },
        changeTab (tab) {
            this.tabSelected = tab
        },
        changeTabs (tab) {
            this.tabNohu = tab
        },
        changeTabGB (tab) {
            this.tabGameBai = tab
        },
        GetListGameHotNhat () {
            this.listHotNhat = [
                { name: 'SE Bacarat', img: require('@/assets/images/images/mobile_games/hotnhat/1.png')},
                { name: 'DG Rồng Hổ', img: require('@/assets/images/images/mobile_games/hotnhat/2.png')},
                { name: 'Saba thể thao', img: require('@/assets/images/images/mobile_games/hotnhat/3.png')},
                { name: 'WM xóc Đĩa', img: require('@/assets/images/images/mobile_games/hotnhat/4.png')},
                { name: 'Super ACE', img: require('@/assets/images/images/mobile_games/hotnhat/5.png')},
                { name: 'Kho báu Aztec', img: require('@/assets/images/images/mobile_games/hotnhat/6.png')},
                { name: 'Đường mạt chược', img: require('@/assets/images/images/mobile_games/hotnhat/7.png')},
                { name: 'Đường mạt chược 2', img: require('@/assets/images/images/mobile_games/hotnhat/8.png')},
                { name: 'Mega bắn cá', img: require('@/assets/images/images/mobile_games/hotnhat/9.png')},
                { name: 'Chill bắn cá', img: require('@/assets/images/images/mobile_games/hotnhat/10.png')},
                { name: 'Đế quốc hoàng kim', img: require('@/assets/images/images/mobile_games/hotnhat/11.png')},
                { name: 'Chuyên gia săn rồng', img: require('@/assets/images/images/mobile_games/hotnhat/12.png')},
                { name: 'Bắt châu báu', img: require('@/assets/images/images/mobile_games/hotnhat/13.png')},
                { name: 'Quyền vương', img: require('@/assets/images/images/mobile_games/hotnhat/14.png')},
                { name: 'Báu vật cổ đại Zeus', img: require('@/assets/images/images/mobile_games/hotnhat/15.png')},
                { name: 'Tam tiên bắt cá', img: require('@/assets/images/images/mobile_games/hotnhat/16.png')},
                { name: 'Tết nguyên đán', img: require('@/assets/images/images/mobile_games/hotnhat/17.png')},
                { name: 'Thần tài giáng lâm', img: require('@/assets/images/images/mobile_games/hotnhat/18.png')},
                { name: 'Thần tài phú quý', img: require('@/assets/images/images/mobile_games/hotnhat/19.png')},
                { name: 'Chợ đêm', img: require('@/assets/images/images/mobile_games/hotnhat/20.png')},
                { name: 'Múa lân may mắn 7', img: require('@/assets/images/images/mobile_games/hotnhat/21.png')},
                { name: 'Vận mệnh tốt', img: require('@/assets/images/images/mobile_games/hotnhat/22.png')},
                { name: 'Siêu bò tót', img: require('@/assets/images/images/mobile_games/hotnhat/23.png')},
                { name: 'Chúc mừng năm mới 2', img: require('@/assets/images/images/mobile_games/hotnhat/24.png')},
                { name: 'Cai shen bắn cá', img: require('@/assets/images/images/mobile_games/hotnhat/25.png')},
                { name: 'Đào thoát Deluxe', img: require('@/assets/images/images/mobile_games/hotnhat/26.png')},
                { name: 'Cổng olympus', img: require('@/assets/images/images/mobile_games/hotnhat/27.png')},
                { name: 'Bắn cá vũ trụ', img: require('@/assets/images/images/mobile_games/hotnhat/28.png')},
                { name: 'Bắn cá ngũ long', img: require('@/assets/images/images/mobile_games/hotnhat/29.png')},
                { name: 'Tài xỉu', img: require('@/assets/images/images/mobile_games/hotnhat/30.png')},
                { name: '888 con rồng', img: require('@/assets/images/images/mobile_games/hotnhat/31.png')},
                { name: 'FA cai shen 2', img: require('@/assets/images/images/mobile_games/hotnhat/32.png')},
                { name: 'Cặp song sinh', img: require('@/assets/images/images/mobile_games/hotnhat/33.png')},
                { name: 'Slot trái cây', img: require('@/assets/images/images/mobile_games/hotnhat/34.png')},
                { name: 'Đêm nhạc disco', img: require('@/assets/images/images/mobile_games/hotnhat/35.png')},
                { name: 'Tài xỉu 2', img: require('@/assets/images/images/mobile_games/hotnhat/36.png')},
                { name: 'Đại thánh bắn cá', img: require('@/assets/images/images/mobile_games/hotnhat/37.png')},
                { name: 'Cao thủ săn rồng', img: require('@/assets/images/images/mobile_games/hotnhat/38.png')},
                { name: 'Tiến lên', img: require('@/assets/images/images/mobile_games/hotnhat/39.png')},
                { name: 'Bonus Mania', img: require('@/assets/images/images/mobile_games/hotnhat/40.png')},
                { name: 'Con mèo may mắn', img: require('@/assets/images/images/mobile_games/hotnhat/41.png')},
                { name: 'Kho báu thần long', img: require('@/assets/images/images/mobile_games/hotnhat/42.png')},
                { name: 'Mạt chược Fa', img: require('@/assets/images/images/mobile_games/hotnhat/43.png')},
                { name: 'Cuộc chiến bắn cá', img: require('@/assets/images/images/mobile_games/hotnhat/44.png')},
                { name: 'SEBacarat', img: require('@/assets/images/images/mobile_games/hotnhat/45.png')},
                { name: 'SEBacarat', img: require('@/assets/images/images/mobile_games/hotnhat/46.png')},
                { name: 'SEBacarat', img: require('@/assets/images/images/mobile_games/hotnhat/47.png')},
                { name: 'SEBacarat', img: require('@/assets/images/images/mobile_games/hotnhat/48.png')},
                { name: 'SEBacarat', img: require('@/assets/images/images/mobile_games/hotnhat/49.png')},
            ]
        },
        GetListGameCasino () {
            this.listCasino = [
                { name: 'SE Bacarat', img: require('@/assets/images/images/mobile_games/casino/1.png')},
                { name: 'DG Rồng Hổ', img: require('@/assets/images/images/mobile_games/casino/2.png')},
                { name: 'Saba thể thao', img: require('@/assets/images/images/mobile_games/casino/3.png')},
                { name: 'WM xóc Đĩa', img: require('@/assets/images/images/mobile_games/casino/4.png')},
                { name: 'Super ACE', img: require('@/assets/images/images/mobile_games/casino/5.png')},
                { name: 'Kho báu Aztec', img: require('@/assets/images/images/mobile_games/casino/6.png')},
                { name: 'Đường mạt chược', img: require('@/assets/images/images/mobile_games/casino/7.png')},
                { name: 'Đường mạt chược 2', img: require('@/assets/images/images/mobile_games/casino/8.png')},
                { name: 'Mega bắn cá', img: require('@/assets/images/images/mobile_games/casino/9.png')},
                { name: 'Chill bắn cá', img: require('@/assets/images/images/mobile_games/casino/10.png')},
                { name: 'Đế quốc hoàng kim', img: require('@/assets/images/images/mobile_games/casino/11.png')},
                { name: 'Chuyên gia săn rồng', img: require('@/assets/images/images/mobile_games/casino/12.png')},
                { name: 'Bắt châu báu', img: require('@/assets/images/images/mobile_games/casino/13.png')},
                { name: 'Quyền vương', img: require('@/assets/images/images/mobile_games/casino/14.png')},
            ]
        },
        GetListGameBanCa () {
            this.listBanca = [
                { name: 'SE Bacarat', img: require('@/assets/images/images/mobile_games/banca/1.png')},
                { name: 'DG Rồng Hổ', img: require('@/assets/images/images/mobile_games/banca/2.png')},
                { name: 'Saba thể thao', img: require('@/assets/images/images/mobile_games/banca/3.png')},
                { name: 'WM xóc Đĩa', img: require('@/assets/images/images/mobile_games/banca/4.png')},
                { name: 'Super ACE', img: require('@/assets/images/images/mobile_games/banca/5.png')},
                { name: 'Kho báu Aztec', img: require('@/assets/images/images/mobile_games/banca/6.png')},
                { name: 'Đường mạt chược', img: require('@/assets/images/images/mobile_games/banca/7.png')},
                { name: 'Đường mạt chược 2', img: require('@/assets/images/images/mobile_games/banca/8.png')},
                { name: 'Mega bắn cá', img: require('@/assets/images/images/mobile_games/banca/9.png')},
                { name: 'Chill bắn cá', img: require('@/assets/images/images/mobile_games/banca/10.png')},
                { name: 'Đế quốc hoàng kim', img: require('@/assets/images/images/mobile_games/banca/11.png')},
                { name: 'Chuyên gia săn rồng', img: require('@/assets/images/images/mobile_games/banca/12.png')},
                { name: 'Bắt châu báu', img: require('@/assets/images/images/mobile_games/banca/13.png')},
                { name: 'Quyền vương', img: require('@/assets/images/images/mobile_games/banca/14.png')},
                { name: 'Quyền vương', img: require('@/assets/images/images/mobile_games/banca/15.png')},
            ]
        },
        GetListGameTheThao () {
            this.listTheThao = [
                { name: 'SE Bacarat', img: require('@/assets/images/images/mobile_games/casino1/1.png')},
                { name: 'DG Rồng Hổ', img: require('@/assets/images/images/mobile_games/casino1/2.png')},
                { name: 'Saba thể thao', img: require('@/assets/images/images/mobile_games/casino1/3.png')},
                { name: 'WM xóc Đĩa', img: require('@/assets/images/images/mobile_games/casino1/4.png')},
                { name: 'Super ACE', img: require('@/assets/images/images/mobile_games/casino1/5.png')},
                { name: 'Kho báu Aztec', img: require('@/assets/images/images/mobile_games/casino1/6.png')},
            ]
        },
        GetListGameXoSo () {
            this.listXoSo = [
                { name: 'SE Bacarat', img: require('@/assets/images/images/mobile_games/xoso/1.png')},
                { name: 'DG Rồng Hổ', img: require('@/assets/images/images/mobile_games/xoso/2.png')},
                { name: 'Saba thể thao', img: require('@/assets/images/images/mobile_games/xoso/3.png')},
                { name: 'WM xóc Đĩa', img: require('@/assets/images/images/mobile_games/xoso/4.png')},
                { name: 'Super ACE', img: require('@/assets/images/images/mobile_games/xoso/5.png')},
                { name: 'Kho báu Aztec', img: require('@/assets/images/images/mobile_games/xoso/6.png')},
                { name: 'Kho báu Aztec', img: require('@/assets/images/images/mobile_games/xoso/7.png')},
            ]
        },
        GetListGameDaGa () {
            this.listDaGa = [
                { name: 'SE Bacarat', img: require('@/assets/images/images/mobile_games/daga/1.png')},
            ]
        },
        GetListGameNohutab1 () {
            this.listNohuTab1 = [
                { name: 'SE Bacarat', img: require('@/assets/images/images/mobile_games/nohu/tab1/1.png')},
                { name: 'DG Rồng Hổ', img: require('@/assets/images/images/mobile_games/nohu/tab1/2.png')},
                { name: 'Saba thể thao', img: require('@/assets/images/images/mobile_games/nohu/tab1/3.png')},
                { name: 'WM xóc Đĩa', img: require('@/assets/images/images/mobile_games/nohu/tab1/4.png')},
                { name: 'Super ACE', img: require('@/assets/images/images/mobile_games/nohu/tab1/5.png')},
                { name: 'Kho báu Aztec', img: require('@/assets/images/images/mobile_games/nohu/tab1/6.png')},
                { name: 'Đường mạt chược', img: require('@/assets/images/images/mobile_games/nohu/tab1/7.png')},
                { name: 'Đường mạt chược 2', img: require('@/assets/images/images/mobile_games/nohu/tab1/8.png')},
                { name: 'Mega bắn cá', img: require('@/assets/images/images/mobile_games/nohu/tab1/9.png')},
                { name: 'Chill bắn cá', img: require('@/assets/images/images/mobile_games/nohu/tab1/10.png')},
                { name: 'Đế quốc hoàng kim', img: require('@/assets/images/images/mobile_games/nohu/tab1/11.png')},
                { name: 'Chuyên gia săn rồng', img: require('@/assets/images/images/mobile_games/nohu/tab1/12.png')},
                { name: 'Bắt châu báu', img: require('@/assets/images/images/mobile_games/nohu/tab1/13.png')},
                { name: 'Quyền vương', img: require('@/assets/images/images/mobile_games/nohu/tab1/14.png')},
                { name: 'Quyền vương', img: require('@/assets/images/images/mobile_games/nohu/tab1/15.png')},
            ]
        },
        GetListGameNohutab2 () {
            this.listNohuTab2 = [
                { name: 'SE Bacarat', img: require('@/assets/images/images/mobile_games/nohu/tab2/1.png')},
                { name: 'DG Rồng Hổ', img: require('@/assets/images/images/mobile_games/nohu/tab2/2.png')},
                { name: 'Saba thể thao', img: require('@/assets/images/images/mobile_games/nohu/tab2/3.png')},
                { name: 'WM xóc Đĩa', img: require('@/assets/images/images/mobile_games/nohu/tab2/4.png')},
                { name: 'Super ACE', img: require('@/assets/images/images/mobile_games/nohu/tab2/5.png')},
                { name: 'Kho báu Aztec', img: require('@/assets/images/images/mobile_games/nohu/tab2/6.png')},
                { name: 'Đường mạt chược', img: require('@/assets/images/images/mobile_games/nohu/tab2/7.png')},
                { name: 'Đường mạt chược 2', img: require('@/assets/images/images/mobile_games/nohu/tab2/8.png')},
                { name: 'Mega bắn cá', img: require('@/assets/images/images/mobile_games/nohu/tab2/9.png')},
                { name: 'Chill bắn cá', img: require('@/assets/images/images/mobile_games/nohu/tab2/10.png')},
                { name: 'Đế quốc hoàng kim', img: require('@/assets/images/images/mobile_games/nohu/tab2/11.png')},
                { name: 'Chuyên gia săn rồng', img: require('@/assets/images/images/mobile_games/nohu/tab2/12.png')},
                { name: 'Bắt châu báu', img: require('@/assets/images/images/mobile_games/nohu/tab2/13.png')},
                { name: 'Quyền vương', img: require('@/assets/images/images/mobile_games/nohu/tab2/14.png')},
                { name: 'Quyền vương', img: require('@/assets/images/images/mobile_games/nohu/tab2/15.png')},
            ]
        },
        GetListGameNohutab3 () {
            this.listNohuTab3 = [
                { name: 'SE Bacarat', img: require('@/assets/images/images/mobile_games/nohu/tab3/1.png')},
                { name: 'DG Rồng Hổ', img: require('@/assets/images/images/mobile_games/nohu/tab3/2.png')},
                { name: 'Saba thể thao', img: require('@/assets/images/images/mobile_games/nohu/tab3/3.png')},
                { name: 'WM xóc Đĩa', img: require('@/assets/images/images/mobile_games/nohu/tab3/4.png')},
                { name: 'Super ACE', img: require('@/assets/images/images/mobile_games/nohu/tab3/5.png')},
                { name: 'Kho báu Aztec', img: require('@/assets/images/images/mobile_games/nohu/tab3/6.png')},
                { name: 'Đường mạt chược', img: require('@/assets/images/images/mobile_games/nohu/tab3/7.png')},
                { name: 'Đường mạt chược 2', img: require('@/assets/images/images/mobile_games/nohu/tab3/8.png')},
                { name: 'Mega bắn cá', img: require('@/assets/images/images/mobile_games/nohu/tab3/9.png')},
                { name: 'Chill bắn cá', img: require('@/assets/images/images/mobile_games/nohu/tab3/10.png')},
                { name: 'Đế quốc hoàng kim', img: require('@/assets/images/images/mobile_games/nohu/tab3/11.png')},
                { name: 'Chuyên gia săn rồng', img: require('@/assets/images/images/mobile_games/nohu/tab3/12.png')},
                { name: 'Bắt châu báu', img: require('@/assets/images/images/mobile_games/nohu/tab3/13.png')},
                { name: 'Quyền vương', img: require('@/assets/images/images/mobile_games/nohu/tab3/14.png')},
                { name: 'Quyền vương', img: require('@/assets/images/images/mobile_games/nohu/tab3/15.png')},
            ]
        },
        GetListGameNohutab4 () {
            this.listNohuTab4 = [
                { name: 'SE Bacarat', img: require('@/assets/images/images/mobile_games/nohu/tab4/1.png')},
                { name: 'DG Rồng Hổ', img: require('@/assets/images/images/mobile_games/nohu/tab4/2.png')},
                { name: 'Saba thể thao', img: require('@/assets/images/images/mobile_games/nohu/tab4/3.png')},
                { name: 'WM xóc Đĩa', img: require('@/assets/images/images/mobile_games/nohu/tab4/4.png')},
                { name: 'Super ACE', img: require('@/assets/images/images/mobile_games/nohu/tab4/5.png')},
                { name: 'Kho báu Aztec', img: require('@/assets/images/images/mobile_games/nohu/tab4/6.png')},
                { name: 'Đường mạt chược', img: require('@/assets/images/images/mobile_games/nohu/tab4/7.png')},
                { name: 'Đường mạt chược 2', img: require('@/assets/images/images/mobile_games/nohu/tab4/8.png')},
                { name: 'Mega bắn cá', img: require('@/assets/images/images/mobile_games/nohu/tab4/9.png')},
                { name: 'Chill bắn cá', img: require('@/assets/images/images/mobile_games/nohu/tab4/10.png')},
                { name: 'Đế quốc hoàng kim', img: require('@/assets/images/images/mobile_games/nohu/tab4/11.png')},
                { name: 'Chuyên gia săn rồng', img: require('@/assets/images/images/mobile_games/nohu/tab4/12.png')},
                { name: 'Bắt châu báu', img: require('@/assets/images/images/mobile_games/nohu/tab4/13.png')},
                { name: 'Quyền vương', img: require('@/assets/images/images/mobile_games/nohu/tab4/14.png')},
                { name: 'Quyền vương', img: require('@/assets/images/images/mobile_games/nohu/tab4/15.png')},
            ]
        },
        GetListGameNohutab5 () {
            this.listNohuTab5 = [
                { name: 'SE Bacarat', img: require('@/assets/images/images/mobile_games/nohu/tab5/1.png')},
                { name: 'DG Rồng Hổ', img: require('@/assets/images/images/mobile_games/nohu/tab5/2.png')},
                { name: 'Saba thể thao', img: require('@/assets/images/images/mobile_games/nohu/tab5/3.png')},
                { name: 'WM xóc Đĩa', img: require('@/assets/images/images/mobile_games/nohu/tab5/4.png')},
                { name: 'Super ACE', img: require('@/assets/images/images/mobile_games/nohu/tab5/5.png')},
                { name: 'Kho báu Aztec', img: require('@/assets/images/images/mobile_games/nohu/tab5/6.png')},
                { name: 'Đường mạt chược', img: require('@/assets/images/images/mobile_games/nohu/tab5/7.png')},
                { name: 'Đường mạt chược 2', img: require('@/assets/images/images/mobile_games/nohu/tab5/8.png')},
                { name: 'Mega bắn cá', img: require('@/assets/images/images/mobile_games/nohu/tab5/9.png')},
                { name: 'Chill bắn cá', img: require('@/assets/images/images/mobile_games/nohu/tab5/10.png')},
                { name: 'Đế quốc hoàng kim', img: require('@/assets/images/images/mobile_games/nohu/tab5/11.png')},
                { name: 'Chuyên gia săn rồng', img: require('@/assets/images/images/mobile_games/nohu/tab5/12.png')},
                { name: 'Bắt châu báu', img: require('@/assets/images/images/mobile_games/nohu/tab5/13.png')},
                { name: 'Quyền vương', img: require('@/assets/images/images/mobile_games/nohu/tab5/14.png')},
                { name: 'Quyền vương', img: require('@/assets/images/images/mobile_games/nohu/tab5/15.png')},
            ]
        },
        GetListGameNohutab6 () {
            this.listNohuTab6 = [
                { name: 'SE Bacarat', img: require('@/assets/images/images/mobile_games/nohu/tab6/1.png')},
                { name: 'DG Rồng Hổ', img: require('@/assets/images/images/mobile_games/nohu/tab6/2.png')},
                { name: 'Saba thể thao', img: require('@/assets/images/images/mobile_games/nohu/tab6/3.png')},
                { name: 'WM xóc Đĩa', img: require('@/assets/images/images/mobile_games/nohu/tab6/4.png')},
                { name: 'Super ACE', img: require('@/assets/images/images/mobile_games/nohu/tab6/5.png')},
                { name: 'Kho báu Aztec', img: require('@/assets/images/images/mobile_games/nohu/tab6/6.png')},
                { name: 'Đường mạt chược', img: require('@/assets/images/images/mobile_games/nohu/tab6/7.png')},
                { name: 'Đường mạt chược 2', img: require('@/assets/images/images/mobile_games/nohu/tab6/8.png')},
                { name: 'Mega bắn cá', img: require('@/assets/images/images/mobile_games/nohu/tab6/9.png')},
                { name: 'Chill bắn cá', img: require('@/assets/images/images/mobile_games/nohu/tab6/10.png')},
                { name: 'Đế quốc hoàng kim', img: require('@/assets/images/images/mobile_games/nohu/tab6/11.png')},
                { name: 'Chuyên gia săn rồng', img: require('@/assets/images/images/mobile_games/nohu/tab6/12.png')},
                { name: 'Bắt châu báu', img: require('@/assets/images/images/mobile_games/nohu/tab6/13.png')},
                { name: 'Quyền vương', img: require('@/assets/images/images/mobile_games/nohu/tab6/14.png')},
                { name: 'Quyền vương', img: require('@/assets/images/images/mobile_games/nohu/tab6/15.png')},
            ]
        },
        GetListGameNohutab7 () {
            this.listNohuTab7 = [
                { name: 'SE Bacarat', img: require('@/assets/images/images/mobile_games/nohu/tab7/1.png')},
                { name: 'DG Rồng Hổ', img: require('@/assets/images/images/mobile_games/nohu/tab7/2.png')},
                { name: 'Saba thể thao', img: require('@/assets/images/images/mobile_games/nohu/tab7/3.png')},
                { name: 'WM xóc Đĩa', img: require('@/assets/images/images/mobile_games/nohu/tab7/4.png')},
                { name: 'Super ACE', img: require('@/assets/images/images/mobile_games/nohu/tab7/5.png')},
                { name: 'Kho báu Aztec', img: require('@/assets/images/images/mobile_games/nohu/tab7/6.png')},
                { name: 'Đường mạt chược', img: require('@/assets/images/images/mobile_games/nohu/tab7/7.png')},
                { name: 'Đường mạt chược 2', img: require('@/assets/images/images/mobile_games/nohu/tab7/8.png')},
                { name: 'Mega bắn cá', img: require('@/assets/images/images/mobile_games/nohu/tab7/9.png')},
                { name: 'Chill bắn cá', img: require('@/assets/images/images/mobile_games/nohu/tab7/10.png')},
                { name: 'Đế quốc hoàng kim', img: require('@/assets/images/images/mobile_games/nohu/tab7/11.png')},
                { name: 'Chuyên gia săn rồng', img: require('@/assets/images/images/mobile_games/nohu/tab7/12.png')},
                { name: 'Bắt châu báu', img: require('@/assets/images/images/mobile_games/nohu/tab7/13.png')},
                { name: 'Quyền vương', img: require('@/assets/images/images/mobile_games/nohu/tab7/14.png')},
                { name: 'Quyền vương', img: require('@/assets/images/images/mobile_games/nohu/tab7/15.png')},
            ]
        },
        GetListGameNohutab8 () {
            this.listNohuTab8 = [
                { name: 'SE Bacarat', img: require('@/assets/images/images/mobile_games/nohu/tab8/1.png')},
                { name: 'DG Rồng Hổ', img: require('@/assets/images/images/mobile_games/nohu/tab8/2.png')},
                { name: 'Saba thể thao', img: require('@/assets/images/images/mobile_games/nohu/tab8/3.png')},
                { name: 'WM xóc Đĩa', img: require('@/assets/images/images/mobile_games/nohu/tab8/4.png')},
                { name: 'Super ACE', img: require('@/assets/images/images/mobile_games/nohu/tab8/5.png')},
                { name: 'Kho báu Aztec', img: require('@/assets/images/images/mobile_games/nohu/tab8/6.png')},
                { name: 'Đường mạt chược', img: require('@/assets/images/images/mobile_games/nohu/tab8/7.png')},
                { name: 'Đường mạt chược 2', img: require('@/assets/images/images/mobile_games/nohu/tab8/8.png')},
                { name: 'Mega bắn cá', img: require('@/assets/images/images/mobile_games/nohu/tab8/9.png')},
                { name: 'Chill bắn cá', img: require('@/assets/images/images/mobile_games/nohu/tab8/10.png')},
                { name: 'Đế quốc hoàng kim', img: require('@/assets/images/images/mobile_games/nohu/tab8/11.png')},
                { name: 'Chuyên gia săn rồng', img: require('@/assets/images/images/mobile_games/nohu/tab8/12.png')},
                { name: 'Bắt châu báu', img: require('@/assets/images/images/mobile_games/nohu/tab8/13.png')},
                { name: 'Quyền vương', img: require('@/assets/images/images/mobile_games/nohu/tab8/14.png')},
                { name: 'Quyền vương', img: require('@/assets/images/images/mobile_games/nohu/tab8/15.png')},
            ]
        },
        GetListGameNohutab9 () {
            this.listNohuTab9 = [
                { name: 'SE Bacarat', img: require('@/assets/images/images/mobile_games/nohu/tab9/1.png')},
                { name: 'DG Rồng Hổ', img: require('@/assets/images/images/mobile_games/nohu/tab9/2.png')},
                { name: 'Saba thể thao', img: require('@/assets/images/images/mobile_games/nohu/tab9/3.png')},
                { name: 'WM xóc Đĩa', img: require('@/assets/images/images/mobile_games/nohu/tab9/4.png')},
                { name: 'Super ACE', img: require('@/assets/images/images/mobile_games/nohu/tab9/5.png')},
                { name: 'Kho báu Aztec', img: require('@/assets/images/images/mobile_games/nohu/tab9/6.png')},
                { name: 'Đường mạt chược', img: require('@/assets/images/images/mobile_games/nohu/tab9/7.png')},
                { name: 'Đường mạt chược 2', img: require('@/assets/images/images/mobile_games/nohu/tab9/8.png')},
                { name: 'Mega bắn cá', img: require('@/assets/images/images/mobile_games/nohu/tab9/9.png')},
                { name: 'Chill bắn cá', img: require('@/assets/images/images/mobile_games/nohu/tab9/10.png')},
                { name: 'Đế quốc hoàng kim', img: require('@/assets/images/images/mobile_games/nohu/tab9/11.png')},
                { name: 'Chuyên gia săn rồng', img: require('@/assets/images/images/mobile_games/nohu/tab9/12.png')},
                { name: 'Bắt châu báu', img: require('@/assets/images/images/mobile_games/nohu/tab9/13.png')},
                { name: 'Quyền vương', img: require('@/assets/images/images/mobile_games/nohu/tab9/14.png')},
                { name: 'Quyền vương', img: require('@/assets/images/images/mobile_games/nohu/tab9/15.png')},
                { name: 'Quyền vương', img: require('@/assets/images/images/mobile_games/nohu/tab9/16.png')},
                { name: 'Quyền vương', img: require('@/assets/images/images/mobile_games/nohu/tab9/17.png')},
                { name: 'Quyền vương', img: require('@/assets/images/images/mobile_games/nohu/tab9/18.png')},
            ]
        },
        GetListGameNohutab10 () {
            this.listNohuTab10 = [
                { name: 'SE Bacarat', img: require('@/assets/images/images/mobile_games/nohu/tab10/1.png')},
                { name: 'DG Rồng Hổ', img: require('@/assets/images/images/mobile_games/nohu/tab10/2.png')},
                { name: 'Saba thể thao', img: require('@/assets/images/images/mobile_games/nohu/tab10/3.png')},
                { name: 'WM xóc Đĩa', img: require('@/assets/images/images/mobile_games/nohu/tab10/4.png')},
                { name: 'Super ACE', img: require('@/assets/images/images/mobile_games/nohu/tab10/5.png')},
                { name: 'Kho báu Aztec', img: require('@/assets/images/images/mobile_games/nohu/tab10/6.png')},
                { name: 'Đường mạt chược', img: require('@/assets/images/images/mobile_games/nohu/tab10/7.png')},
                { name: 'Đường mạt chược 2', img: require('@/assets/images/images/mobile_games/nohu/tab10/8.png')},
                { name: 'Mega bắn cá', img: require('@/assets/images/images/mobile_games/nohu/tab10/9.png')},
                { name: 'Chill bắn cá', img: require('@/assets/images/images/mobile_games/nohu/tab10/10.png')},
                { name: 'Đế quốc hoàng kim', img: require('@/assets/images/images/mobile_games/nohu/tab10/11.png')},
                { name: 'Chuyên gia săn rồng', img: require('@/assets/images/images/mobile_games/nohu/tab10/12.png')},
                { name: 'Bắt châu báu', img: require('@/assets/images/images/mobile_games/nohu/tab10/13.png')},
                { name: 'Quyền vương', img: require('@/assets/images/images/mobile_games/nohu/tab10/14.png')},
                { name: 'Quyền vương', img: require('@/assets/images/images/mobile_games/nohu/tab10/15.png')},
            ]
        },

        
        GetListGameBaitab1 () {
            this.listGameBaiTab1 = [
                { name: 'SE Bacarat', img: require('@/assets/images/images/mobile_games/gamebai/tab1/1.png')},
                { name: 'DG Rồng Hổ', img: require('@/assets/images/images/mobile_games/gamebai/tab1/2.png')},
                { name: 'Saba thể thao', img: require('@/assets/images/images/mobile_games/gamebai/tab1/3.png')},
                { name: 'WM xóc Đĩa', img: require('@/assets/images/images/mobile_games/gamebai/tab1/4.png')},
                { name: 'Super ACE', img: require('@/assets/images/images/mobile_games/gamebai/tab1/5.png')},
                { name: 'Kho báu Aztec', img: require('@/assets/images/images/mobile_games/gamebai/tab1/6.png')},
                { name: 'Đường mạt chược', img: require('@/assets/images/images/mobile_games/gamebai/tab1/7.png')},
                { name: 'Đường mạt chược 2', img: require('@/assets/images/images/mobile_games/gamebai/tab1/8.png')},
                { name: 'Mega bắn cá', img: require('@/assets/images/images/mobile_games/gamebai/tab1/9.png')},
                { name: 'Chill bắn cá', img: require('@/assets/images/images/mobile_games/gamebai/tab1/10.png')},
                { name: 'Đế quốc hoàng kim', img: require('@/assets/images/images/mobile_games/gamebai/tab1/11.png')},
                { name: 'Chuyên gia săn rồng', img: require('@/assets/images/images/mobile_games/gamebai/tab1/12.png')},
                { name: 'Bắt châu báu', img: require('@/assets/images/images/mobile_games/gamebai/tab1/13.png')},
                { name: 'Quyền vương', img: require('@/assets/images/images/mobile_games/gamebai/tab1/14.png')},
                { name: 'Quyền vương', img: require('@/assets/images/images/mobile_games/gamebai/tab1/15.png')},
                { name: 'Quyền vương', img: require('@/assets/images/images/mobile_games/gamebai/tab1/16.png')},
            ]
        },
        GetListGameBaitab2 () {
            this.listGameBaiTab2 = [
                { name: 'SE Bacarat', img: require('@/assets/images/images/mobile_games/gamebai/tab2/1.png')},
                { name: 'DG Rồng Hổ', img: require('@/assets/images/images/mobile_games/gamebai/tab2/2.png')},
                { name: 'Saba thể thao', img: require('@/assets/images/images/mobile_games/gamebai/tab2/3.png')},
                { name: 'WM xóc Đĩa', img: require('@/assets/images/images/mobile_games/gamebai/tab2/4.png')},
                { name: 'Super ACE', img: require('@/assets/images/images/mobile_games/gamebai/tab2/5.png')},
                { name: 'Kho báu Aztec', img: require('@/assets/images/images/mobile_games/gamebai/tab2/6.png')},
                { name: 'Đường mạt chược', img: require('@/assets/images/images/mobile_games/gamebai/tab2/7.png')},
                { name: 'Đường mạt chược 2', img: require('@/assets/images/images/mobile_games/gamebai/tab2/8.png')},
                { name: 'Mega bắn cá', img: require('@/assets/images/images/mobile_games/gamebai/tab2/9.png')},
                { name: 'Chill bắn cá', img: require('@/assets/images/images/mobile_games/gamebai/tab2/10.png')},
                { name: 'Đế quốc hoàng kim', img: require('@/assets/images/images/mobile_games/gamebai/tab2/11.png')},
                { name: 'Chuyên gia săn rồng', img: require('@/assets/images/images/mobile_games/gamebai/tab2/12.png')},
                { name: 'Bắt châu báu', img: require('@/assets/images/images/mobile_games/gamebai/tab2/13.png')},
                { name: 'Quyền vương', img: require('@/assets/images/images/mobile_games/gamebai/tab2/14.png')},
                { name: 'Quyền vương', img: require('@/assets/images/images/mobile_games/gamebai/tab2/15.png')},
                { name: 'Quyền vương', img: require('@/assets/images/images/mobile_games/gamebai/tab2/16.png')},
                { name: 'Quyền vương', img: require('@/assets/images/images/mobile_games/gamebai/tab2/17.png')},
                { name: 'Quyền vương', img: require('@/assets/images/images/mobile_games/gamebai/tab2/18.png')},
                { name: 'Quyền vương', img: require('@/assets/images/images/mobile_games/gamebai/tab2/19.png')},
            ]
        },
        GetListGameBaitab3 () {
            this.listGameBaiTab3 = [
                { name: 'SE Bacarat', img: require('@/assets/images/images/mobile_games/gamebai/tab3/1.png')},
                { name: 'DG Rồng Hổ', img: require('@/assets/images/images/mobile_games/gamebai/tab3/2.png')},
                { name: 'Saba thể thao', img: require('@/assets/images/images/mobile_games/gamebai/tab3/3.png')},
                { name: 'WM xóc Đĩa', img: require('@/assets/images/images/mobile_games/gamebai/tab3/4.png')},
                { name: 'Super ACE', img: require('@/assets/images/images/mobile_games/gamebai/tab3/5.png')},
                { name: 'Kho báu Aztec', img: require('@/assets/images/images/mobile_games/gamebai/tab3/6.png')},
                { name: 'Đường mạt chược', img: require('@/assets/images/images/mobile_games/gamebai/tab3/7.png')},
                { name: 'Đường mạt chược 2', img: require('@/assets/images/images/mobile_games/gamebai/tab3/8.png')},
                { name: 'Mega bắn cá', img: require('@/assets/images/images/mobile_games/gamebai/tab3/9.png')},
                { name: 'Chill bắn cá', img: require('@/assets/images/images/mobile_games/gamebai/tab3/10.png')},
                { name: 'Đế quốc hoàng kim', img: require('@/assets/images/images/mobile_games/gamebai/tab3/11.png')},
                { name: 'Chuyên gia săn rồng', img: require('@/assets/images/images/mobile_games/gamebai/tab3/12.png')},
                { name: 'Bắt châu báu', img: require('@/assets/images/images/mobile_games/gamebai/tab3/13.png')},
                { name: 'Quyền vương', img: require('@/assets/images/images/mobile_games/gamebai/tab3/14.png')},
                { name: 'Quyền vương', img: require('@/assets/images/images/mobile_games/gamebai/tab3/15.png')},
            ]
        },
        GetListGameBaitab4 () {
            this.listGameBaiTab4 = [
                { name: 'SE Bacarat', img: require('@/assets/images/images/mobile_games/gamebai/tab4/1.png')},
                { name: 'DG Rồng Hổ', img: require('@/assets/images/images/mobile_games/gamebai/tab4/2.png')},
                { name: 'Saba thể thao', img: require('@/assets/images/images/mobile_games/gamebai/tab4/3.png')},
                { name: 'WM xóc Đĩa', img: require('@/assets/images/images/mobile_games/gamebai/tab4/4.png')},
                { name: 'Super ACE', img: require('@/assets/images/images/mobile_games/gamebai/tab4/5.png')},
                { name: 'Kho báu Aztec', img: require('@/assets/images/images/mobile_games/gamebai/tab4/6.png')},
                { name: 'Đường mạt chược', img: require('@/assets/images/images/mobile_games/gamebai/tab4/7.png')},
                { name: 'Đường mạt chược 2', img: require('@/assets/images/images/mobile_games/gamebai/tab4/8.png')},
                { name: 'Mega bắn cá', img: require('@/assets/images/images/mobile_games/gamebai/tab4/9.png')},
                { name: 'Chill bắn cá', img: require('@/assets/images/images/mobile_games/gamebai/tab4/10.png')},
                { name: 'Đế quốc hoàng kim', img: require('@/assets/images/images/mobile_games/gamebai/tab4/11.png')},
                { name: 'Chuyên gia săn rồng', img: require('@/assets/images/images/mobile_games/gamebai/tab4/12.png')},
            ]
        },
        GetListGameBaitab5 () {
            this.listGameBaiTab5 = [
                { name: 'SE Bacarat', img: require('@/assets/images/images/mobile_games/gamebai/tab5/1.png')},
                { name: 'DG Rồng Hổ', img: require('@/assets/images/images/mobile_games/gamebai/tab5/2.png')},
                { name: 'Saba thể thao', img: require('@/assets/images/images/mobile_games/gamebai/tab5/3.png')},
                { name: 'WM xóc Đĩa', img: require('@/assets/images/images/mobile_games/gamebai/tab5/4.png')},
                { name: 'Super ACE', img: require('@/assets/images/images/mobile_games/gamebai/tab5/5.png')},
                { name: 'Kho báu Aztec', img: require('@/assets/images/images/mobile_games/gamebai/tab5/6.png')},
                { name: 'Đường mạt chược', img: require('@/assets/images/images/mobile_games/gamebai/tab5/7.png')},
                { name: 'Đường mạt chược 2', img: require('@/assets/images/images/mobile_games/gamebai/tab5/8.png')},
                { name: 'Mega bắn cá', img: require('@/assets/images/images/mobile_games/gamebai/tab5/9.png')},
                { name: 'Chill bắn cá', img: require('@/assets/images/images/mobile_games/gamebai/tab5/10.png')},
                { name: 'Đế quốc hoàng kim', img: require('@/assets/images/images/mobile_games/gamebai/tab5/11.png')},
                { name: 'Chuyên gia săn rồng', img: require('@/assets/images/images/mobile_games/gamebai/tab5/12.png')},
                { name: 'Bắt châu báu', img: require('@/assets/images/images/mobile_games/gamebai/tab5/13.png')},
                { name: 'Quyền vương', img: require('@/assets/images/images/mobile_games/gamebai/tab5/14.png')},
                { name: 'Quyền vương', img: require('@/assets/images/images/mobile_games/gamebai/tab5/15.png')},
                { name: 'Quyền vương', img: require('@/assets/images/images/mobile_games/gamebai/tab5/16.png')},
            ]
        },
        GetListGameBaitab6 () {
            this.listGameBaiTab6 = [
                { name: 'SE Bacarat', img: require('@/assets/images/images/mobile_games/gamebai/tab6/1.png')},
                { name: 'DG Rồng Hổ', img: require('@/assets/images/images/mobile_games/gamebai/tab6/2.png')},
                { name: 'Saba thể thao', img: require('@/assets/images/images/mobile_games/gamebai/tab6/3.png')},
                { name: 'WM xóc Đĩa', img: require('@/assets/images/images/mobile_games/gamebai/tab6/4.png')},
                { name: 'Super ACE', img: require('@/assets/images/images/mobile_games/gamebai/tab6/5.png')},
                { name: 'Kho báu Aztec', img: require('@/assets/images/images/mobile_games/gamebai/tab6/6.png')},
                { name: 'Đường mạt chược', img: require('@/assets/images/images/mobile_games/gamebai/tab6/7.png')},
            ]
        },
        GetListGameBaitab7 () {
            this.listGameBaiTab7 = [
                { name: 'SE Bacarat', img: require('@/assets/images/images/mobile_games/gamebai/tab7/1.png')},
            ]
        },
        GetListGameBaitab8 () {
            this.listGameBaiTab8 = [
                { name: 'SE Bacarat', img: require('@/assets/images/images/mobile_games/gamebai/tab8/1.png')},
                { name: 'DG Rồng Hổ', img: require('@/assets/images/images/mobile_games/gamebai/tab8/2.png')},
                { name: 'Saba thể thao', img: require('@/assets/images/images/mobile_games/gamebai/tab8/3.png')},
                { name: 'WM xóc Đĩa', img: require('@/assets/images/images/mobile_games/gamebai/tab8/4.png')},
                { name: 'Super ACE', img: require('@/assets/images/images/mobile_games/gamebai/tab8/5.png')},
                { name: 'Kho báu Aztec', img: require('@/assets/images/images/mobile_games/gamebai/tab8/6.png')},
                { name: 'Đường mạt chược', img: require('@/assets/images/images/mobile_games/gamebai/tab8/7.png')},
                { name: 'Đường mạt chược 2', img: require('@/assets/images/images/mobile_games/gamebai/tab8/8.png')},
                { name: 'Mega bắn cá', img: require('@/assets/images/images/mobile_games/gamebai/tab8/9.png')},
                { name: 'Chill bắn cá', img: require('@/assets/images/images/mobile_games/gamebai/tab8/10.png')},
                { name: 'Đế quốc hoàng kim', img: require('@/assets/images/images/mobile_games/gamebai/tab8/11.png')},
                { name: 'Chuyên gia săn rồng', img: require('@/assets/images/images/mobile_games/gamebai/tab8/12.png')},
                { name: 'Bắt châu báu', img: require('@/assets/images/images/mobile_games/gamebai/tab8/13.png')},
                { name: 'Quyền vương', img: require('@/assets/images/images/mobile_games/gamebai/tab8/14.png')},
                { name: 'Quyền vương', img: require('@/assets/images/images/mobile_games/gamebai/tab8/15.png')},
            ]
        },
        GetListGameBaitab9 () {
            this.listGameBaiTab9 = [
                { name: 'SE Bacarat', img: require('@/assets/images/images/mobile_games/gamebai/tab9/1.png')},
                { name: 'DG Rồng Hổ', img: require('@/assets/images/images/mobile_games/gamebai/tab9/2.png')},
                { name: 'Saba thể thao', img: require('@/assets/images/images/mobile_games/gamebai/tab9/3.png')},
                { name: 'WM xóc Đĩa', img: require('@/assets/images/images/mobile_games/gamebai/tab9/4.png')},
                { name: 'Super ACE', img: require('@/assets/images/images/mobile_games/gamebai/tab9/5.png')},
                { name: 'Kho báu Aztec', img: require('@/assets/images/images/mobile_games/gamebai/tab9/6.png')},
                { name: 'Đường mạt chược', img: require('@/assets/images/images/mobile_games/gamebai/tab9/7.png')},
                { name: 'Đường mạt chược 2', img: require('@/assets/images/images/mobile_games/gamebai/tab9/8.png')},
                { name: 'Mega bắn cá', img: require('@/assets/images/images/mobile_games/gamebai/tab9/9.png')},
                { name: 'Chill bắn cá', img: require('@/assets/images/images/mobile_games/gamebai/tab9/10.png')},
            ]
        },
    }
}
</script>
