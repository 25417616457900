<template>
    <Header_live :class="classMb" v-if="this.$route.name != 'Chat_nm'" />
        <Banner_adv :class="classTopview" />
        <div v-if="this.$route.name != 'Chat_nm'" class="down_mobile" :class="classNone">
            <div class="wp_downapp">
                <img src="./assets/images/downapp.png" alt="" class="img_down_mobile">
                <a href="https://f8game.click/img/app.apk" download>
                    <img src="./assets/images/download.gif" alt="" class="img_down_mobile0">
                </a>
                <a href="https://testflight.apple.com/join/tvpJzFOW" download>
                    <img src="./assets/images/dtios.gif" alt="" class="img_down_mobile1">
                </a>
            </div>
            <img src="./assets/images/clo.png" alt="" class="hidden_down" @click="Fcmargin">
        </div>
        <router-view :class="classTopview" :key ='$route.params'/>
    <Footer_live v-if="this.$route.name != 'Chat_nm'" :class="'footer_f8 ' + {noneFooter: $route.name == 'LiveDetail_nm' || $route.name == 'LiveDetailShow_nm'}" />
</template>

<script>
import "./assets/css/style.css"
import Banner_adv from './components/Banner.vue'
import Header_live from './components/Header.vue'
import Footer_live from './components/Footer.vue'

export default {
  name: 'App',
  data () {
        return {
            // pathname: this.$route.name,
            classMb: 'mgr_top_mb',
            classNone: '',
            classTopview: 'topview_mb',
            token: window.localStorage.getItem('token'),
            token1: window.localStorage.getItem('token1'),
            cookie: this.$cookies.get('_ga'),
            other: ''
        }
    },
    components: {
        Header_live,
        Footer_live,
        Banner_adv
    },
    created () {
        if(this.token != null && this.token1 == null){
            // this.loginChat()
        }
        this.postCookie()
    },
    methods: {
        Fcmargin () {
            this.classMb = '',
            this.classNone = 'none_down_mb'
            this.classTopview = ''
        },
        // loginChat () {
        //     this.axios.post(this.api_loginChat, 
        //         {

        //         },
        //         {
        //             headers: {
        //                 Authorization: 'Bearer ' + this.token
        //             }
        //         }
        //     ).then((response) => {
        //         window.localStorage.setItem('token1', response.data.data.token)
        //     })
        // },
        postCookie () {
            this.axios.get(this.api_postGa + '?_ga=' + this.cookie).then((response) => {
                this.other = response
            })
        },
    }
}
</script>

<style>
</style>
