<template>
    <div class="root_info_user">
        <div class="container ner_support">
            <div class="list_chat_support list_chat_support_detail">
                <input type="hidden" class="id_support_input" :value="id">
                <router-link to="/support" class="comeback_support"><img src="../assets/images/cb.png" alt=""></router-link>
                <div class="scroll_div_support">
                    <div class="item_chat_detail" v-for="(item, index) in listspdt" :key="index">
                        <div class="wp_item_chatdt_right" v-if="item.type == 1">
                            <div class="content_chatdt">
                                <p class="ngaytao_chat ngaytao_chat1"><span>{{ fullname }}</span></p>
                                <div class="content_child">
                                    <!-- <p v-if="item.type_mes == 1">{{ item.content }}</p>
                                    <p v-else-if="item.type_mes == 2"><a :href="link_web + item.url_image" target="_blank"><img :src="link_web + item.url_image" alt=""></a></p>
                                    <p v-else><a :href="item.content" target="_blank">{{ item.content }}</a></p> -->

                                    <div class="left_content_mes">
                                        <p v-if="item.type_mes == 1">{{ item.content }}</p>
                                        <p v-else-if="item.type_mes == 2"><a :href="link_web + item.url_image" target="_blank"><img :src="link_web + item.url_image" alt=""></a></p>
                                        <p v-else><a :href="item.content" target="_blank">{{ item.content }}</a></p>
                                    </div>
                                </div>
                                <p class="ngaytao_chat">{{ item.ngay_tao }}</p>
                            </div>
                            <div class="img_chat_detail">
                                <img :src="link_web + hinh_anh" alt="">
                            </div>
                        </div>
                        <div class="wp_item_chatdt_left" v-else>
                            <div class="img_chat_detail">
                                <img src="../assets/images/admin.png" alt="">
                            </div>
                            <div class="content_chatdt" :class="{active: last_id == item.id}">
                                <p class="ngaytao_chat ngaytao_chat1"><span>Hải Yến</span></p>
                                <div class="content_child">
                                    <!-- <p v-if="item.type_mes == 1">{{ item.content }}</p>
                                    <p v-else-if="item.type_mes == 2"><a :href="link_web + item.url_image" target="_blank"><img :src="link_web + item.url_image" alt=""></a></p>
                                    <p v-else><a :href="item.content" target="_blank">{{ item.content }}</a></p> -->
                                    <div class="left_content_mes">
                                        <p v-if="item.type_mes == 1">{{ item.content }}</p>
                                        <p v-else-if="item.type_mes == 2"><a :href="link_web + item.url_image" target="_blank"><img :src="link_web + item.url_image" alt=""></a></p>
                                        <p v-else><a :href="item.content" target="_blank">{{ item.content }}</a></p>
                                    </div>
                                </div>
                                <!-- <p class="haiyen">Hải yến</p> -->
                                <p class="ngaytao_chat">{{ item.ngay_tao }}</p>
                                <ul class="listtp_other" v-if="last_id == item.id">
                                    <li @click="clickLoadSupport($event)" v-for="(item, index) in listtp" :data_id="item.id" :key="index" :data_title="item.title" :data_content="item.content">{{ item.title }}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form_chat_support">
                    <form action="" method="post" id="frm_chatsupport" @submit="sendSupport">
                        <input type="text" class="content_rep" v-model="content" placeholder="Nội dung">
                        <div class="input_file_img">
                            <input type="file" name="file_img_sp" id="file_img_sp" @change="submitImg($event)"/>
                            <label class="file_img_sp" for="file_img_sp">
                                <font-awesome-icon icon="fa-solid fa-image" />
                            </label>
                        </div>
                        <button type="submit">Gửi</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import $ from 'jquery'
import SocketIO from 'socket.io-client'
$( document ).ready(function() {
    var domainWeb = 'https://f8game.click/';
    var socket = SocketIO(domainWeb,{ transports: ['websocket', 'polling', 'flashsocket'] });
    // console.log('123', socket);
    socket.on('chat:support',function(result){
        // console.log('result', result);
        let str = '';
        let str1 = '';
        let image = window.localStorage.getItem('img');
        let pic = require('../assets/images/admin.png');

        let id_spp = $('.id_support_input').val()
        // type = 2 => admin gửi xuống
        // type = 1 => user gửi lên
        // type_mes = 1 => nội dung
        // type_mes = 2 => hình ảnh
        // type_mes = 3 => link
        if(id_spp == result.data.support_id){
            if(result.data.type == 1){
                if(result.data.type_mes == 1){
                    str = `<div class="wp_item_chatdt_right">
                        <div class="content_chatdt">
                            <div class="content_child">
                                <div class="left_content_mes">
                                    <p>${result.data.content}</p>
                                </div>
                            </div>
                            <p class="ngaytao_chat">${result.data.ngay_tao}</p>
                        </div>
                        <div class="img_chat_detail">
                            <img src="${domainWeb}${image}" alt="">
                        </div>
                    </div>`
                }else if(result.data.type_mes == 2){
                    str = `<div class="wp_item_chatdt_right">
                        <div class="content_chatdt">
                            <div class="content_child">
                                <div class="left_content_mes">
                                    <p><a href="${domainWeb}${result.data.url_image}" target="_blank"><img src="${domainWeb}${result.data.url_image}" alt=""></a></p>
                                </div>
                            </div>
                            <p class="ngaytao_chat">${result.data.ngay_tao}</p>
                        </div>
                        <div class="img_chat_detail">
                            <img src="${domainWeb}${image}" alt="">
                        </div>
                    </div>`
                }else{
                    str = `<div class="wp_item_chatdt_right">
                        <div class="content_chatdt">
                            <div class="content_child">
                                <div class="left_content_mes">
                                    <p><a href="${result.data.content}" target="_blank">${result.data.content}</a></p>
                                </div>
                            </div>
                            <p class="ngaytao_chat">${result.data.ngay_tao}</p>
                        </div>
                        <div class="img_chat_detail">
                            <img src="${domainWeb}${image}" alt="">
                        </div>
                    </div>`
                }
            }else{
                if(result.data.type_mes == 1){
                    str = `<div class="wp_item_chatdt_left">
                        <div class="img_chat_detail">
                            <img src="${pic}" alt="">
                        </div>
                        <div class="content_chatdt">
                            <div class="content_child">
                                <div class="left_content_mes">
                                    <p>${result.data.content}</p>
                                </div>
                            </div>
                            <p class="ngaytao_chat"><span>Hải Yến</span>${result.data.ngay_tao}</p>
                        </div>
                    </div>`
                }else if(result.data.type_mes == 2){
                    str = `<div class="wp_item_chatdt_left">
                        <div class="img_chat_detail">
                            <img src="${pic}" alt="">
                        </div>
                        <div class="content_chatdt">
                            <div class="content_child">
                                <div class="left_content_mes">
                                    <p><a href="${domainWeb}${result.data.url_image}" target="_blank"><img src="${domainWeb}${result.data.url_image}" alt=""></a></p>
                                </div>
                            </div>
                            <p class="ngaytao_chat"><span>Hải Yến</span>${result.data.ngay_tao}</p>
                        </div>
                    </div>`
                }else{
                    str = `<div class="wp_item_chatdt_left">
                        <div class="img_chat_detail">
                            <img src="${pic}" alt="">
                        </div>
                        <div class="content_chatdt">
                            <div class="content_child">
                                <div class="left_content_mes">
                                    <p><a href="${result.data.content}" target="_blank">${result.data.content}</a></p>
                                </div>
                            </div>
                            <p class="ngaytao_chat"><span>Hải Yến</span>${result.data.ngay_tao}</p>
                        </div>
                    </div>`
                }
            }
        }
        str1 = `<div class="item_chat_detail">${str}</div>`

        $(`.scroll_div_support`).append(str1);

        var hg = $('.scroll_div_support').prop('scrollHeight');
        $('.scroll_div_support').animate({ scrollTop: hg+100 },500);
    });

    $(document).on('click', '.listtp_otherss li', function() {
        let title = $(this).attr('data_title')
        let content = $(this).attr('data_content')
        let image = window.localStorage.getItem('img');
        let pic = require('../assets/images/admin.png');
        console.log(title, content);

        var today = new Date();
        var date = today.getFullYear() + '-' + (today.getMonth()+1) + '-' + today.getDate();
        var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        var dateTime = date + ' ' + time;

        let str = `<div class="item_chat_detail"><div class="wp_item_chatdt_right">
                    <div class="content_chatdt">
                        <div class="content_child">
                            <div class="left_content_mes">
                                <p>${title}</p>
                            </div>
                        </div>
                        <p class="ngaytao_chat">${dateTime}</p>
                    </div>
                    <div class="img_chat_detail">
                        <img src="${domainWeb}${image}" alt="">
                    </div>
                </div>
                <div class="wp_item_chatdt_left">
                    <div class="img_chat_detail">
                        <img src="${pic}" alt="">
                    </div>
                    <div class="content_chatdt">
                        <p class="ngaytao_chat ngaytao_chat1"><span>Hải Yến</span></p>
                        <div class="content_child">
                            <div class="left_content_mes">
                                <p>${content}</p>
                            </div>
                        </div>
                        <p class="ngaytao_chat">${dateTime}</p>
                    </div>
                </div>
            </div>`;
        $(`.scroll_div_support`).append(str);
        console.log(str);
    });
});
export default {
    name: 'SupportDetail_nm',
    data () {
        return {
            id: parseInt(this.$route.params.id),
            token: window.localStorage.getItem('token'),
            listspdt: [],
            fullname: '',
            hinh_anh: '',
            content: '',
            other: '',
            listtp: [],
            last_id: '',
        }
    },
    components: {
    },
    created () {
        this.getListspdt()
        this.getListInfo()
    },
    methods: {
        getListInfo () {
            this.axios.get(this.api_infouser, {
                headers: {
                    Authorization: 'Bearer ' + window.localStorage.getItem('token')
                }
            }).then((response) => {
                this.fullname = response.data.data.user.fullname
                this.hinh_anh = response.data.data.user.hinh_anh
            }).catch(e => {
                if (e.response.status === 401) {
                    setTimeout(function() {
                        this.axios.post(this.api_logout, {
                        }).then(res => {
                            if (res.data.success === true) {
                                localStorage.clear();
                            }
                        })
                    }.bind(this), 5000);
                    setTimeout(function() {
                        window.location.href = '/'
                    }, 13000);
                }
            })
        },
        getListspdt () {
            this.axios.get(this.api_spDetail + '?support_id=' + this.id, {
                headers: {
                    Authorization: 'Bearer ' + this.token
                }
            }).then((response) => {
                this.listtp = response.data.list
                // Object.entries(response.data.list).forEach(([key, val]) => {
                //     console.log(key);
                //     str1 = str1 + `<li @click="${this.clickLoadSupport}" data_title="${val.title}" data_content="${val.content}">${val.title}</li>`
                // });
                this.last_id = response.data.last_id
                let arr_lt = response.data.data
                this.listspdt = arr_lt.reverse()
                setTimeout(() => {
                    var hg = $('.scroll_div_support').prop('scrollHeight');
                    $('.scroll_div_support').animate({ scrollTop: hg+100 }, 5);
                }, 500);
            })
        },
        sendSupport (e) {
            e.preventDefault();
            $('.listtp_other').hide()
            $('.loading_show').show().fadeIn(10)
            let url_image = document.getElementById('file_img_sp').files[0]
            const form = new FormData();
            form.append('support_id', this.id);
            form.append('url_image', url_image);
            form.append('content', this.content);
            this.axios.post(this.api_sendSupport, 
                form,
                {
                    headers: {
                        Authorization: 'Bearer ' + this.token
                    }
                }
            ).then((response) => {
                $('#loading_show').css('display', 'none')
                this.other  = response
                this.content = ''
                $("#file_img_sp").val('');
            }).catch(e => {
                $('#loading_show').css('display', 'none')
                this.$swal({
                    position: 'top-end',
                    icon: 'error',
                    title: e.response.data.message,
                    showConfirmButton: false,
                    timer: 3000
                })
            })
            return false;
        },
        submitImg (event) {
            event.preventDefault();
            $('.listtp_other').hide()
            $('.loading_show').show().fadeIn(10)
            let url_image = document.getElementById('file_img_sp').files[0]
            const form = new FormData();
            form.append('support_id', this.id);
            form.append('url_image', url_image);
            form.append('content', this.content);
            this.axios.post(this.api_sendSupport, 
                form,
                {
                    headers: {
                        Authorization: 'Bearer ' + this.token
                    }
                }
            ).then((response) => {
                $('#loading_show').css('display', 'none')
                this.other  = response
                this.content = ''
                $("#file_img_sp").val('');
            }).catch(e => {
                $('#loading_show').css('display', 'none')
                this.$swal({
                    position: 'top-end',
                    icon: 'error',
                    title: e.response.data.message,
                    showConfirmButton: false,
                    timer: 3000
                })
            })
            return false;
        },
        getListtopic () {
            this.axios.get(this.api_listtopic).then((response) => {
                let listtopic = response.data.data
                let datatp = []
                listtopic.forEach(item => {
                    datatp.push({...item, text: item.title});
                });
                this.listtopic = datatp
            })
        },
        clickLoadSupport (event) {
            let idtp = event.target.getAttribute('data_id')
            this.axios.get(this.api_listtopic + '?topic_id=' + idtp + '&support_id=' + this.id).then((response) => {
                this.other = response
            })
            this.axios.get(this.api_spDetail + '?support_id=' + this.id, {
                headers: {
                    Authorization: 'Bearer ' + this.token
                }
            }).then((response) => {
                this.listtp = response.data.list
                this.last_id = response.data.last_id
                let arr_lt = response.data.data
                this.listspdt = arr_lt.reverse()
                setTimeout(() => {
                    var hg = $('.scroll_div_support').prop('scrollHeight');
                    $('.scroll_div_support').animate({ scrollTop: hg+100 }, 5);
                }, 500);
            })
        }
    }
}
</script>
<style>
</style>
